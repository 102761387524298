import React from 'react';
import {
    Col,
    Row,
    Button,
    Card
} from 'reactstrap'

const DiscordRegister = (() => {


    return <Row className='justify-content-around'>
        <Col lg="3" className='text-center' >

            <h6> Step 1: Register a is Discord account (Skip if you already have a Discord account) </h6>



            <div className='mt-3'>

                <img className='img-fluid rounded' src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/discord-step-1.webp' alt='discord-step-1' />
            </div>
            <a href='https://discord.com/register' target='_blank'>
                <Button className='mt-3'>Register</Button>
            </a>

        </Col>
        <Col lg="3" className='text-center'>
            <h6> Step 2: Join VE Discord server </h6>
            <div  className='mt-3'>
                <img className='img-fluid rounded' src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/discord-step-2_1.webp' alt='discord-step-1' />
            </div>
            <a href='https://discord.com/invite/QDdvJZsQQD' target='_blank'>
                <Button className='mt-3'>Join Discord Server</Button>
            </a>
        </Col>
        <Col lg="3" className='text-center'>
            <h6> Step 3: Remember your Discord username </h6>
            <div  className='mt-3'>
                <img className='img-fluid rounded' src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/discord-step-3_1.webp' alt='discord-step-1' />
            </div>

        </Col>
    </Row >
});

export default DiscordRegister;