import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Label,
  Input,
  CardTitle,
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ResponsiveLiveDiv } from '../../common/components.style'
import AccountService from '../../../network/services/account'
import WalletService from '../../../network/services/wallet'
import VeSyncThService from '../../../network/services/syncth'
import UserService from '../../../network/services/user'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import styled from 'styled-components'
import './index.scss'
import ActiveAcc from './activeAcc.svg'
import 'react-accessible-accordion/dist/fancy-example.css'
import SweetAlert from 'sweetalert2'

import VePackageModal from './vePackage.jsx'
import './index.css'

const ResponsiveParagraph = styled.p`
  @media (max-width: 578px) {
    font-size: 10px;
  }
`

const Responsiveh6 = styled.h6`
  @media (max-width: 578px) {
    font-size: 11px;
  }
`

const ActiveLiveAccounts = () => {
  let history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)

  const [showSyncModal, setShowSyncModal] = useState(false)
  const [authCode, setAuthCode] = useState('')
  const [syncEmail, setSyncEmail] = useState('')

  const [isSendCode, setIsSendCode] = useState(false)
  const [errors, setErrors] = useState({ verification: null, twoFA: null })
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [courseLanguageSelected, setCourseLanguage] = useState('Chinese Course')
  const [availablePackages, setAvailablePackages] = useState(['PPT', 'VTrade', 'TTTS', 'TTTV', 'TTTS/TTTV'])

  const toggleSyncModal = () => {
    setShowSyncModal(!showSyncModal)
  }

  const checkUpgradeDisabled = (account) => {
    if (account.package_id) {
      if (availablePackages.includes(account?.package?.name)) {
        if (account?.package?.level == 4) {
          return true
        }else{
          return false
        }
      }
    } else {
      return false
    }

    return true
  }


  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  // live accounts
  const liveAccounts = useSelector((state) => {
    if (state.account.accounts?.length > 0) {
      return state.account.accounts.filter((e) => e.account_type === 0 || e.account_type === 2)
    } else if (state.account.accounts?.length === 0) {
      return []
    } else {
      return null
    }
  })
  const selectedAccount = useSelector((state) => state.dashboard.account)
  const init = async () => {
    const accountResponse = await AccountService.getLiveAndIbAccount()
    const walletResponse = await WalletService.get()

    dispatch({ type: 'SET_TOTAL_DEPOSIT', totalDeposit: walletResponse.total_deposit })
    dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // modal
  const [showModal, setShowModal] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState(null)

  // useEffect(() => {
  //   if (selectedSetting != null && selectedAccount != null) {
  //     setShowModal(true)
  //   }
  // }, [selectedSetting, selectedAccount])

  // useEffect(() => {
  //   if (showModal === false) {
  //     // fetch account
  //     if (selectedAccount != null) {
  //       init()
  //     }

  //     setSelectedSetting(null)
  //     dispatch({
  //       type: 'SET_DASHBOARD_ACCOUNT',
  //       account: null
  //     })
  //   }
  //   // eslint-disable-next-line
  // }, [showModal])

  // const [bonusModal, setBonusModal] = useState(false)
  const [accountDetails, setAccountDetails] = useState(null)

  // const bonusToggle = (account) => {
  //   setAccountDetails(account)
  //   setBonusModal(!bonusModal)
  // }

  const [upgradeModal, setUpgradeModal] = useState(false)



  const upgradeModalToggle = (account) => {
    setAccountDetails(account)
    setUpgradeModal(!upgradeModal)
  }



  const [vePremiumUpgradeModal, setVePremiumUpgradeModal] = useState(false)

  // const upgradeVePremiumModalToggle = () => {
  //   setVePremiumUpgradeModal(!vePremiumUpgradeModal)
  // }

  // const faqItems = [
  //   {
  //     heading: t('live.WhatIsTheDifference'),
  //     content: t('live.TheStarterPackIs')
  //   },
  //   {
  //     heading: t('live.HowDoTKnow'),
  //     content: t('live.IfYouAreNewToInvesting')
  //   }
  // ]
  return (

    <>

      {user.is_th_sync == true &&
        liveAccounts != null ? (
          <>
            <Row style={{ justifyContent: 'space-between', marginRight: '0', marginLeft: '0' }}>
              {/* <h5 style={{ marginBottom: 20 }}>{t('live.liveAccounts')}</h5> */}

              <p style={{ fontSize: '12px' }}>
                
              </p>

              <p style={{ fontSize: '16px' }}>
                {t('live.liveAccounts')}: {liveAccounts?.length} / 7
              </p>
            </Row>
            <Row>
              {liveAccounts?.length > 0 ? (
                liveAccounts.map((account) => {
                  return (
                    <Col xl="4" lg='6' md="6" key={account.id} className='account-card'>
                      <Card
                        className="card-absolute "
                        style={{
                          backgroundImage: `url(${ActiveAcc})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '100%',
                          borderRadius: '20px',
                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <CardBody
                          className="text-left"
                          style={{ paddingTop: '10px', paddingBottom: '0px' }}
                        >
                          <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <Col className='mt-2'>
                              <p style={{ fontSize: '16px', lineHeight: '1.0' }}>{`#${account.account_login} `}</p>
                              {/* <h6>{account.plan?.name ?? '-'}</h6> */}
                            </Col>
                            <Col >
                              <Badge style={{ float: 'right', fontSize: '14px', backgroundColor: '#232323', borderRadius: '7px' }}>{account.plan?.name}</Badge>
                              {/* <h6>{account.plan?.name ?? '-'}</h6> */}
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <p style={{ fontSize: '28px', fontWeight: 500, marginLeft: '10px' }}>{`${account?.balance === null
                              ? '-'
                              : formatter?.format(account?.balance) ?? '-'
                              } `}
                            </p><p style={{ fontSize: '16px', marginTop: '12px', marginLeft: '10px' }}>USD</p>
                          </Row>
                        </CardBody>

                        <div className="live-card-right">
                          <ResponsiveLiveDiv
                            className="d-sm-block"
                            style={{
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              padding: '20px 40px 0px 40px'
                            }}
                          >




                            {/* Person Who fill in personal details  */}
                            {upgradeModal && (
                              <Modal
                                id="package-live-modal"
                                isOpen={account.id === accountDetails?.id && upgradeModal}
                                toggle={upgradeModalToggle}
                                centered
                                backdrop="static"
                              >
                                <ModalHeader
                                  toggle={upgradeModalToggle}
                                  className="modalHeader-Package"
                                  style={{
                                    backgroundColor: '#FAFCFE',

                                  }}
                                ></ModalHeader>
                                <ModalBody
                                  style={{
                                    backgroundColor: '#FAFCFE',
                                    padding: '1rem 1rem 0rem 1rem',
                                  }}
                                >
                                  <Row style={{ justifyContent: 'center', paddingBottom: '0px' }}>
                                    <h2 className='m-3' >
                                      {t('live.ChoosePackage')}
                                    </h2>
                                  </Row>

                                  <Row
                                    style={{ justifyContent: 'space-evenly', marginTop: '25px' }}
                                  >


                                    <Col lg="12" id="premium-pack">

                                      <VePackageModal
                                        account={account}
                                        accountDetails={accountDetails}
                                        vePremiumUpgradeModal={vePremiumUpgradeModal}
                                        setVePremiumUpgradeModal={setVePremiumUpgradeModal}

                                      />

                                    </Col>

                                  </Row>

                                  {/* <Row
                                    style={{
                                      justifyContent: 'center',
                                      backgroundColor: '#F5F7FA',
                                      padding: '2rem 0rem'
                                    }}
                                  >
                                    <Col lg="8" id="faq">
                                      <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>
                                        {t('live.FrequentlyAskedQuestions')}
                                      </h5>

                                      <Accordion allowZeroExpanded allowMultipleExpanded>
                                        {faqItems.map((item, index) => (
                                          <AccordionItem key={index}>
                                            <AccordionItemHeading>
                                              <AccordionItemButton
                                                style={{ backgroundColor: '#FFF' }}
                                              >
                                                {item.heading}
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              {item.content}
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                        ))}
                                      </Accordion>
                                    </Col>
                                  </Row> */}
                                </ModalBody>

                              </Modal>
                            )}

                          </ResponsiveLiveDiv>
                        </div>
                        <CardBody
                          className="text-left"
                          style={{ paddingTop: '10px', paddingBottom: '0px' }}
                        >
                          <Row style={{ marginBottom: '15px' }}>
                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                MT5 LOGIN ID
                              </ResponsiveParagraph>
                              <Responsiveh6>{`#${account.account_login} `}</Responsiveh6>

                            </Col>


                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('live.leverage')}
                              </ResponsiveParagraph>
                              <Responsiveh6>
                                {account?.leverage === null ? '-' : `1:${account?.leverage}` ?? '-'}
                              </Responsiveh6>
                            </Col>
                          </Row>

                          <Row>

                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('live.credit')}
                              </ResponsiveParagraph>
                              <Responsiveh6>{`${formatter?.format(account?.credit) ?? '-'
                                } `}</Responsiveh6>
                            </Col>

                            <Col>
                              <ResponsiveParagraph style={{ marginBottom: '0px' }}>
                                {t('live.Class')}
                              </ResponsiveParagraph>


                              <Responsiveh6>
                                <Badge
                                  style={{
                                    backgroundColor:
                                      account?.account_type === 2 ? '#108EE9' :
                                        account.package_id == 1 ||
                                          account.package_id == 2 ||
                                          account.package_id == 3 ||
                                          account.package_id == 10 ||
                                          account.package_id == 11 ||
                                          account.package_id == 12
                                          ? '#E782A9'
                                          : account.package_id == 4 ||
                                            account.package_id == 5 ||
                                            account.package_id == 6 ||
                                            account.package_id == 13 ||
                                            account.package_id == 14 ||
                                            account.package_id == 15 ||
                                            account.package_id == 19
                                            ? '#39207C'
                                            : account.package_id == 7
                                              ? '#48D597'
                                              : account.package_id == 8
                                                ? '#F0EC74'
                                                : account.package_id == 9
                                                  ? '#00A300' :
                                                  account.package_id == 16 ||
                                                    account.package_id == 17 ?
                                                    '#E609D3'
                                                    : account.package_id == 18 ? '#bd4040' :
                                                      account.package_id == 21 ? '#514496'
                                                        : '#B1B3B3'
                                  }}
                                >
                                  {
                                    account?.account_type === 2 ? 'IB Account' :
                                      account?.package?.name ? account?.package?.name : 'Normal Account'}
                                </Badge>
                              </Responsiveh6>
                            </Col>
                          </Row>
                          <div style={{ borderBottom: "1px solid #F0F0F0" }} />
                          {/* <Row className="mt-2">
                            <Col>
                              <p
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  dispatch({
                                    type: 'SET_DASHBOARD_ACCOUNT',
                                    account: account
                                  })
                                  setSelectedSetting(0)
                                }}>
                                {t('dashboard.titleChangeMaster')}

                              </p>
                            </Col>
                            <Col>
                              <p
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  dispatch({
                                    type: 'SET_DASHBOARD_ACCOUNT',
                                    account: account
                                  })
                                  setSelectedSetting(1)
                                }}>
                                {t('dashboard.titleChangeInvestor')}
                              </p>
                            </Col>

                          </Row> */}


                          {/*disabled button for pending status */}
                          {account?.mt5?.latestPackageApplication?.status !== 0 ?
                            <Row style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'nowrap', marginTop: '10px' }}>

                              {/* {account?.account_type == 0 &&
                                (account?.package_id == null ) ? ( */}
                              <Button
                                color="primary"
                                style={{ color: 'black', border: '1px solid black', borderRadius: '12px', marginTop: '10px', width: '100%', boxShadow: '0px 7px 20px #00000014', margin: '5px' }}
                                onClick={() => {
                                  upgradeModalToggle(account)
                                }}
                                disabled={checkUpgradeDisabled(account)}
                              >
                                {t('live.PackageUpgrade')}
                              </Button>
                              {/* )
                                :
                                <Button
                                  disabled={true}
                                  color="primary"
                                  style={{ color: 'black', border: '1px solid black', borderRadius: '12px', marginTop: '10px', width: '100%', boxShadow: '0px 7px 20px #00000014', margin: '5px' }}
                                  onClick={() => {

                                  }}
                                >
                                  {t('live.PackageUpgrade')}
                                </Button>} */}


                            </Row>
                            :
                            <Row style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'nowrap', marginTop: '10px' }}>
                              <Button
                                disabled={true}
                                color="primary"
                                style={{ color: 'black', border: '1px solid black', borderRadius: '12px', marginTop: '10px', width: '100%', boxShadow: '0px 7px 20px #00000014', margin: '5px' }}
                                onClick={() => {

                                }}
                              >
                                {t('live.PackageUpgrade')}
                              </Button>
                            </Row>
                          }

                          <Row style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'nowrap', marginBottom: '10px' }}>

                            <Button
                              id='deposit-button'
                              style={{ borderRadius: '12px', marginTop: '0px', width: '100%', border: '1px solid #84754e', boxShadow: '0px 7px 20px #00000014', margin: '5px' }}
                              onClick={() => {
                                window.open('https://secure.tradehallglobal.co/financial', '_blank')
                              }}
                            >
                              {t('live.deposit')}
                            </Button>


                          </Row>


                          {/*check the upgrade status */}

                          {account?.mt5?.latestPackageApplication?.status === 0 ?
                            <>
                              <Row style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'nowrap', marginLeft: '-10px', marginBottom: '10px', marginRight: '-10px', padding: '10px', boxShadow: '0px 4px 20px #0000000D', backgroundColor: '#D3D3D3', borderRadius: '12px' }}>
                                <p>You have a pending package upgrade application for this account. Please wait for approval.
                                  <br />
                                  <br />
                                  <p>Package Submitted at: {moment(account?.mt5?.latestPackageApplication?.created_at).format(
                                    'yyyy-MM-DD'
                                  )}</p>
                                </p>

                              </Row>


                            </>
                            : account?.mt5?.latestPackageApplication?.status === 2 ?
                              <Row style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'nowrap', marginLeft: '-10px', marginBottom: '10px', marginRight: '-10px', padding: '10px', boxShadow: '0px 4px 20px #0000000D', color: 'red', backgroundColor: '#D3D3D3', borderRadius: '12px' }}>
                                <p>Your recent application was rejected
                                  <br />
                                  <p>Reason: {account?.mt5?.latestPackageApplication?.reason}</p>
                                </p>
                              </Row>
                              : ""
                          }
                        </CardBody>

                        {account?.hasOwnProperty('latestPackageApplication') &&
                          account?.latestPackageApplication?.status == 0 &&
                          (account.package_id === null ||
                            account.package_id === 1 ||
                            account.package_id === 2 ||
                            account.package_id === 3) && (
                            <CardFooter
                              style={{
                                padding: '20px 40px',
                                color: 'black',
                                backgroundColor: '#f9b600'
                              }}
                            >
                              <p style={{ marginBottom: '0px' }}>
                                {t('live.ApplicationSubmittedOn')} :{' '}
                                {moment(account?.latestPackageApplication?.created_at).format(
                                  'yyyy-MM-DD HH:mm:ss'
                                )}
                              </p>
                              {t('live.UpgradeStatusProcess')}
                              <br />( {t('live.NeedToWait')} )
                            </CardFooter>
                          )}

                        {account?.hasOwnProperty('latestPackageApplication') &&
                          account?.latestPackageApplication?.status == 2 &&
                          (account.package_id === null ||
                            account.package_id === 1 ||
                            account.package_id === 2 ||
                            account.package_id === 3) && (
                            <CardFooter
                              style={{
                                padding: '20px 40px',
                                color: 'black',
                                backgroundColor: '#ffc4c4'
                              }}
                            >
                              <p style={{ marginBottom: '0px' }}>
                                {t('live.ApplicationSubmittedOn')} :{' '}
                                {moment(account?.latestPackageApplication?.created_at).format(
                                  'yyyy-MM-DD HH:mm:ss'
                                )}
                              </p>
                              <p style={{ marginBottom: '0px' }}>{t('live.PremiumStatusRejected')}</p>
                              <p style={{ marginBottom: '0px' }}>
                                {t('live.Reason')} : {account?.latestPackageApplication?.reason}
                              </p>
                            </CardFooter>
                          )}
                      </Card>
                    </Col>
                  )
                })
              ) : (
                  <Row 
                  className="syncCard"
                  >
                    <Col lg='12'>
                      <h3 >{t('live.NoTradeAccount')}</h3>
                      <p>{t('live.OpenTradeAccount')}</p>
                      <Button
                        color='white'
                        style={{ borderRadius: '14px', backgroundColor: 'white', fontSize: '16px', height: '40px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                        onClick={() => {

                          window.open(
                            'https://secure.tradehallglobal.co/auth'

                          );
                        }}

                      >
                        {t('live.GoOpenAccount')}
                    </Button>
                    </Col>
                  </Row>
                )}
            </Row>
          </>
        ) : (
            <div style={{ textAlign: 'center' }}>
              {/*skeleton */}
              <Row>
                <Col xl="4" lg='6' md="6">
                  <Card
                    className="card-absolute "
                    style={{
                      backgroundColor: '#f7f7f7',
                      borderRadius: '20px',
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CardBody
                      className="text-left"
                      style={{ paddingTop: '10px', marginBottom: '10px' }}
                    >
                      <Row>
                        <Col>
                          <Skeleton width={150} />
                        </Col>
                        <Col >
                          <Skeleton width={150} />
                        </Col>
                      </Row>
                      <Skeleton width={150} />
                    </CardBody>
                    <CardBody
                      className="text-left"
                      style={{ paddingTop: '10px', marginBottom: '10px' }}
                    >
                      <Row>
                        <Col>
                          <Skeleton width={100} />
                        </Col>
                        <Col >
                          <Skeleton width={100} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Skeleton width={100} />
                        </Col>
                        <Col >
                          <Skeleton width={100} />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Row className='ml-1' style={{ justifyContent: 'center' }}>
                        <Skeleton width={200} height={20} />
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4" lg='6' md="6">
                  <Card
                    className="card-absolute "
                    style={{
                      backgroundColor: '#f7f7f7',
                      borderRadius: '20px',
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CardBody
                      className="text-left"
                      style={{ paddingTop: '10px', marginBottom: '10px' }}
                    >
                      <Row>
                        <Col>
                          <Skeleton width={150} />
                        </Col>
                        <Col >
                          <Skeleton width={150} />
                        </Col>
                      </Row>
                      <Skeleton width={150} />
                    </CardBody>
                    <CardBody
                      className="text-left"
                      style={{ paddingTop: '10px', marginBottom: '10px' }}
                    >
                      <Row>
                        <Col>
                          <Skeleton width={100} />
                        </Col>
                        <Col >
                          <Skeleton width={100} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Skeleton width={100} />
                        </Col>
                        <Col >
                          <Skeleton width={100} />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Row style={{ justifyContent: 'center' }}>
                        <Skeleton width={200} height={20} />
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4" lg='6' md="6">
                  <Card
                    className="card-absolute "
                    style={{
                      backgroundColor: '#f7f7f7',
                      borderRadius: '20px',
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CardBody
                      className="text-left"
                      style={{ paddingTop: '10px', marginBottom: '10px' }}
                    >
                      <Row>
                        <Col>
                          <Skeleton width={150} />
                        </Col>
                        <Col >
                          <Skeleton width={150} />
                        </Col>
                      </Row>
                      <Skeleton width={150} />
                    </CardBody>
                    <CardBody
                      className="text-left"
                      style={{ paddingTop: '10px', marginBottom: '10px' }}
                    >
                      <Row>
                        <Col>
                          <Skeleton width={100} />
                        </Col>
                        <Col >
                          <Skeleton width={100} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Skeleton width={100} />
                        </Col>
                        <Col >
                          <Skeleton width={100} />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Row style={{ justifyContent: 'center' }}>
                        <Skeleton width={200} height={20} />
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </div>
          )}


      {/* {selectedSetting !== null && selectedAccount !== null && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{settings[selectedSetting].title}</ModalHeader>
          <ModalBody>{settings[selectedSetting].cta}</ModalBody>
        </Modal>
      )} */}
    </>
  )
}

export default ActiveLiveAccounts
