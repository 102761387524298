import client from '../request'

const answerQuiz = (data) => {
    return client.post('/quiz_participants', data)
}


export default {
    answerQuiz
}
