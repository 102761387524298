import client from "../request"


const checkoutStripe = (data) => {
    return client.post(`/discord/stripe-checkout`, data)
}

const unsubscribeSubscription = (id) => {
    return client.post(`/discord/discord-subscriptions/${id}/cancel_subscription`)
}

const checkDiscordSubscription = () => {
    return client.get(`/discord/discord-subscriptions`)
}

const checkUserExistsDiscord = (data) => {
    return client.post(`/discord/user_exists`, data)
}

const getDiscordGroups = () => {
    return client.get(`/discord/discord-groups`)
}

export default {
    checkoutStripe,
    checkDiscordSubscription,
    unsubscribeSubscription,
    checkUserExistsDiscord,
    getDiscordGroups
}
