import React from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap'
import { Home } from 'react-feather'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Bell } from 'react-feather'
import { useTranslation } from 'react-i18next'

// import Bookmark from "../bookmark";

const Breadcrumbs = (props) => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <>
      <Container fluid="sm">
        <div className="page-header p-0 " >
          <Row className="mb-4">
            <Col className='d-none d-lg-block'>
              {/* <h3>{props.title}</h3> */}

              <Breadcrumb>
                <BreadcrumbItem>
                  <Col>
                    <Button
                      style={{
                        width: '136px',
                        height: '57px',
                        borderRadius: '40px',
                        backgroundColor: '#F0F0F0',
                        color: 'black',
                        fontSize: '18px'
                      }}
                      onClick={() => history.goBack()}
                      color='#F0F0F0'
                    >{t("CheckIn.Back")}

                    </Button>
                  </Col>

                </BreadcrumbItem>

                {/* {props.children} */}
              </Breadcrumb>


              {/* <Link to='/notification' className='d-block d-sm-none'>
                  <Bell />
                </Link> */}



            </Col>
            {/* {props.action && <Col>{props.action}</Col>} */}
            <Col>
              <p style={{fontSize:'20px', fontWeight:'500'}}>
              {props.parent}
              </p>
            </Col>

          </Row>
        </div>
      </Container>
    </>
  )
}

export default Breadcrumbs
