import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import moment from "moment"
import {
    Row,
    Col,
    Card,
    Button,
    Spinner,
    Container
} from 'reactstrap'

import PammSubscriptionService from '../../../../network/services/pammSubscription'
import { useTranslation } from 'react-i18next'

import PammPendingTable from './pending/index'
import PammRejectedTable from './rejected/index'

const PammDeactiveSubscription = (props) => {
    const { t } = useTranslation()
    const [deactiveSubscribe, setDeactiveSubscribe] = useState([])
    const deActiveList = deactiveSubscribe
    const [isBusy, setIsBusy] = useState(false)
    const [RejectedResult, setRejectedResult] = useState([])
    const [pending, setPending] = useState([])

    const init = async () => {
        try {
            setIsBusy(true)
            const result = await PammSubscriptionService.getAll()
            const subscribePendingReject = result?.pending?.concat(result?.rejected_unsubscriptions)
            const subscribeRejectedReject = result?.pending?.concat(result?.rejected_unsubscriptions)
            const PendingUnsubscribe = subscribePendingReject?.filter(record => record?.record_type === 3)
            const RejectedResult = subscribeRejectedReject?.filter(record => record?.record_type === 5)

            setRejectedResult(RejectedResult ?? [])
            setPending(PendingUnsubscribe ?? [])
            setDeactiveSubscribe(result.deactive ?? {})
            setIsBusy(false)
        } catch (error) {
            setIsBusy(false)
            console.log(error)
        }
        setIsBusy(false)
    }

    const reload = () => {
        window.location.reload()
    }

    useEffect(() => {
        init()
    }, [])


    const PammDeactiveList = deActiveList.map(user => {
        return (deActiveList.length > 0 ?
            <Col lg='6' key={user.id} className="result">
                <Card >
                    <Row className='m-0' style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>

                        <Col lg='3' xs='3' className="mt-3 mb-3 " style={{ alignSelf: 'center' }} >
                            <div style={{ textAlign: 'center' }}>
                                <img id='imageSize' style={{ width: "80px", height: "80px", borderRadius: '15px' }} src={user.pammManager.avatar !== "" ? user.pammManager.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} alt={user.pammManager.name} />
                            </div>
                        </Col>

                        <Col lg='9' xs='9' className="mb-3 ml-2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="mt-3">
                                <Col lg="9">
                                    <h5 className="mt-1" style={{ color: '#fbc54d' }}>{user.pammManager.name}</h5>
                                </Col>
                            </div>

                            <div >
                                <Link to={`/veFund/${user.pammManager.id}/statistic`}>
                                    <Col lg="6">
                                        <p style={{ color: '#103c5a', lineHeight: '1.3', letterSpacing: '0', fontWeight: 'bold' }}>{t("pammSubsccription.ReviewStatistic")}</p>
                                    </Col>
                                </Link>
                            </div>
                            <div>
                                <div className="mt-0">
                                    <Col lg="9">
                                        <p className='mb-0'>{t('pammSubsccription.UnsubscribeDate')} : </p>
                                        <p>{moment(user?.updated_at).format('yyyy-MM-DD')}</p>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            : <h1 style={{ marginLeft: '15px' }}>{t("pammSubsccription.youDoNotHaveAnyUnsubscribe")}</h1>

        )
    })
    return (
        <>
            {/* <h3 style={{ color: '#103c5a', marginLeft: '15px' }}>{t("pammSubsccription.PAMMSubscriptions")}</h3>
            <p style={{ color: '#868686', marginLeft: '15px' }}>{t("pammSubsccription.viewingAllUnsubscribedManagers")}</p> */}
            {deActiveList.length > 0 ? (
                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <h4 className='d-none d-lg-block'>{t("pammSubsccription.success")}</h4>
                        <h6 className='d-block d-lg-none'>{t("pammSubsccription.success")}</h6>

                    </Row>

                    <Container className="p-0">
                        <Row>
                            {deActiveList.length > 0 && PammDeactiveList}
                        </Row>
                    </Container>


                </Container>

            ) : (
                ""
            )}


            {pending.length > 0 &&
                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <h4 className='d-none d-lg-block'>{t("pammSubsccription.pending")}</h4>
                        <h6 className='d-block d-lg-none'>{t("pammSubsccription.pending")}</h6>
                    </Row>
                    <Row className='m-0' style={{ justifyContent: 'space-between', width: '100%' }}>
                        <PammPendingTable />
                    </Row>
                </Container>
            }


            {RejectedResult.length > 0 &&

                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <h4 className='d-none d-lg-block'>{t("pammSubsccription.rejected")}</h4>
                        <h6 className='d-block d-lg-none'>{t("pammSubsccription.rejected")}</h6>
                    </Row>
                    <Row className='m-0' style={{ justifyContent: 'space-between', width: '100%' }}>
                        <PammRejectedTable />
                    </Row>
                </Container>

            }

            <Row className="mb-5">
                {isBusy ? <><Spinner size="md" color="warning"></Spinner><h4>{t("pammSubsccription.loading")}...</h4><br /></> : ''}
                {/* Show when deactive list length is 0 */}
                {deActiveList.length <= 0 && !isBusy &&
                    <Button style={{ marginLeft: '30px', borderRadius: '15px', color: 'black' }} color="primary" onClick={reload}>
                        {t("pammSubsccription.SubscribeHere")}
                    </Button>}
            </Row>



        </>
    )
}

export default PammDeactiveSubscription

