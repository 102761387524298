import React, { useState, useEffect } from 'react'
import { Minimize, Bell } from 'react-feather'
import { translate } from 'react-switch-lang'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { Container, Card, CardBody, Col, Row, Button } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import DarkMode from '../../components/header/dark_mode'
import Profile from '../../components/header/profile'
import LanguageIcon from '../../language.png'
import '../../components/header/profile/profile.scss'

import notification from './notification.png'

import { toast } from "react-toastify"

import { useTranslation } from 'react-i18next'

import ReactGA from 'react-ga'

const HighlightDiv = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover{
    color: white;
  }

`



// const MobileOnly = styled.div`
//   display: none;

//   @media (max-width: 575px) {
//     display: block;
//   }
// `

const Rightbar = () => {

  let history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const unread_notification = useSelector((state) => state.notification?.unread_notifications)

  // const [unReadNotificationCount, setUnReadNotificationCount] = useState([])

  // const [languageSelected, setLanguageSelected] = useState('en')
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const language = sessionStorage.getItem('language')

  const languageToggle = () => setLanguageDropdownOpen(prevState => !prevState);

  const pathname = window.location.pathname


  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  // const init = () => {

  //   if (verification?.status === 3) {
  //     return <>
  //         {toast.warning(
  //            <Link to='' style={{color:'red'}}>
  //               <p>{t('dashboard.yourRecentApplicationWasRejected')}</p>

  //               <p>{`${t('dashboard.reason')}: ${verification?.reason}`}</p>
  //            </Link>,{
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: false,
  //             className: 'toast-notification',
              
  //            },
  //         )}
  //     </>
  //     }
  // }


  // useEffect(() => {

  //   init()
  //   if (unread_notification?.length && !window.location.toString().includes("notification")) {
  //     return <>

  //       {toast.success(
  //         <Link to='/notification' style={{ color: 'white' }}>
  //           {t("header.YouHave")} {unread_notification?.length} {t("header.notification")}
  //         </Link>
  //         , {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: false,
  //           className: 'toast-notification'
  //         },

  //       )}

        
  //       </>}

  // }, [])


  return (
    <>
      {/* <div className="nav-right pull-right right-menu"> */}
      <div className='nav-right' style={{width:'95%'}}>
        {/* <ul className="nav-menus"> */}
        <ul style={{marginTop:'10px'}}>
          <Row style={{width:'100%', flexWrap:'nowrap'}}> 

          {/* <Col className='d-lg-none d-xl-none' onClick={() => trackPersonal()} style={{ fontSize:'18px'}}> */}
          <Col className='d-none d-md-block d-sm-block d-lg-none' style={{ fontSize:'18px', padding:'0px'}}>
            <HighlightDiv
              style={{color: pathname == `${process.env.PUBLIC_URL}/dashboard` ?'#C4A569':'black', justifyContent:'flex-start'}}
            >
          
                <img
                  style={{float:'left', maxWidth:'200px', marginLeft:'20px'}}
                  src={require('../../assets/images/logo/ve-logo.png')}
                  alt=""
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard`)
                  }}
                />
            </HighlightDiv>
          </Col>

          <Col className='d-block d-lg-none d-md-none d-sm-none' style={{ fontSize:'18px', padding:'0px'}}>
            <HighlightDiv
              style={{color: pathname == `${process.env.PUBLIC_URL}/dashboard` ?'#C4A569':'black', justifyContent:'flex-start'}}
            >
          
                <img
                  style={{float:'left', maxHeight:"50px", marginLeft:'20px'}}
                  src={require('../../assets/images/logo/ve-logo-only.png')}
                  alt=""
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard`)
                  }}
                />
            </HighlightDiv>
          </Col>
    
          <Col className='d-none d-lg-block d-xl-block' style={{ fontSize:'18px'}}>
            <HighlightDiv
              style={{color: pathname == `${process.env.PUBLIC_URL}/dashboard` ?'#C4A569':'black', justifyContent:'center'}}
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/dashboard`)
              }}
            >
            
              {t("header.home")}
            </HighlightDiv>
          </Col>

          <Col className='d-none d-lg-none d-xl-block'  style={{fontSize:'18px'}}>
            <HighlightDiv
              style={{color: pathname == `${process.env.PUBLIC_URL}/tradeAccount` ?'#C4A569':'black', justifyContent:'center'}}
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/tradeAccount`)
              }}
            >
          
              {t("header.trade")}
            </HighlightDiv>
          </Col>
          <Col className='d-none d-lg-block d-xl-block '  style={{fontSize:'18px'}}>
            <HighlightDiv
              style={{color: pathname == `${process.env.PUBLIC_URL}/courses` ?'#C4A569':'black', justifyContent:'center'}}
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/courses`)
              }}
            >
           
              {t("header.course")}
            </HighlightDiv>
          </Col>

          
          <Col className='d-none d-lg-block d-xl-block' style={{fontSize:'18px'}}>
            <HighlightDiv
              style={{color: pathname == `${process.env.PUBLIC_URL}/veFund` ?'#C4A569':'black', justifyContent:'center'}}
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/veFund`)
              }}
            >
           
              {t("header.pamm")}
            </HighlightDiv>
          </Col>
          </Row>

          {/* <li className="onhover-dropdown">
            <div
              className="notification-box"
            >
              
              <Link to='/notification'>
                <img src={notification} width='20px'/>
              </Link>
              <span className="badge badge-pill badge-primary">{unread_notification?.length}</span>
            </div>
          </li> */}
                

          {/* <MobileOnly>
            
          </li> */}
          <li style={{marginLeft:'50px'}}>
            <Profile />
          </li>
            
          {/* <div className="d-none d-lg-block d-xl-block d-xl-block" style={{height: '30px', marginRight:'100px', zIndex:'1'}}>
          <ul class="menu--main" style={{marginTop:'20px'}}>
            <li style={{display:'flex', borderRadius:'25px', backgroundColor:'#C4A569'}}>
                   <img src={LanguageIcon} alt="languageIcon" width='18px' height="18px" className="m-r-5" />
                    {language === "en" && <span className="d-none d-lg-block d-xl-block">EN</span>}
                    {language === "zh" && <span className="d-none d-lg-block d-xl-block">ZH</span>}
              <ul class="sub-menu" style={{width:'100px', marginLeft:'-10px'}}>
                
                  <li onClick={() => changeLanguage('en')}>
                    <div style={{marginLeft:''}}>
                      English
                    </div>
                  </li>
                  <li onClick={() => changeLanguage('zh')}>
                    <div style={{marginLeft:''}}>
                      中文简体
                    </div>
                  </li>
                
              </ul>

            </li>
            
          </ul>
      </div> */}
          
        </ul>
      </div>
    </>

  )
}
export default translate(Rightbar)
