import React, { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Collapse,
    Button,
    Progress,
    Container,
    Badge,
    Input
} from 'reactstrap'
import parse from 'html-react-parser'
import {
    BrowserRouter as Router,
    useParams,
    useHistory
} from "react-router-dom";

import { useDispatch } from 'react-redux'

import CourseService from '../../../../network/services/courses'
import QuizService from '../../../../network/services/quiz'
import ModuleService from '../../../../network/services/modules'



import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'

import "./index.scss";
import "./course.css";


import {
    Video,
    ChevronDown,
    ChevronUp,
    Book,
    FileText, CheckCircle,
    AlertCircle

} from 'react-feather'

import ReactGA from 'react-ga'


const PammManager = (props) => {

    const history = useHistory();
    const dispatch = useDispatch()
    const { id } = useParams()

    const { t } = useTranslation()


    const [allChapter, setAllChapter] = useState([])

    const [courseResult, setCourseResult] = useState({})

    const [description, setDescription] = useState([])

    const [isCollapse, setIsCollapse] = useState(false)
    const [isChapterOpenId, setIsChapterOpenId] = useState('')
    const toggleCollapse = (chapter) => {
        setIsChapterOpenId(chapter?.id)
        setIsCollapse(true)

    };

    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
    }

    const [selectedVideoIndex, setSelectedVideoIndex] = useState('')
    const [video, setVideo] = useState('')
    const [videoLink, setVideoLink] = useState('https://player.vimeo.com/video/918242866?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')
    const [videoID, setVideoID] = useState('918242866')
    const [selectedLesson, setSelectedLesson] = useState(null)

    const [isSubmitted, setIsSubmitted] = useState(false)

    const [selectCorrectAnswer, setSelectCorrectAnswer] = useState([])

    const [finalResult, setFinalResult] = useState(null)




    return (
        <Container className='mt-5' style={{ marginBottom: '70px' }}>
            <Row style={{ marginTop: '110px' }}>
                <Col className='d-none d-lg-block'>
                    <Button
                        style={{
                            width: '136px',
                            height: '57px',
                            borderRadius: '40px',
                            backgroundColor: '#F0F0F0',
                            color: 'black',
                            fontSize: '18px'
                        }}
                        onClick={() => navigate(`${process.env.PUBLIC_URL}/courses/ppt/journey`)}
                        color='#F0F0F0'
                    >{t('courses.Back')}

                    </Button>
                </Col>
                <Col className='d-none d-lg-block'>
                    <p style={{ fontSize: '20px', fontWeight: '500' }}>
                        {allChapter[0]?.name}
                    </p>
                </Col>
            </Row>
            <Row style={{ padding: '0' }}>
                <Col className='mt-5'>
                    <div>
                        <div style={{ padding: '6% 0 50% 0', position: 'relative', backgroundColor: videoLink && 'black' }}>
                            <iframe src={videoLink}
                                frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen
                                style={{ position: 'absolute', top: '0', left: '0' }}
                                id='frame-vimeo'
                                title="Screen Recording 2021-11-29 at 11.09.29 AM.mov"
                                onLoad={async () => {
                                    if (video?.userCourseProgresses?.playback_status === 0) {
                                        const completeResult = await CourseService.updateChapterProgress(videoID, {
                                            playback_status: 1
                                        })

                                        const chaptersResult = await CourseService.getCourseChapter(id)
                                        setAllChapter(chaptersResult?.chapters)
                                        setCourseResult(chaptersResult?.course)
                                    }
                                }}
                            >
                            </iframe>
                        </div>

                        <div className='mt-4' style={{ whiteSpace: 'pre-wrap' }}>

                            {selectedLesson?.description != null ?
                                <h5>{parse(String(selectedLesson?.description))}</h5>
                                : ''}

                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>




                </Col>
            </Row>



        </Container >
    )
}

export default PammManager