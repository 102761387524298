import client from "../request"

const sendCode = (data) => {
    return client.post(`/link/code`, data)
}

const verifyAccount = (data) => {
    return client.post(`/link/login`, data)
}

const getAccountsFromTH = () => {
    return client.get(`/link/accounts`)
}



export default {
    sendCode,
    verifyAccount,
    getAccountsFromTH
}
