import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Link } from 'react-router-dom'

import EyeGameService from '../../../network/services/eyeGame'


import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap'


const Leaderboard = ({ refresh }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()



  const columns = [
    {
      name: 'Rank',
      selector: (row, index) => data?.indexOf(row) + 1,
      sortable: true
    },
    {
      name: 'Name',
      selector: 'subject',
      width: '200px',
      sortable: true,
      format: (row) => {
        return row?.gameUser?.name
      }
    },

    {
      name: 'Mark',
      selector: 'is_read',
      sortable: true,
      format: (row) => {
        return `${row?.total_correct_question} / 10`
      }
    },

  ]

  const [data, setData] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('')


  const init = async () => {
    const result = await EyeGameService.getLeaderboard()

    console.log('resultresult', result)
    setData(result.leaderboard ?? [])
  }

  useEffect(() => {
    init()
  }, [])



  return (
    <>
      {/* <h5>{t("support.tickets")}</h5> */}

      <div id="supportTable" className="product-wrapper-grid" style={{ marginTop: 16 }}>
        <DataTable
          noHeader
          data={data}
          columns={columns}
          striped={true}
          center={true}
        // pagination
        // paginationComponentOptions={{ noRowsPerPage: true }}
        // paginationServer
        // paginationTotalRows={total}
        // onChangePage={handlePageChange}
        // style={{ paddingBottom: 8 }}

        // subHeader
        // subHeaderComponent={
        //   <h6 id='sub-header-support' style={{ textAlign: 'center', height: 'auto', marginBottom: '4px', fontWeight: '500' }}>
        //     Leaderboard
        //   </h6>
        // }
        // subHeaderAlign="center"
        />
      </div>
    </>
  )
}

export default Leaderboard
