import client from '../request'

const getAll = () => {
    return client.get('/modules')
} // findMyModules

const getModuleChapter = (id) => {
    return client.get(`/me/modules/${id}`)
} // findMyModuleChapters

const getModuleChapterUnderCourse = (id) => {
    return client.get(`/modules/${id}/chapters/`)
} // findAllModuleChaptersWithCourseId



export default {
    getAll,
    getModuleChapter,
    getModuleChapterUnderCourse,
}
