import {
  Home,
  Monitor,
  User,
  DollarSign,
  Download,
  Phone,
  Navigation,
  Navigation2,
  Compass,
  LogOut,
  Layers,
  List,
  Flag,
  Users,
  Video,
  HelpCircle,
  Gift,
  Award,
  BookOpen
} from 'react-feather'
import Auth from '../../network/services/auth'

export const MENUITEMS = [
  // {
  //   menuTitle: "sidebar.hot",
  //   menuContent: "sidebar.event",
  //   type: 'container'
  // },
  // {
  //   title: 'sidebar.promotion',
  //   icon: Heart,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/promotion`
  // },

  // {
  //   menuTitle: 'Carnival',
  //   menuContent: 'TradeHall Carnival 2022',
  //   icon: Award,
  //   type: 'carnival-container',
  //   path: `${process.env.PUBLIC_URL}/carnival`
  // },

  {
    container_id: 1,
    menuTitle: 'sidebar.main',
    menuContent: 'sidebar.funding',
    type: 'container'
  },

  {
    container_parent_id: 1,
    title: 'sidebar.dashboard',
    icon: Home,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/dashboard`
  },

  {
    container_parent_id: 1,
    title: 'sidebar.Deposit/Withdrawal',
    icon: DollarSign,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/financial`,
    id: 'financial-sidebar'
  },

  {
    container_parent_id: 1,
    title: 'sidebar.partnerRoom',
    icon: User,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/partner`,
    verified: true
  },

  {
    container_parent_id: 1,
    title: 'sidebar.liveMonitoring',
    icon: Monitor,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/monitoring`
  },
  {
    container_parent_id: 1,
    title: 'sidebar.contest',
    icon: Flag,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/event`
  },

  {
    container_parent_id: 1,
    title: 'sidebar.customerSupport',
    icon: Phone,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/support`
  },
  {
    container_parent_id: 1,
    title: 'sidebar.faqs',
    icon: HelpCircle,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/faqs`
  },

  {
    container_id: 2,
    menuTitle: 'PAMM',
    menuContent: 'sidebar.pammMenu',
    type: 'container'
  },
  // {
  //   container_parent_id: 2,
  //   title: 'sidebar.myPammAccount',
  //   icon: User,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/mypamm_account`
  // },
  {
    container_parent_id: 2,
    title: 'sidebar.pammManagerList',
    icon: Layers,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/veFund`
  },
  // {
  //   container_parent_id: 2,
  //   title: 'sidebar.pammSubscription',
  //   icon: List,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/mysubscription`
  // },

  {
    container_id: 3,
    menuTitle: 'Courses',
    menuContent: 'All Courses',
    type: 'container'
  },
  {
    container_parent_id: 3,
    title: 'sidebar.courses',
    icon: Video,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/courses`
  },
  // {
  //   container_parent_id: 3,
  //   title: 'sidebar.myCourses',
  //   icon: BookOpen,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/my_courses`
  // },




  {
    container_id: 4,
    menuTitle: 'MT5',
    menuContent: 'sidebar.accounts',
    type: 'container'
  },
  {
    container_parent_id: 4,
    title: 'sidebar.demo',
    icon: Navigation,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/demo`
  },
  {
    container_parent_id: 4,
    title: 'sidebar.live',
    icon: Navigation2,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/live`
  },
  {
    container_parent_id: 4,
    title: 'competition.Contest',
    icon: Compass,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/competition_account`
  },

  {
    container_id: 5,
    menuTitle: 'Others',
    menuContent: 'Others',
    type: 'container',
    mobile: true
  },

  {
    container_parent_id: 5,
    title: "sidebar.inviteFriend",
    icon: Users,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/referral_friend`,
    verified: false
  },

  {
    container_parent_id: 5,
    title: 'sidebar.tradingPlatforms',
    icon: Download,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/platform`
  },
  {
    container_parent_id: 5,
    title: 'sidebar.vouchers',
    icon: Gift,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/vouchers`
  },


  // {
  //   container_id: 6,
  //   menuTitle: 'sidebar.user',
  //   menuContent: 'sidebar.setting',
  //   type: 'container',
  //   mobile: true
  // },
  {
    container_id: 7,
    container_parent_id: 6,
    title: 'sidebar.account',
    icon: User,
    type: 'action',
    path: `${process.env.PUBLIC_URL}/account`,
    mobile: true
  },
  {
    container_id: 8,
    container_parent_id: 6,
    title: 'sidebar.logOut',
    icon: LogOut,
    type: 'action',
    action: () => Auth.logout(),
    mobile: true
  },

]
