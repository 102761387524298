import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import SignInForm from './sign_in'
import { useTranslation } from 'react-i18next'

import SettingService from '../../network/services/setting'
import 'animate.css'
import LanguageIcon from '../../language.png'

const Authentication = () => {

  const [logo, setLogo] = useState(null)
  const { t, i18n } = useTranslation()

  const [languageSelected, setLanguageSelected] = useState('en')

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const languageToggle = () => setLanguageDropdownOpen((prevState) => !prevState)


  const init = async () => {
    try {
      const result = await SettingService.getAll()
      if (result.settings) {
        let logo2 = result.settings.find((s) => {
          return s.handle === 'crm_logo_url'
        })

        setLogo(logo2 ?? {})
      } else {
        setLogo({})
      }
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {logo != null ? (
        <Container
          style={{
            maxWidth: '100%',
            // backgroundColor: '#f8f8f8',

            backgroundSize: 'cover'
          }} >

          <Row className="h-100-d-center m-0" style={{ paddingBottom: "0px" }} >
            <Col lg="12" id="login-col" style={{ textAlign: 'left', marginRight: '0px' }}>
              <SignInForm logo={logo} />
            </Col>
          </Row>
        </Container>
      ) : (
          <>
            <Container
              style={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner />
            </Container>
          </>
        )}
    </>
  )
}

export default Authentication


