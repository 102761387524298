import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import './footer.scss'
import LanguageIcon from '../../language.png'
import home from './home.png'
import courses from './courses.png'
import account from './account.png'
import live from './up-arrow.png'


const Paragraph = styled.p`
  margin: 25px 20px 20px 20px;

  @media only screen and (max-width: 575px) {

  }
`

const Footer = (props) => {
  const { t } = useTranslation()
  const Year = new Date();
  const history = useHistory()
  const dispatch = useDispatch()

  const pathname = window.location.pathname

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
  }

  return (
    <>
      {/* <footer className="footer"> */}
      <Row style={{ marginRight: '0px', marginTop: '0px', backgroundColor: 'white', justifyContent: 'center' }}>
        <Col lg="6" sm="12" md="5" className="footer-copyright d-none d-lg-flex d-sm-none d-xs-none" style={{ display: "flex", justifyContent: "start", alignItems: "center", backgroundColor: ' #C4A569', margin: '0', padding: '30px 0px' }}>
          {/* <p className="mb-0">{t("footer.support")}</p> */}
          <p className="ml-5 text-white mb-0" style={{ fontSize: '16px', justifyContent: 'flex-start', display: 'flex' }}>info@ve.education</p>
        </Col>
        <Col
          lg="6"
          className="d-none d-lg-flex d-xl-flex"
          style={{ backgroundColor: '#C4A569', margin: '0', backgroundColor: ' #C4A569', justifyContent: 'end' }}
        >
          <Row style={{ display: "flex", justifyContent: "start", alignItems: "center", backgroundColor: ' #C4A569', margin: '0' }}>
            <p className='text-white mb-0' style={{ fontSize: '16px', justifyContent: 'flex-end', display: 'flex' }}>{t("footer.copyright")} {Year.getFullYear()}</p>
            <img style={{ marginLeft: '25px', width: '200px' }}
              src={require('../../assets/images/logo/ve-logo-white.png')}
            ></img>
          </Row>
        </Col>
        {/* <Col lg="2" className="d-none d-lg-block d-xl-none d-xl-block" style={{ float: 'left', backgroundColor: 'white', marginBottom: '15px' }}>


        </Col> */}

        <Col
          className="d-block d-lg-none d-xl-none"
        >
          <Row className="mobilefooter" >

            {pathname === `${process.env.PUBLIC_URL}/dashboard` ?
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/dashboard`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={home} width='35px' height="35px" />
                  {t('footer.home')}
                </div>
              </Col>
              :
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/dashboard`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={home} width='25px' height="25px" />
                  {t('footer.home')}
                </div>

              </Col>
            }

            {pathname === `${process.env.PUBLIC_URL}/courses` ?
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/courses`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={courses} width='35px' height="35px" />
                  {t('footer.courses')}
                </div>


              </Col>
              :
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/courses`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={courses} width='25px' height="25px" />
                  {t('footer.courses')}
                </div>


              </Col>
            }

            {pathname === `${process.env.PUBLIC_URL}/tradeAccount` ?
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/tradeAccount`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={live} width='35px' height="35px" />
                  {t('footer.live')}
                </div>

              </Col>
              :
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/tradeAccount`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={live} width='25px' height="25px" />
                  {t('footer.live')}
                </div>

              </Col>
            }

            {pathname === `${process.env.PUBLIC_URL}/account` ?
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/account`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={account} width='35px' height="35px" />
                  {t('footer.me')}
                </div>

              </Col>
              :
              <Col
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/account`)
                }}
                style={{ alignSelf: 'center' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={account} width='25px' height="25px" />
                  {t('footer.me')}
                </div>

              </Col>
            }
          </Row>
        </Col>


      </Row>
      {/* </footer> */}
    </>
  )
}

export default Footer
