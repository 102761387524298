import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    Badge,
    Button,
    Container,
} from 'reactstrap'

import CourseService from '../../../network/services/courses'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './pammManager.scss'
import './carousel.scss'

import "intro.js/introjs.css";
import { Steps } from 'intro.js-react';

import ReactGA from 'react-ga'

const Courses = (props) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })

    }

    const { t } = useTranslation()
    // const [pammManager, setPammManager] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [courseLanguageSelected, setCourseLanguage] = useState('')

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const [allCourses, setAllCourses] = useState([])
    const [isbusy, setIsbusy] = useState(false)

    const [availableCourses, setAvailableCourses] = useState([])
    const [notAvailableCourses, setNotAvailableCourses] = useState([])
    const [premiumCourse, setPremiumCourse] = useState([])

    const init = async () => {
        setIsbusy(true)
        const coursesResult = await CourseService.getAll()

        setAllCourses(coursesResult?.courses)
        setIsbusy(false)
        setPremiumCourse(coursesResult?.courses?.filter((course => !course.is_course_available && course.course_language == 'chinese' && course.name !== 'PPT 2.0' )))

        setAvailableCourses(coursesResult?.courses?.filter((course => {
            return course.is_course_available  && course.name !== 'PPT 2.0' 
        })))

        setNotAvailableCourses(coursesResult?.courses?.filter((course => {
            return course.is_course_available === false
        })))

    }

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    const toChapterPage = (course, courseID) => {

        if (course?.is_course_available && course.name === 'PPT') {
            navigate(`${process.env.PUBLIC_URL}/courses/ppt/journey`)
            return;
        }

        if (course?.is_course_available) {
            navigate(`${process.env.PUBLIC_URL}/courses/${course?.id}/chapters`)
        } else if (courseID === 9 || courseID === 10) {
            SweetAlert.fire({
                showClass: {
                    popup: 'animate__animated animate__backInUp'
                },
                hideClass: {
                    popup: 'animate__animated animate__backOutDown'
                },
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} Find <a href="https://wa.me/601120682128?text=Hi%2C%20I%20need%20assist%20on%20VE%20Connect" target="_blank">Customer Service</a> To View This Content</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true,
            })

        }
        else {
            SweetAlert.fire({
                showClass: {
                    popup: 'animate__animated animate__backInUp'
                },
                hideClass: {
                    popup: 'animate__animated animate__backOutDown'
                },
                title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
                html: `<p>${t('courses.YouMay')} <a href="/tradeAccount" target="_blank">${t('courses.Upgrade')}</a> ${t('courses.ToThisPackageToViewThisContent')}</p>`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true,
            })
        }
    }



    return (
        <>
            <Container className='d-none d-xl-block d-lg-block d-md-block'>


                {/* desktop skeleton courses */}
                {isbusy &&
                    <div className='mt-5' style={{ textAlign: 'center' }}>
                        <Row>
                            <Col xl='3' lg='6' xs='8' sm='12' className="result" style={{ marginBottom: '30px' }}>
                                <Card
                                    style={{
                                        boxShadow: '0px 4px 20px #0000000D',
                                        backgroundColor: 'white',
                                        color: 'black',

                                    }}
                                >
                                    <div style={{ marginTop: '-5px' }}>
                                        <Skeleton width="100%" height={130} borderRadius={15} />
                                    </div>
                                    <Row className="ml-3 mt-3" style={{ color: 'black' }}>
                                        <Skeleton width={200} height={10} />
                                    </Row>

                                    <Row className="ml-3" style={{ color: 'black' }}>
                                        <Skeleton width={100} height={10} />
                                    </Row>
                                    <Row style={{ color: 'black', marginBottom: '0px' }}>
                                        <Skeleton />
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl='3' lg='6' xs='8' sm='12' className="result" style={{ marginBottom: '30px' }}>
                                <Card
                                    style={{
                                        boxShadow: '0px 4px 20px #0000000D',
                                        backgroundColor: 'white',
                                        color: 'black',

                                    }}
                                >
                                    <div style={{ marginTop: '-5px' }}>
                                        <Skeleton width="100%" height={130} borderRadius={15} />
                                    </div>
                                    <Row className="ml-3 mt-3" style={{ color: 'black' }}>
                                        <Skeleton width={200} height={10} />
                                    </Row>

                                    <Row className="ml-3" style={{ color: 'black' }}>
                                        <Skeleton width={100} height={10} />
                                    </Row>
                                    <Row style={{ color: 'black', marginBottom: '0px' }}>
                                        <Skeleton />
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl='3' lg='6' xs='8' sm='12' className="result" style={{ marginBottom: '30px' }}>
                                <Card
                                    style={{
                                        boxShadow: '0px 4px 20px #0000000D',
                                        backgroundColor: 'white',
                                        color: 'black',

                                    }}
                                >
                                    <div style={{ marginTop: '-5px' }}>
                                        <Skeleton width="100%" height={130} borderRadius={15} />
                                    </div>
                                    <Row className="ml-3 mt-3" style={{ color: 'black' }}>
                                        <Skeleton width={200} height={10} />
                                    </Row>

                                    <Row className="ml-3" style={{ color: 'black' }}>
                                        <Skeleton width={100} height={10} />
                                    </Row>
                                    <Row style={{ color: 'black', marginBottom: '0px' }}>
                                        <Skeleton />
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }



                <Row  style={{ marginTop: "25px", overflow: 'auto', display: 'flex' }}>

                    {availableCourses?.map(course => {
                        return (

                            <Col key={course.id} xl='3' lg='6' xs='8' sm='12' className="result mb-5">
                                {/* <Link to='courses/course/123'> */}
                                <Card

                                    style={{
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                        backgroundColor: 'white',
                                        color: 'black',

                                    }}
                                >

                                    <div style={{
                                        filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                    }}>

                                        <div >
                                            {
                                                course?.is_course_available === false &&
                                                <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                            }
                                            <img alt='course background' src={course?.cover_image_url}
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '225px',
                                                    borderTopLeftRadius: '15px',
                                                    borderTopRightRadius: '15px'
                                                }}
                                            />

                                        </div>

                                        <Row style={{ marginTop: '-17px', color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                            <Badge
                                                className='p-t-10'
                                                style={{
                                                    borderRadius: '7px',
                                                    color: 'black',
                                                    width: 'auto',
                                                    height: '32px',
                                                    fontSize: '14px'
                                                }}
                                                color="primary"
                                            >
                                                {course?.difficulty}
                                            </Badge>
                                            <Badge
                                                className='p-t-10'
                                                style={{
                                                    textTransform: 'capitalize',
                                                    borderRadius: '7px',
                                                    color: 'black',
                                                    width: '98px',
                                                    height: '32px',
                                                    backgroundColor: '#CDCFD7',
                                                    fontSize: '14px'
                                                }}
                                                color=""
                                            >

                                                {course?.course_language}
                                            </Badge>


                                        </Row>

                                        <Row style={{ color: 'black', marginBottom: '0px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>

                                            <h4>{course?.name}</h4>

                                        </Row>

                                        <Row className="mt-0" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                            <div>
                                                <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                            </div>
                                        </Row>

                                        <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                            <div style={{ display: 'flex' }}>
                                                <h5 style={{ margin: '0', textAlign: 'right', color: ' #C4A569' }}>{course?.course_progress}% </h5>
                                                <p className='ml-2'>{t('courses.Complete')}</p>
                                            </div>

                                        </Row>


                                        <Row style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                            {/* Only PPT can feedback */}
                                            {
                                                course.id == 23 ?
                                                    <>
                                                        <a href='https://forms.gle/62nGqWwH72TBMFreA' target='_blank'>
                                                            <Button
                                                                style={{
                                                                    borderRadius: '26px',
                                                                    width: '140px',
                                                                    height: '60px',
                                                                    boxShadow: '0px 7px 20px #00000014',
                                                                    color: 'white',
                                                                    fontSize: '16px',
                                                                    fontWeight: '500',
                                                                    marginBottom: '-30px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                color="secondary"
                                                                onClick={() => {

                                                                }}
                                                            >
                                                                Question

                                                            </Button>
                                                        </a>
                                                        <Button
                                                            style={{
                                                                borderRadius: '26px',
                                                                width: '140px',
                                                                height: '60px',
                                                                boxShadow: '0px 7px 20px #00000014',
                                                                color: 'black',
                                                                fontSize: '16px',
                                                                fontWeight: '500',
                                                                marginBottom: '-30px',
                                                                cursor: 'pointer'
                                                            }}
                                                            color="primary"
                                                            onClick={() => {
                                                                toChapterPage(course)
                                                                ReactGA.event({
                                                                    category: 'Courses',
                                                                    action: 'Click not available courses ',
                                                                    label: 'Click not available courses '
                                                                })
                                                            }}
                                                        >
                                                            {t("courses.View")}

                                                        </Button>
                                                    </>

                                                    :
                                                    <Button
                                                        style={{
                                                            borderRadius: '26px',
                                                            width: '195px',
                                                            height: '60px',
                                                            boxShadow: '0px 7px 20px #00000014',
                                                            color: 'black',
                                                            fontSize: '20px',
                                                            fontWeight: '500',
                                                            marginBottom: '-30px',
                                                            cursor: 'pointer'
                                                        }}
                                                        color="primary"
                                                        onClick={() => {
                                                            toChapterPage(course)
                                                            ReactGA.event({
                                                                category: 'Courses',
                                                                action: 'Click not available courses ',
                                                                label: 'Click not available courses '
                                                            })
                                                        }}
                                                    >
                                                        {t("courses.View")}

                                                    </Button>
                                            }


                                        </Row>

                                    </div>

                                </Card>
                               

                            </Col>
                        )

                    })}


                </Row>

                <Row style={{ marginTop: "25px", display: 'flex', zIndex: '1' }}>

                    {premiumCourse?.map(course => {
                        return (
                            course?.status &&
                            <Col xl='3' lg='6' xs='8' sm='12' className="result mb-5">

                                <Card
                                    style={{
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                        backgroundColor: 'white',
                                        color: 'black',

                                    }}
                                >

                                    <div style={{

                                        filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                    }}>

                                        <div >
                                            {
                                                course?.is_course_available === false &&
                                                <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                            }
                                            <img alt='course background' src={course?.cover_image_url}
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '225px',
                                                    borderTopLeftRadius: '15px',
                                                    borderTopRightRadius: '15px'
                                                }}
                                            />

                                        </div>

                                        <Row style={{ marginTop: '-17px', color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                            <Badge
                                                className='p-t-10'
                                                style={{
                                                    borderRadius: '7px',
                                                    color: 'black',
                                                    width: 'auto',
                                                    height: '32px',
                                                    fontSize: '14px'
                                                }}
                                                color="primary"
                                            >
                                                {course?.difficulty}
                                            </Badge>
                                            <Badge
                                                className='p-t-10'
                                                style={{
                                                    textTransform: 'capitalize',
                                                    borderRadius: '7px',
                                                    color: 'black',
                                                    width: '98px',
                                                    height: '32px',
                                                    backgroundColor: '#CDCFD7',
                                                    fontSize: '14px'
                                                }}
                                                color=""
                                            >

                                                {course?.course_language}
                                            </Badge>


                                        </Row>

                                        <Row style={{ color: 'black', marginBottom: '0px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>

                                            <h4>{course?.name}</h4>

                                        </Row>

                                        <Row className="mt-0" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                            <div>
                                                <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                            </div>
                                        </Row>

                                        <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                            <div style={{ display: 'flex' }}>
                                                <h5 style={{ margin: '0', textAlign: 'right', color: ' #C4A569' }}>{course?.course_progress}% </h5>
                                                <p className='ml-2'>{t('courses.Complete')}</p>
                                            </div>

                                        </Row>
                                        <Row style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                            <Button
                                                style={{
                                                    borderRadius: '26px',
                                                    width: '195px',
                                                    height: '60px',
                                                    boxShadow: '0px 7px 20px #00000014',
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    marginBottom: '-30px',
                                                    cursor: 'pointer'
                                                }}
                                                color="primary"
                                                onClick={() => {
                                                    toChapterPage(course)
                                                    ReactGA.event({
                                                        category: 'Courses',
                                                        action: 'Click not available courses ',
                                                        label: 'Click not available courses '
                                                    })
                                                }}
                                            >
                                                {t("courses.View")}

                                            </Button>

                                        </Row>

                                    </div>


                                </Card>


                            </Col>
                        )

                    })}
                </Row>



            </Container>
            {/*mobile size */}
            <Container className='d-block d-lg-none d-xl-none d-md-none mb-5' style={{ zIndex: '1' }}>



                {availableCourses.length >= 1  ?
                    <>
                        <Row className="courseScrollBar" style={{ marginTop: "25px", overflow: 'auto', display: 'flex', flexWrap: 'nowrap' }}>

                            {availableCourses?.map(course => {
                                return (

                                    <Col key={course.id} xl='4' lg='6' xs='8' sm='8' className="result" style={{ marginBottom: '30px' }}>
                                        {/* <Link to='courses/course/123'> */}
                                        <Card

                                            style={{
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                backgroundColor: 'white',
                                                color: 'black',

                                            }}
                                        >

                                            <div style={{
                                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                            }}>

                                                <div >
                                                    {
                                                        course?.is_course_available === false &&
                                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                    }
                                                    <img alt='course background' src={course?.cover_image_url}
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '125px',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '15px'
                                                        }}
                                                    />

                                                </div>

                                                <Row style={{ marginTop: '-17px', color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <Badge
                                                        style={{
                                                            borderRadius: '7px',
                                                            color: 'black',
                                                            width: 'auto',
                                                            height: '28px',
                                                            fontSize: '10px',
                                                            paddingTop: '8px'
                                                        }}
                                                        color="primary"
                                                    >
                                                        {course?.difficulty}
                                                    </Badge>
                                                    <Badge
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            borderRadius: '7px',
                                                            color: 'black',
                                                            width: 'auto',
                                                            height: '28px',
                                                            backgroundColor: '#CDCFD7',
                                                            fontSize: '10px',
                                                            paddingTop: '8px'
                                                        }}
                                                        color=""
                                                    >

                                                        {course?.course_language}
                                                    </Badge>


                                                </Row>

                                                <Row style={{ color: 'black', marginBottom: '0px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <h6>{course?.name}</h6>

                                                </Row>

                                                <Row className="mt-0" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                    </div>
                                                </Row>

                                                <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <div style={{ display: 'flex' }}>
                                                        <h5 style={{ margin: '0', textAlign: 'right', color: ' #C4A569' }}>{course?.course_progress}% </h5>
                                                        <p className='ml-2' style={{ margin: '0' }}>{t('courses.Complete')}</p>
                                                    </div>

                                                </Row>

                                                {
                                                    course.id == 23 &&
                                                    <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div style={{ display: 'flex', marginBottom: '12px' }}>
                                                            <a href='https://forms.gle/62nGqWwH72TBMFreA' target='_blank'>
                                                                Question
                                                            </a>

                                                        </div>

                                                    </Row>
                                                }

                                                <Row style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'space-evenly' }}>


                                                    <Button
                                                        style={{
                                                            borderRadius: '26px',
                                                            width: 'auto',
                                                            height: '40px',
                                                            boxShadow: '0px 7px 20px #00000014',
                                                            color: 'black',
                                                            fontSize: '20px',
                                                            fontWeight: '500',
                                                            marginBottom: '-30px',
                                                            cursor: 'pointer'
                                                        }}
                                                        color="primary"
                                                        onClick={() => {
                                                            toChapterPage(course)
                                                            ReactGA.event({
                                                                category: 'Courses',
                                                                action: 'Click not available courses ',
                                                                label: 'Click not available courses '
                                                            })
                                                        }}
                                                    >
                                                        {t("courses.View")}

                                                    </Button>

                                            
                                                </Row>

                                            </div>


                                        </Card>
                                       

                                    </Col>
                                )

                            })}


                        </Row>

                        <Row className="courseScrollBar" style={{ overflow: 'auto', display: 'flex' }}>

                            {notAvailableCourses?.map(course => {
                                return (
                                    course?.status &&
                                    <Col xl='3' lg='6' xs='8' sm='12' className="result" style={{ marginBottom: '30px' }}>

                                        <Card
                                            style={{
                                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                                backgroundColor: 'white',
                                                color: 'black',

                                            }}
                                        >

                                            <div style={{

                                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                                            }}>

                                                <div >
                                                    {
                                                        course?.is_course_available === false &&
                                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                                    }
                                                    <img alt='course background' src={course?.cover_image_url}
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '125px',
                                                            borderTopLeftRadius: '15px',
                                                            borderTopRightRadius: '15px'
                                                        }}
                                                    />

                                                </div>

                                                <Row style={{ marginTop: '-17px', color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <Badge
                                                        style={{
                                                            borderRadius: '7px',
                                                            color: 'black',
                                                            width: 'auto',
                                                            height: '28px',
                                                            fontSize: '10px',
                                                            paddingTop: '8px'
                                                        }}
                                                        color="primary"
                                                    >
                                                        {course?.difficulty}
                                                    </Badge>
                                                    <Badge
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            borderRadius: '7px',
                                                            color: 'black',
                                                            width: 'auto',
                                                            height: '28px',
                                                            backgroundColor: '#CDCFD7',
                                                            fontSize: '10px',
                                                            paddingTop: '8px'
                                                        }}
                                                        color=""
                                                    >

                                                        {course?.course_language}
                                                    </Badge>


                                                </Row>

                                                <Row style={{ color: 'black', marginBottom: '0px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <h6>{course?.name}</h6>

                                                </Row>

                                                <Row className="mt-0" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                                    </div>
                                                </Row>

                                                <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <div style={{ display: 'flex' }}>
                                                        <h5 style={{ margin: '0', textAlign: 'right', color: '#EEC256' }}>{course?.course_progress}% </h5>
                                                        <p className='ml-2'>{t('courses.Complete')}</p>
                                                    </div>

                                                </Row>
                                                <Row style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button
                                                        style={{
                                                            borderRadius: '26px',
                                                            width: 'auto',
                                                            height: '40px',
                                                            boxShadow: '0px 7px 20px #00000014',
                                                            color: 'black',
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                            marginBottom: '-20px',
                                                            cursor: 'pointer'
                                                        }}
                                                        color="primary"
                                                        onClick={() => {
                                                            toChapterPage(course)
                                                            ReactGA.event({
                                                                category: 'Courses',
                                                                action: 'Click available courses ',
                                                                label: 'Click available courses '
                                                            })
                                                        }}
                                                    >
                                                        View Now

                                                    </Button>

                                                </Row>

                                            </div>


                                        </Card>


                                    </Col>
                                )

                            })}
                        </Row>
                    </> : " "

                }
            </Container>



        </>



    )
}

export default Courses