import React, { useState, useRef, useEffect } from "react"
import DataTable from "react-data-table-component"
import { get } from "react-hook-form"
import { Container, Row, Col, CardBody, Navbar, NavbarText } from "reactstrap"
import { useParams } from 'react-router-dom'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import PammManagerStatistic from '../../../network/services/pammManagerStatistic'
import { useTranslation } from 'react-i18next'


import _ from 'lodash'


const GrowthTable = (props) => {
    const { t } = useTranslation()
    // const columns = [
    //     {
    //         name: t("pamm.year"),
    //         selector: "year",
    //         sortable: true,
    //         // width:'100px',
    //         cell: row => <span style={{ cursor: 'pointer' }} onClick={props.onClickChangeState}>{[Object.keys(row)]}</span>
    //     },
    //     {
    //         name: t("pamm.JAN"),
    //         selector: "JAN",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('1') ? <div style={{ color: row[Object.keys(row)]['1'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['1'].monthly_growth}%</div> : '-'
    //     },
    //     {
    //         name: t("pamm.FEB"),
    //         selector: "FEB",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('2') ? <div style={{ color: row[Object.keys(row)]['2'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['2'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.MAR"),
    //         selector: "MAR",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('3') ? <div style={{ color: row[Object.keys(row)]['3'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['3'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.APR"),
    //         selector: "APR",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('4') ? <div style={{ color: row[Object.keys(row)]['4'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['4'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.MAY"),
    //         selector: "MAY",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('5') ? <div style={{ color: row[Object.keys(row)]['5'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['5'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.JUN"),
    //         selector: "JUN",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('6') ? <div style={{ color: row[Object.keys(row)]['6'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['6'].monthly_growth}%</div> : '-'
    //     },
    //     {
    //         name: t("pamm.JUL"),
    //         selector: "JUL",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('7') ? <div style={{ color: row[Object.keys(row)]['7'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['7'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.AUG"),
    //         selector: "AUG",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('8') ? <div style={{ color: row[Object.keys(row)]['8'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['8'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.SEP"),
    //         selector: "SEP",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('9') ? <div style={{ color: row[Object.keys(row)]['9'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['9'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.OCT"),
    //         selector: "OCT",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('10') ? <div style={{ color: row[Object.keys(row)]['10'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['10'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.NOV"),
    //         selector: "NOV",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('11') ? <div style={{ color: row[Object.keys(row)]['11'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['11'].monthly_growth}%</div> : '-'
    //     }, {
    //         name: t("pamm.DEC"),
    //         selector: "DEC",
    //         // width:'100px',
    //         cell: row => row[Object.keys(row)].hasOwnProperty('12') ? <div style={{ color: row[Object.keys(row)]['12'].monthly_growth < 0 ? 'red' : 'green' }}>{row[Object.keys(row)]['12'].monthly_growth}%</div> : '-'
    //     },
    //     {
    //         name: t("pamm.TotalGrowth"),
    //         // width:'100px',
    //         cell: (row) => {

    //             const lastKey = _.findLastKey(row[Object.keys(row)])
    //             const lastMonthYearlyGrowth = row[Object.keys(row)][lastKey]?.yearly_growth

    //             return lastMonthYearlyGrowth ?
    //                 <div style={{ color: lastMonthYearlyGrowth < 0 ? 'red' : 'green' }}>{lastMonthYearlyGrowth}%</div>
    //                 : '-'

    //         }
    //     },
    // ]

    const [isBusy, setIsBusy] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [years, setYears] = useState([])
    const wrapperRef = useRef()
    const { id } = useParams()
    const getGrowthYear = []
    const [nav_Graph, setNav_Graph] = useState('')
    const [monthTotalGrowth, setMonthTotalGrowth] = useState('0')

    const getthemonth = (month) => {
        if (month == 1) {
            return t('pamm.January');
        } else if (month == 2) {
            return t('pamm.February');
        } else if (month == 3) {
            return t('pamm.March');
        } else if (month == 4) {
            return t('pamm.April');
        } else if (month == 5) {
            return t('pamm.May');
        } else if (month == 6) {
            return t('pamm.June');
        } else if (month == 7) {
            return t('pamm.July');
        } else if (month == 8) {
            return t('pamm.August');
        } else if (month == 9) {
            return t('pamm.September');
        } else if (month == 10) {
            return t('pamm.October');
        } else if (month == 11) {
            return t('pamm.November');
        } else if (month == 12) {
            return t('pamm.December');
        }
    }


    const changeDisplayData = (year, currentData) => {
        const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const newData = currentData.filter(item => item.year == year)


        //fill the empty month 
        const back_fill_months = (newData) => {

            const last_month_index = months.indexOf(newData[newData.length - 1].month);

            return Array
                .from(
                    { length: Math.max(12 - newData.length, 0) },
                    (v, i) => (last_month_index + i + 1) % 12
                )
                .map((month_index) => ({ year: 0, month: months[month_index], monthly_growth: 0, yearly_growth: 0 }))
                .concat(newData);
        };


        let result = back_fill_months(newData)
        result.sort((a, b) => {
            return a.month - b.month
        })

        const monthTotalGrowth = newData.reduce(
            (a, v) => a = a + v.monthly_growth, 0
        )

        setMonthTotalGrowth(monthTotalGrowth)
        setFilteredData(result)
        setIsLoading(false)

    }

    useEffect(() => {
        const init = async () => {
            setIsLoading(true)
            setIsBusy(true)

            const result = await PammManagerStatistic.get(id)

            result.growth.map(result => {
                getGrowthYear.push(parseInt(Object.keys(result).toString()))
            })

            const keys = Object.keys(result.growth)
            let array = []
            for (const key of keys) {
                array = [...array, ...Object.keys(result.growth[key])]
            }
            setYears(array.reverse())


            if (getGrowthYear.includes(new Date().getFullYear())) {
                setData(result.growth.reverse() ?? [])
            } else {

                const element = { [new Date().getFullYear()]: {} }
                result.growth.push(element)
                setData(result.growth.reverse() ?? [])
            }

            // console.log(result.growth)
            setIsBusy(false)

            let arr = []
            for (const key of keys) {
                arr = [...arr, ...Object.values(Object.values(result.growth[key])[0])]
            }
            setData(arr)

            setNav_Graph(array[0])
            changeDisplayData(array[0], arr)

        }

        init()
    }, [])



    return (
        <div className="product-wrapper" ref={wrapperRef} >
            <div className="product-grid" >
                <div className='mt-2 mb-2' style={{ borderBottom: '3px solid #f7f7f7' }} />
                <h5 style={{ fontSize: '18px' }} >{t("pamm.PerformanceRecord")}</h5>
                {/* <div className="product-wrapper-grid" >

                    <DataTable
                        noHeader
                        data={data}
                        columns={columns}
                        striped={true}
                        center={true}
                        progressPending={isBusy}
                        defaultContent="<i>Not set</i>"
                    />
                </div> */}
                <Col style={{ fontSize: '13px', padding: '0px' }}>
                    <Navbar className="mobile-font-size" expand="md" style={{ justifyContent: 'flex-start', padding: '0px' }}>
                        {years?.map((year) => {
                            return <>

                                <div style={{ cursor: 'pointer' }} onClick={(e) => { setNav_Graph(e.currentTarget.textContent); changeDisplayData(e.currentTarget.textContent, data) }}>
                                    <NavbarText className={`${nav_Graph === year ? 'isActive' : ''} mr-3 ml-3`}>
                                        {year}
                                    </NavbarText>
                                </div>


                            </>
                        })}
                        {/* <div style={{ cursor: 'pointer' }} className={`${nav_Graph === 'Performance'}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Graph === '2021' || nav_Graph === "" ? 'isActive' : ''} mr-3 ml-3`}>
                                2021
                            </NavbarText>
                        </div>


                        <div style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.statistics")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Graph === '2022' ? 'isActive' : ''} mr-3 ml-3`}>
                                2022
                            </NavbarText>
                        </div> */}

                    </Navbar>

                    {/* selected year data*/}
                    <Container style={{ margin: '20px 15px', padding: '0px' }}>
                        <Row style={{ backgroundColor: '#C4A569', width: '100%', height: '90px', padding: '35px 5px', borderRadius: '15px', justifyContent: 'space-between' }}>
                            <Col>
                                <p id="pamm-text">
                                    {t("pamm.TotalGrowth")}
                                </p>

                            </Col>
                            <Col style={{ alignItems: 'end' }}>
                                <h5 style={{ marginBottom: "1rem", textAlign: 'end' }}>{parseFloat(monthTotalGrowth).toFixed(2)}% </h5>
                            </Col>
                        </Row>
                    </Container>
                    {isLoading === true ?
                        <Container>

                            <Row style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                            </Row>
                            <Row style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                            </Row>
                            <Row style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                            </Row>
                            <Row style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                            </Row>
                            <Row style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                            </Row>
                            <Row style={{ flexWrap: 'nowrap', marginBottom: '20px' }}>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                                <Col>
                                    <Skeleton height={57} borderRadius={14} />
                                </Col>
                            </Row>


                        </Container>
                        : ''}
                    <Container style={{ height: '500px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', padding: '0px' }}>

                        {/* {filteredData.map(data => data.month)} */}

                        {filteredData.map(data => {
                            return (
                                <>
                                    <Col className="d-none d-sm-block" style={{ backgroundColor: '#F7F7F7', borderRadius: '14px', height: '57px', width: '48%', marginBottom: '20px' }}>
                                        <Row style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                                            <p style={{ margin: '20px', fontSize: '18px' }}>
                                                {getthemonth(data.month)}
                                            </p>
                                            <p
                                                style={{
                                                    margin: '20px', fontSize: '18px',
                                                    color: data.monthly_growth > 0 ? '#369B70' : data.monthly_growth < 0 ? '#E52E2E' : "black"
                                                }}>

                                                {data.monthly_growth > 0 ? <p style={{ fontSize: '18px' }}>+{data.monthly_growth.toFixed(2)}</p> : data.monthly_growth.toFixed(2)}
                                            </p>
                                        </Row>
                                    </Col>

                                </>

                            )

                        })}


                    </Container>
                    <Container className="d-block d-sm-none" style={{ height: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', padding: '0px', marginTop: '-500px' }}>

                        {/* {filteredData.map(data => data.month)} */}

                        {filteredData.map(data => {
                            return (
                                <>
                                    <Col style={{ backgroundColor: '#F7F7F7', borderRadius: '14px', height: '57px', width: '100%', marginBottom: '20px' }}>
                                        <Row style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                                            <p id="pamm-record-text">
                                                {getthemonth(data.month)}
                                            </p>
                                            <p id="pamm-record-text"
                                                style={{
                                                    color: data.monthly_growth > 0 ? '#369B70' : data.monthly_growth < 0 ? '#E52E2E' : "black"
                                                }}>

                                                {data.monthly_growth > 0 ? <p style={{ fontSize: '18px' }}>+{data.monthly_growth.toFixed(2)}</p> : data.monthly_growth.toFixed(2)}
                                            </p>
                                        </Row>
                                    </Col>
                                </>

                            )

                        })}

                    </Container>


                </Col>


            </div>
        </div>
    )
}

export default GrowthTable