import React, { useState, useEffect } from 'react'
import {
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PPTbackgroundDesktop from './JourneyMapDesktop.png'
import PPTbackgroundMobile from './JourneyMapMobile.png'

import AnnouncementModal from './announcement_modal'

import ModuleService from '../../../network/services/modules'

import './index.css'

const PPTCourse = () => {

    const { t } = useTranslation()
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    const showAnnouncement = useSelector((state) => state.announcement.showAnnouncement)

    const toggleAnnouncementModal = () => {
        setShowModal(false)
        // dispatch({ type: 'SET_ANNOUNCEMENTS_INIT', showAnnouncement: false })
    }

    const [showModal, setShowModal] = useState(true)

    const [isbusy, setIsbusy] = useState(false)
    const [allModules, setAllModules] = useState([])

    const init = async () => {
        setIsbusy(true)
        const moduleResult = await ModuleService.getAll()

        setAllModules(moduleResult?.modules)
        setIsbusy(false)
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {
                <Modal isOpen={showModal} backdrop={true} centered={true}>
                    <ModalHeader
                        style={{
                            backgroundColor: 'rgb(15, 60, 90)',
                            color: 'white',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {t('dashboard.importantAnnouncement')}
                    </ModalHeader>
                    <ModalBody>
                        <AnnouncementModal />
                        <Button
                            style={{ display: 'block', margin: '0 auto' }}
                            color="primary"
                            onClick={toggleAnnouncementModal}
                        >
                            {t('dashboard.noted')}
                        </Button>
                    </ModalBody>
                </Modal>
            }
            <Container >
                <div className="d-xs-none" style={{ position: 'relative' }}>
                    <div>
                        <img className='w-100' style={{ marginTop: '-30px', marginBottom: '30px' }} src={PPTbackgroundDesktop} alt='PPT background desktop' />
                    </div>
                    <a href={`${process.env.PUBLIC_URL}/courses/ppt/journey/module/pre-video`} >
                        <span className={`module0`}>
                            <h2>前言</h2>
                        </span>
                    </a>

                    {allModules?.map((module, index) => (
                        <span key={index} className={`module${index + 1}`} onClick={() => {

                            navigate(`${process.env.PUBLIC_URL}/courses/ppt/journey/module/${module.id}/chapters/lesson`);
                        }}>
                            <h1>{index + 1}</h1>
                        </span>
                    ))}
                </div>
            </Container>

            <Container >
                <div className="d-xs-block" style={{ position: 'relative' }}>
                    <div>
                        <img className='w-100' style={{ marginTop: '40px', marginBottom: '100px' }} src={PPTbackgroundMobile} alt='PPT background desktop' />
                    </div>

                    <a href={`${process.env.PUBLIC_URL}/courses/ppt/journey/module/pre-video`} target='_blank'>
                        <span className={`module0-mobile`}>

                        </span>
                    </a>

                    {allModules?.map((module, index) => (
                        <span key={index} className={`module${index + 1}-mobile`} onClick={() => {

                            navigate(`${process.env.PUBLIC_URL}/courses/ppt/journey/module/${module.id}/chapters/lesson`);
                        }}>
                            {/* <h1>{index + 1}</h1> */}
                        </span>
                    ))}
                </div>
            </Container>
        </>
    );


}

export default PPTCourse