import React, { useState, useEffect } from 'react'
import { Container, Card, CardBody, Form, FormGroup, Input, Label, Button, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'

import SettingService from '../../network/services/setting'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import VEBackground from './veBackground.jpg'


import newLogo from '../../assets/images/logo/logo.png'
import LanguageIcon from '../../language.png'

const SubmitButton = styled.button`
  background-color: #0d1731;
  width: 100%;
  color: white;
  border-radius: 5px;
  padding: 5px;
  border: 0;
`

const ForgotPassword = ({ login }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [logo, setLogo] = useState(null)
  const init = async () => {
    try {
      const result = await SettingService.getAll()
      // console.log(result)
      if (result.settings) {
        let logo2 = result.settings.find((s) => {
          return s.handle === 'crm_logo_url'
        })

        setLogo(logo2 ?? {})
      } else {
        setLogo({})
      }
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  const { register, handleSubmit, watch, errors, clearErrors, setError } = useForm()
  const watchPassword = watch('password')
  const { i18n } = useTranslation()

  const [email, setEmail] = useState('')
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  const [languageSelected, setLanguageSelected] = useState('en')

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const languageToggle = () => setLanguageDropdownOpen((prevState) => !prevState)

  const submit = async (data) => {
    try {
      const result = await AuthService.forgotPassword({
        email: email,
        auth_code: data.auth_code,
        password: data.password,
        password_confirmation: data.confirm_password
      })
      // console.log(result)

      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: t("forgotPassword.passwordResetSuccess"),
        icon: 'success'
      }).then(() => {
        history.push('/auth')
      })
    } catch (error) {
      console.log(error)

      //  SweetAlert.fire({
      // showClass: {
      //   popup: 'animate__animated animate__backInUp'
      // },
      // hideClass: {
      //   popup: 'animate__animated animate__backOutDown'
      // },
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  const verifyEmail = async () => {
    if (email == null || email === '') {
      setError('email', {
        type: 'manual',
        message: t("forgotPassword.emailIsRequired")
      })
      return
    }

    try {
      const result = await UserService.verifyEmail({
        email: email
      })

      if (!result.success) {
        const codeResult = await AuthService.sendVerification({
          email: email
        })

        if (codeResult.success) {
          setIsEmailVerified(true)
          clearErrors('email')
        } else {
          setError('email', {
            type: 'manual',
            message: codeResult.message ?? t("forgotPassword.pleseTryAgain")
          })
          // throw codeResult
        }
      } else {
        // console.log('?')
        setError('email', {
          type: 'manual',
          message: t("forgotPassword.emailIsNotValid")
        })
        // throw result
      }
    } catch (error) {
      console.log(error)

      //  SweetAlert.fire({
      // showClass: {
      //   popup: 'animate__animated animate__backInUp'
      // },
      // hideClass: {
      //   popup: 'animate__animated animate__backOutDown'
      // },
      //   title: error.message,
      //   icon: 'error'
      // })
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }
  return (

    <>



      <div className="mb-3" style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '3' }}>
        {logo?.value && (
          <Link style={{ margin: '0 auto', marginBottom: '30px' }} to={`${process.env.PUBLIC_URL}/auth`}>
            <img
              src={logo.value}
              // src={newLogo}
              alt="logo"
              style={{
                maxHeight: 175,
                maxWidth: 175,
              }}
            />
          </Link>
        )}
      </div>

      {/*circle*/}
      {/* <div style={{ position: 'fixed', top: '-85px', right: '-85px', width: '266px', height: '266px', backgroundColor: '#F0F0F0', borderRadius: '50%' }}>
        </div> */}



      <Row style={{ justifyContent: 'center', zIndex: '4', height: '100vh' }}>


        <Form
          onSubmit={handleSubmit(submit)}
          id="login-form"
          className="theme-form cont"
          style={{ margin: 'auto', minHeight: '310px', borderRadius: '0px 0px 50px 0px', border: 'none' }}
        >
          <h4>Recover your password</h4>
          {/* <p style={{color:'#898989'}}>Please enter your email</p> */}

          <Label className="col-form-label pt-1 pb-0">{t("auth.emailAddress")}</Label>
          <FormGroup>
            <Input
              id="input-form"
              style={{ backgroundColor: "#f8f8f8" }}
              placeholder={t("forgotPassword.emailAddress")}
              className="form-control"
              type="text"
              required=""
              name="email"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              disabled={isEmailVerified ? true : false}
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
          </FormGroup>
          {/* {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>} */}

          {isEmailVerified ? (
            <>
              <FormGroup>
                <Label className="col-form-label pt-0">Verification Code</Label>
                <Input
                  id="input-form"
                  style={{ backgroundColor: "#f8f8f8" }}
                  className="form-control"
                  type="text"
                  placeholder={t("forgotPassword.verificationCode")}
                  name="auth_code"
                  innerRef={register({ required: t("forgotPassword.verificationCodeIsRequired") })}
                />
                <span
                  style={{ color: 'green' }}
                >
                  {t("forgotPassword.pleaseCheckEmailForVerification", { email: email })}
                  {/* {`Please check ${email} for verification code`} */}
                </span>
                <br />
                <span style={{ color: 'red' }}>
                  {errors.auth_code && errors.auth_code.message}
                </span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">New Password</Label>
                <Input
                  id="input-form"
                  style={{ backgroundColor: "#f8f8f8" }}
                  className="form-control"
                  type="password"
                  placeholder={t("forgotPassword.password")}
                  name="password"
                  innerRef={register({
                    required: t("forgotPassword.passwordIsRequired"),
                    minLength: {
                      value: 8,
                      message: t("forgotPassword.passwordMinumumLength")
                    }
                  })}
                />
                <span style={{ color: 'red' }}>
                  {errors.password && errors.password.message}
                </span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">Comfirm New Password</Label>
                <Input
                  id="input-form"
                  style={{ backgroundColor: "#f8f8f8" }}
                  className="form-control"
                  type="password"
                  placeholder={t("forgotPassword.confirmPassword")}
                  name="confirm_password"
                  innerRef={register({
                    required: t("forgotPassword.confirmPasswordIsRequired"),
                    minLength: 8,
                    validate: (value) => {
                      return value === watchPassword || t("forgotPassword.passwordNotMatch")
                    }
                  })}
                />
                <span style={{ color: 'red' }}>
                  {errors.confirm_password && errors.confirm_password.message}
                </span>
              </FormGroup>
              <FormGroup className="mt-3 mb-3">
                <Button color="primary btn-block" type="submit">
                  {t("forgotPassword.submit")}
                </Button>
              </FormGroup>
            </>
          ) : (
              <FormGroup className="mt-3 mb-3">
                <Button color="primary btn-block" onClick={() => verifyEmail()}>
                  {t("forgotPassword.submit")}
                </Button>
              </FormGroup>
            )}
          <div className="mt-3" />
          <div className='justify-content-end' style={{ display: 'flex' }}>
            <p style={{ color: '#898989' }}>{t("forgotPassword.alreadyHaveAnAccount")} </p>
            <Link to={`${process.env.PUBLIC_URL}/auth`} href="#javascript">
              <p className="ml-1" style={{ color: '#C4A569' }}>{t("forgotPassword.login")}</p>
            </Link>
          </div>
        </Form>

        <div
          className='d-none d-lg-block'
          width='40vw'
          height='100vh'

          style={{
            backgroundColor: 'white',
            backgroundSize: 'cover',
            backgroundImage: `url('${VEBackground}')`,
            position: 'relative'
          }}>

          {/* <p style={{ zIndex: '3', color: 'white', fontSize: '28px', margin: '50px' }}>
              </p> */}

          <div className="d-flex justify-content-start align-items-start " style={{ color: 'white', textAlign: 'left', width: '40vw', height: '100vh' }}>
            <div style={{ margin: '75px' }}>
              <h1 className='m-0'>Learn with</h1>
              <h1 className='m-0'>Value Envision</h1>
              <h2 className='m-0'>用心教育，正規交易</h2>
            </div>
          </div>

        </div>
      </Row>


      {/* </Container> */}

    </>


  )
}

export default ForgotPassword
