import React from "react"
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap"
import { useSelector } from "react-redux"
import styled from "styled-components"

import Breadcrumb from "../../layout/breadcrumb"
import MonitoringOpenTable from "./table/open"
import MonitoringClosedTable from "./table/closed"
import ColorDropdown from "./dropdown"
import SwitchButton from "./switch"
import MonitoringGraphical from "./graphical"
import { useTranslation } from 'react-i18next'
import '../newDashboard/dashboard.css'

const Gap = styled.div`
  padding: 10px;
`

const MobileGap = styled(Gap)`
  @media (min-width: 577px) {
    padding: 0;
  }
`

const Monitoring = (props) => {
  const { t } = useTranslation()
  const isGraphical = useSelector((state) => state.monitoring.isGraphical)
  const selectedAccount = useSelector((state) => state.monitoring.account)

  return (
    <>
      <Breadcrumb />
      <Container className="mobile-monitoring">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{t("monitoring.liveMonitoring")}</h5>
                <div className="p-10"></div>
                <Row>
                  <Col lg={6}>
                    <ColorDropdown />
                    <MobileGap />
                  </Col>
                  {/* <Col className="text-right" lg={6}>
                    <SwitchButton options={[t("monitoring.graphical"), t("monitoring.transaction")]} />
                  </Col> */}
                </Row>
              </CardHeader>
              {selectedAccount ? (
                <CardBody>
                  {isGraphical ? (
                    <>
                      <MonitoringGraphical />
                      <Gap />
                      <MonitoringOpenTable />
                      <Gap />
                      <MonitoringClosedTable />
                    </>
                  ) : (
                      <>


                      </>
                    )}
                </CardBody>
              ) : (
                  <CardBody>{t("monitoring.noAccountSelected")}</CardBody>
                )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Monitoring
