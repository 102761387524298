import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { translate } from 'react-switch-lang'

import Breadcrumb from '../../layout/breadcrumb'
import LanguageIcon from '../../language.png'

import NotificationService from '../../network/services/notification'
import { Col, Row, Card, CardBody, Container, Spinner, Input, Form, FormGroup, Label } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'


import { useTranslation } from 'react-i18next'
import moment from 'moment'

import './language.css'

const Language = () => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(()=>{
        sessionStorage.getItem('language')
    },[])
    
    const CurrentLanguage = sessionStorage.getItem('language')

    const multiLanguage = [
        {
            languageName: 'English',
            languageNow:'en'
        },
        {
            languageName: '中文（简体）',
            languageNow:'zh'
        }
    ]

    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
        sessionStorage.setItem('language', language)
    }

    // const getLanguage = (language) => {
    //     if (language == 'en') {
    //         return t('language.CurrentLanguage', { languageNow: '(English)' })
    //     } else if (language == 'zh') {
    //         return t('language.CurrentLanguage', { languageNow: '(中文简体)' })

    //     }
    // }


    return (
        <Container>
            <Breadcrumb
                title={t('language.Language')}
            />




            <Row style={{ marginTop: '15px', justifyContent: 'center', marginLeft: '0', marginRight: '0' }}>
                <Col lg='6'>
                    <Card style={{
                        padding: '15px 20px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        borderRadius: '20px',
                        backgroundColor: 'white',
                        height: 'auto'
                    }}>
                        <Row style={{ padding: '0px 15px', justifyContent: 'space-between', alignItems: 'center', color: 'black' }}>
                            <h5 style={{ fontWeight: 'normal' }}>{t('language.Language')}</h5>
                            {/* <h5 style={{ fontWeight: 'normal' }}>{getLanguage(CurrentLanguage)}</h5> */}
                        </Row>

                        <div
                            style={{
                                overflow: 'none',
                                padding: '20px',
                            }}
                            id="transactionsDiv"
                        >

                            <Container style={{ margin: '10px' }}>

                                <Row className='language-box'>
                                    {/* <h5 className='p-1'>English / 中文简体</h5>
                                    <label class="language-switch">
                                        <input type="checkbox" onChange={onChangeHandler} value={Language} />
                                        <span className='language-slider'></span>
                                    </label> */}
                                    {multiLanguage.map((language) => (
                                        <Row>
                                            <Col lg='6'>
                                                <h5 className='p-1'>{language.languageName}</h5>
                                            </Col>
                                            <Col lg='6' style={{textAlign:'end'}}>
                                                <label class="language-switch">
                                                    <input type="checkbox" onChange={() => changeLanguage(language.languageNow)} value={language} />
                                                    <span className={`${CurrentLanguage === language.languageNow ? 'language-slider' : 'language-slider-inactive'}`}></span>
                                                    {/* <span className='language-slider'></span> */}
                                                </label>
                                            </Col>
                                        </Row>
                                    ))}
                                </Row>

                                {/* <Form>
                                    <FormGroup onClick={() => changeLanguage('en')} style={{ paddingLeft: '0' }}>
                                        <Label check>
                                            <Input type="radio" id="LanguageRadio" checked={language == 'en'} style={{ height: '15px', width: '15px' }} />
                                            <span>English</span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check onClick={() => changeLanguage('zh')} style={{ paddingLeft: '0' }}>
                                        <Label check style={{ marginTop: '-5px' }}>
                                            <Input type="radio" id="LanguageRadio" checked={language == 'zh'} style={{ height: '15px', width: '15px' }} />
                                            <span>Chinese</span>
                                        </Label>
                                    </FormGroup>
                                </Form> */}
                            </Container>




                            {/* for bahasa malaysia  */}
                            {/* <Row>
                                                        <Col>
                                                            <div style={{marginLeft:''}}>
                                                                Bahasa Malaysia
                                                            </div>

                                                        </Col>
                                                    </Row> */}

                        </div>


                    </Card>
                </Col>




            </Row>

        </Container>
    )
}

export default translate(Language)
