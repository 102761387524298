import React from "react";
import { Card, CardBody } from "reactstrap";
import ChangePasswordForm from "./form";

const ChangePassword = () => {
  return (
    <Card style={{boxShadow:'0px 7px 20px #0000000D', backgroundColor:'#f7f7f7'}}>
      <CardBody>
        <ChangePasswordForm />
      </CardBody>
    </Card>
  );
};

export default ChangePassword;
