import React, { useState, useEffect } from 'react'
import {
    Row,
    Navbar,
    NavbarText,
    Container,
    Col,
    Card,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap'

import Breadcrumb from '../../../layout/breadcrumb'

import PammSubscriptionService from '../../../network/services/pammSubscription'

// import PammPendingTable from './pending/index'
import ActiveList from './active/index'
import DeactiveList from './deactive/index'
import { useTranslation } from 'react-i18next'
import './PammSubscription.css'

const PammSubscription = (props) => {
    const { t } = useTranslation()
    const [activeSubscribe, setActiveSubscribe] = useState([])
    const [deactiveSubscribe, setDeactiveSubscribe] = useState([])
    const [pendingSubscribe, setPendingSubscribe] = useState([])

    const [activeTab, setActiveTab] = useState('subscribed')

    const tabs = {
        // beneficial: 'Beneficial become IB',
        subscribed: t("pammSubsccription.subscribed"),
        unsubscribed: t("pammSubsccription.unsubscribed"),
    }

    // const promotions = useSelector((state) => state.promotion.promotions)

    const [navText, setNavText] = useState('Active')


    const init = async () => {
        try {
            const result = await PammSubscriptionService.getAll()

            setActiveSubscribe(result.active ?? {})
            setDeactiveSubscribe(result.deactive ?? {})
            setPendingSubscribe(result.pending ?? {})

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            {/* <Breadcrumb parent={t("pammSubsccription.PAMMSubscriptions")} title={t("pammSubsccription.PAMMSubscriptions")}></Breadcrumb> */}

            <Container className="mb-5">
                <Col lg='12' className="d-none d-md-block d-lg-block d-xl-block" style={{ padding: '0px' }}>
                    <Card style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                        <Row>
                            <Col>
                                <Nav tabs className="border-tab-refer" style={{ marginBottom: '0px', border: 'none' }}>

                                    <NavItem style={{ cursor: 'pointer' }}>
                                        <NavLink
                                            className={activeTab === 'subscribed' ? 'active' : ''}
                                            onClick={() => setActiveTab('subscribed')}
                                        >
                                            {/* <i className="fa fa-building"></i> */}
                                            {tabs.subscribed}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{ cursor: 'pointer' }}>
                                        <NavLink
                                            className={activeTab === 'unsubscribed' ? 'active' : ''}
                                            onClick={() => setActiveTab('unsubscribed')}
                                        >
                                            {/* <i className="fa fa-users"></i> */}
                                            {tabs.unsubscribed}
                                        </NavLink>
                                    </NavItem>


                                </Nav>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Row style={{marginBottom:'80px'}}>
                    <Col sm="12" lg="4" xl="3" className="project-list d-md-none d-lg-none d-xl-none">

                            <Row style={{justifyContent:'center'}}>
                                
                                    <Nav tabs className="partner-border-mobile">

                                        <NavItem>
                                            <NavLink
                                                className={activeTab === 'subscribed' ? 'active' : ''}
                                                onClick={() => setActiveTab('subscribed')}
                                            >
                                                {/* <i className="fa fa-building"></i> */}
                                                {tabs.subscribed}
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                className={activeTab === 'unsubscribed' ? 'active' : ''}
                                                onClick={() => setActiveTab('unsubscribed')}
                                            >
                                                {/* <i className="fa fa-users"></i> */}
                                                {tabs.unsubscribed}
                                            </NavLink>
                                        </NavItem>


                                    </Nav>
                                
                            </Row>
                        
                    </Col>

                    <Col sm="12" lg="12" xl="12">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="subscribed" >

                                <ActiveList />


                            </TabPane>
                            <TabPane tabId="unsubscribed">

                                <DeactiveList />

                            </TabPane>
                        </TabContent>
                    </Col>

                </Row>
            </Container>

            {/* <Row className="mt-3" style={{ margin: '0', marginLeft: '15px', marginBottom: '30px' }}>
                <Navbar color="light" light expand="md" style={{ padding: '0', justifyContent: 'flex-start', fontWeight: 'bold' }}>

                    <div style={{ cursor: 'pointer', fontSize: '12px' }} className={`${navText === "Active" || navText === "" ? 'isActive' : ''} pl-2 pr-2`} onClick={() => setNavText("Active")}>
                        <NavbarText className={`${navText === "Active" || navText === "" ? 'isActive' : ''} mr-1 ml-1`}>
                            {t("pammSubsccription.subscribed")}
                        </NavbarText>
                    </div>

                    <div style={{ cursor: 'pointer', fontSize: '12px' }} className={`${navText === "Deactive" ? 'isActive' : ''} pl-2 pr-3`} onClick={() => setNavText("Deactive")}>
                        <NavbarText
                            className={`${navText === "Deactive" ? 'isActive' : ''} mr-1 ml-1`}
                        >
                            {t("pammSubsccription.unsubscribed")}
                        </NavbarText>
                    </div>



                </Navbar>
            </Row> */}
            {/* {navText === "Active" ? <ActiveList /> : <DeactiveList />} */}
            {/* <div className="mt-3">
                <PammPendingTable />
            </div> */}
        </>
    )
}

export default PammSubscription