import React from "react";
import PammRejectedTable from "./table";

const Rejected = () => {
  return (
    <PammRejectedTable />
  );
};

export default Rejected;
