import React, { Fragment } from "react"
import { Row, Col } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import LeftHeader from "./leftbar"
import RightHeader from "./rightbar"
import Logo from "../../components/header/logo";
import DarkMode from '../../components/header/dark_mode'
import { Box } from "react-feather"

import headphone from './headphones.svg'

const Header = () => {

  let history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <Fragment>
      <div className="page-main-header" style={{ zIndex: '16', flexWrap: 'nowrap' }}>
        <Row className="main-header-right">
          {/* <LeftHeader /> */}
          <Col lg='3' className="d-none d-lg-block d-xl-none d-xl-block" style={{ paddingLeft: '40px' }}>
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img
                style={{ float: 'left', width: '250px' }}
                className="img-fluid for-light"
                src={require('../../assets/images/logo/ve-logo.png')}
                alt=""
              />
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img
                style={{ marginRight: '10px', float: 'right', width: '250px' }}
                className="img-fluid for-dark"
                src={require('../../assets/images/logo/ve-logo.png')}
                alt=""
              />
            </Link>
          </Col>
          <Col lg='9' className="img-fluid nav-right pull-right right-menu" >
            <Col lg='11' style={{ margin: '25px 0px' }}>
              <RightHeader />
            </Col>
            <Col lg='1' className="d-none d-lg-block d-xl-none d-xl-block" style={{ backgroundColor: '#C4A569', float: 'right', height: '100px', marginTop: '-100px', borderRadius: '0 0 0 30px', textAlign: 'center' }}>

            <a href="https://wa.me/601120682128?text=Hi%2C%20I%20need%20assist%20on%20VE%20Connect" target="_blank">
                <img src={headphone} height='40px' width='40px' style={{ marginTop: '25px', cursor: 'pointer' }}

                ></img>
              </a>
            </Col>
          </Col>

        </Row>
      </div>
    </Fragment>
  )
}

export default Header
