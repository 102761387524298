import React, { useState, useEffect } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Tooltip,
  Button,
  Col,
  Row,
  Modal,
  Spinner,
  ModalBody,
  Form,
  Input
} from 'reactstrap'
import { useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import PromotionBannerService from '../../network/services/promotion'
import AccountService from '../../network/services/account'
import styled from 'styled-components'

import SweetAlert from 'sweetalert2'

import { HelpCircle } from 'react-feather'
import moment from 'moment'

const ClaimButton = styled.button`
  background-color: #fdf5bb;
  color: #be132d;
  border-color: #fdf5bb;
  border-radius: 0.25rem;
  font-size: 18px;
  font-weight: 500;
`

const Example = () => {
  const user = useSelector((state) => state.user.user)
  const [promotionBanners, setPromotionBanners] = useState([])
  const [bannerModalOpen, setBannerModalOpen] = useState(false)
  const [promotionDetails, setPromotionDetails] = useState('')
  const [selectedAccount, setSelectedAccount] = useState(false)
  const [isCollapse, setIsCollapse] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toolTiptoggle = () => setTooltipOpen(!tooltipOpen)

  const { register, handleSubmit, errors, reset } = useForm()
  const dispatch = useDispatch()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const [qualifyAccounts, setQualifyAccounts] = useState()

  const toggleBannerModal = async (promotionID) => {
    setBannerModalOpen(!bannerModalOpen)
    const getPromotionsDetails = await PromotionBannerService.getPromotions(promotionID)
    setPromotionDetails(getPromotionsDetails?.promotion)
    // console.log('getPromotionsDetails', getPromotionsDetails)
    setIsBusy(false)
    const accountResponse = await AccountService.getAll()
    setQualifyAccounts(
      accountResponse?.accounts?.filter((acc) => {
        return (acc?.account_type === 0 || acc?.account_type === 2) && (acc?.package_id !== 7 && acc?.package_id !== 9)
      })
    )
    setIsBusy(true)
  }

  const init = async () => {
    const promotionsResponse = await PromotionBannerService.getPromotionBanner()
    // console.log('promotionsResponse', promotionsResponse)
    // setTransactions([])
    setPromotionBanners(promotionsResponse.dashboard_carousel_banners)
  }

  //   const navigate = (path) => {
  //     history.push(path)
  //     dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  //   }

  useEffect(() => {
    init()
  }, [])

  const handleClaim = async (data) => {
    // console.log('data', data)
    // console.log('promotionDetails', promotionDetails)
    SweetAlert.fire({
      showClass: {
        popup: 'animate__animated animate__backInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__backOutDown'
      },
      title: 'Are You Sure You Want To Claim',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Claim',
      cancelButtonText: t('financial.cancel'),
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        try {
          let result = await PromotionBannerService.claimPromotion(promotionDetails?.id, data)

          if (result) {
            SweetAlert.fire({
              showClass: {
                popup: 'animate__animated animate__backInUp'
              },
              hideClass: {
                popup: 'animate__animated animate__backOutDown'
              },
              title: 'Successfully Claimed',
              html: '<h6>Kindly Check Your Email To Proceed Next Step</h6>',
              // icon: 'success',
              confirmButtonText: 'Check My $88 Credit Bonus',
              customClass: 'ClaimButtonAlert'
            }).then(() => {
              window.location.href = '/tradeAccount'
            })
          }
        } catch (error) {
          //  SweetAlert.fire({
          // showClass: {
          //   popup: 'animate__animated animate__backInUp'
          // },
          // hideClass: {
          //   popup: 'animate__animated animate__backOutDown'
          // },
          //   title: error.message,
          //   icon: 'error'
          // })
          const errorCode = error.code
          const errorTranslate = error.translate_params
          SweetAlert.fire({
            showClass: {
              popup: 'animate__animated animate__backInUp'
            },
            hideClass: {
              popup: 'animate__animated animate__backOutDown'
            },
            title: t(errorCode, errorTranslate),
            icon: 'error'
          })
        }
      }
    })
  }

  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const [tncModal, setTncModal] = useState(false)

  const tncToggle = () => setTncModal(!tncModal)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === promotionBanners.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? promotionBanners.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }



  const checkFulfillRequirement = () => {
    if (promotionDetails?.my_deposit_progress <= promotionDetails?.min_deposit) {
      return promotionDetails?.my_deposit_progress
    } else if (promotionDetails?.my_mt5_deposit_progress <= promotionDetails?.min_deposit) {
      return promotionDetails?.my_mt5_deposit_progress
    } else {
      return promotionDetails?.my_deposit_progress
    }
  }
  const AutomationSlides = promotionBanners.map((item) => {
    if (item.status === 1) {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.src}
        >
          <a href={`${item?.proceed_url == "https://app.senangpay.my/payment/171301547965" ? "https://app.senangpay.my/payment/171301547965" : "https://app.senangpay.my/payment/171301547965"}`} target="_blank" rel="noopener noreferrer">
            <img
              className="d-block"
              style={{ borderRadius: '20px', backgroundColor: '#8a8a8a33', maxWidth: '100%', width: '100%' }}
              src={
                sessionStorage.getItem('language') == 'en'
                  ? item.banner_english
                  : item.banner_chinese
              }
              alt={item?.description}
            />
          </a>
          <Row>
            {item?.proceed_url && item?.banner_action !== 1 ? (
              <Col style={{ padding: '0' }}>
                <a
                  href={`${item?.proceed_url == "https://app.senangpay.my/payment/171301547965" ? "https://app.senangpay.my/payment/171301547965" : "https://app.senangpay.my/payment/171301547965"}`}
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                
                </a>
              </Col>
            ) : (
              <Col style={{ padding: '0' }}>
                <Button
                  onClick={() => {
                    toggleBannerModal(item?.promotion_id)
                  }}
                  color="primary"
                  style={{ borderRadius: '0', width: '100%', color: 'white' }}
                >
                  {t('dashboard.claimNow')}
                </Button>

                <Modal
                  isOpen={bannerModalOpen}
                  toggle={() => {
                    toggleBannerModal()
                  }}
                  centered
                  id="CnyModal"
                  style={{
                    backgroundImage:
                      'url("https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/Claim+pop+up-01.png")',
                    backgroundSize: 'cover',
                  }}
                >
                  {/* <ModalHeader toggle={() => { toggleBannerModal() }}>Modal title</ModalHeader> */}
                  <ModalBody
                    style={{
                      padding: '0'
                    }}
                    id="CnyModalBody"
                  >
                    <div>
                      <Row
                        style={{
                          padding: '0 1rem',
                          justifyContent: 'flex-end',
                          marginLeft: '0',
                          marginRight: '0',
                          marginTop: '5px'
                        }}
                      >
                        <div style={{ color: '#BE132D' }}>
                          {promotionDetails?.current_claim_count} {t('dashboard.peopleHadClaimed')}
                        </div>
                      </Row>

                      <Row style={{ justifyContent: 'center', margin: '20px 0px 0px 20px' }}>
                        <div style={{ color: '#BE132D', textAlign: 'center' }}>
                          <h1>
                            ${checkFulfillRequirement()} / ${promotionDetails?.min_deposit}
                          </h1>
                        </div>
                      </Row>

                      <Row style={{ justifyContent: 'center', margin: '0px 0px 0px 20px' }}>
                        <div style={{ color: '#BE132D', textAlign: 'center' }}>
                          <Form className="theme-form" onSubmit={handleSubmit(handleClaim)}>
                            {promotionDetails?.my_deposit_progress >=
                              promotionDetails?.min_deposit &&
                              promotionDetails?.my_mt5_deposit_progress >=
                              promotionDetails?.min_deposit &&
                              user?.country === 'Malaysia' ? (
                              <>
                                {/* <h4 style={{ color: "#BE132D", fontWeight: 400 }} >Select Account To Claim</h4> */}

                                {isBusy ? (
                                  promotionDetails?.is_promotion_available ? (
                                    <>
                                      <Input
                                        type="select"
                                        name="account_id"
                                        className="form-control digits"
                                        innerRef={register({ required: true })}
                                        style={{
                                          border: '1.5px solid #868686',
                                          marginBottom: '5px'
                                        }}
                                        required
                                        onChange={(e) => {
                                          setSelectedAccount(e.target.value)
                                        }}
                                      >
                                        <option value="">{t('dashboard.selectMT5ToClaim')}</option>

                                        {qualifyAccounts?.length > 0 &&
                                          qualifyAccounts.map((account, index) => {
                                            return (
                                              <option key={index} value={account.id}>
                                                {account.account_login} {account?.plan?.name} -{' '}
                                                {formatter.format(account?.balance)}
                                              </option>
                                            )
                                          })}
                                      </Input>
                                      <div style={{ marginTop: '10px' }}>
                                        <ClaimButton
                                          disabled={promotionDetails?.current_claim_count > 100}
                                          style={{ width: '100%' }}
                                        >
                                          {promotionDetails?.current_claim_count <= 100
                                            ? t('dashboard.claim')
                                            : t('dashboard.fullyRedeemed')}
                                        </ClaimButton>
                                      </div>
                                    </>
                                  ) : (
                                    <h6>{t('dashboard.youAlreadyClaim')}</h6>
                                  )
                                ) : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Spinner />
                                  </div>
                                )}
                              </>
                            ) : (
                              <h6>{t('dashboard.haveNotFulfil')}</h6>
                            )}
                          </Form>
                        </div>
                      </Row>

                      {/* <Row style={{ padding: "0 3rem", justifyContent: 'center', margin: '20px 0px 0px 20px' }}>
                        <div >
                          <h5>Terms And Conditions</h5>
                          <p>1. Only Available For Specific Country</p>
                          <p>2. Deposit $188 To Wallet</p>
                          <p>3. Deposit $188 To MT5 Account</p>
                        </div>
                      </Row> */}

                      <Row style={{ justifyContent: 'center', margin: '1z0px 0px 0px 20px' }}>
                        <div>
                          <a href={item?.proceed_url} style={{ textDecoration: 'underline', color: '#BE132D' }}>
                            {t('dashboard.moreDetails')}
                          </a>
                        </div>
                      </Row>

                      <Row style={{ justifyContent: 'flex-end', margin: '20px 20px 0px 0px' }}>
                        <div>
                          <p style={{ color: '#BE132D', marginBottom: '0px' }}>
                            From {moment(promotionDetails.start_date).format('yyyy-MM-DD')} To  {moment(promotionDetails.end_date).format('yyyy-MM-DD')} Only
                          </p>
                        </div>
                      </Row>


                      <div
                        style={{
                          backgroundColor: '#BA0201',
                          color: 'white',
                          marginTop: '5px',
                          paddingTop: '20px',
                          paddingBottom: '20px',
                          boxShadow: '0 -4px 6px -4px #BE132D'
                        }}
                      >
                        <div>
                          <h5 style={{ textAlign: 'center' }}>
                            {t('dashboard.TermsAndConditions')}
                          </h5>
                        </div>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            padding: '10px 40px',
                            margin: '0',
                            backgroundColor: '#BA0201',
                            color: 'white',
                            alignItems: 'center'
                          }}
                        >
                          <div>
                            {t('dashboard.onlyAvailableForSelectedCountry')}
                            <span style={{ color: 'white' }} id="CountryToolTip">
                              <HelpCircle width="16px" />
                            </span>
                            <Tooltip
                              id="CountryToolTip"
                              placement="right"
                              isOpen={tooltipOpen}
                              target="CountryToolTip"
                              toggle={toolTiptoggle}
                            >
                              {t('dashboard.dueToDelivery')}
                            </Tooltip>
                          </div>
                          <div style={{ marginTop: '6px' }}>
                            {user?.country === 'Malaysia' ? (
                              <i
                                className="fa fa-check-circle"
                                style={{ color: 'white', fontSize: 20, marginRight: 4 }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-times-circle"
                                style={{ color: 'white', fontSize: 20, marginRight: 4 }}
                              ></i>
                            )}
                          </div>
                        </Row>

                        <Row
                          style={{
                            justifyContent: 'space-between',
                            padding: '10px 40px',
                            margin: '0',
                            backgroundColor: '#BA0201',
                            color: 'white'
                          }}
                        >
                          <div>{t('dashboard.deposit188ToWallet')}</div>
                          {promotionDetails?.my_deposit_progress >=
                            promotionDetails?.min_deposit ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: 'white', fontSize: 20, marginRight: 4 }}
                            ></i>
                          ) : (
                            <div>
                              ${promotionDetails?.my_deposit_progress} / $
                              {promotionDetails?.min_deposit}
                            </div>
                          )}
                        </Row>

                        <Row
                          style={{
                            justifyContent: 'space-between',
                            padding: '10px 40px',
                            margin: '0',
                            backgroundColor: '#BA0201',
                            color: 'white'
                          }}
                        >
                          <div>{t('dashboard.deposit188ToMT5')}</div>

                          {promotionDetails?.my_mt5_deposit_progress >=
                            promotionDetails?.min_deposit ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: 'white', fontSize: 20, marginRight: 4 }}
                            ></i>
                          ) : (
                            <div>
                              ${promotionDetails?.my_mt5_deposit_progress} / $
                              {promotionDetails?.min_deposit}
                            </div>
                          )}
                        </Row>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </Col>
            )}

            {item?.tnc_english && (
              <Col style={{ padding: '0' }}>
                <a
                  href={
                    sessionStorage.getItem('language') == 'en' ? item.tnc_english : item.tnc_chinese
                  }
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <Button color="secondary" style={{ borderRadius: '0', width: '100%' }}>
                    {item?.banner_action !== 1
                      ? t('dashboard.TermsAndConditions')
                      : t('competition.ViewDetails')}
                  </Button> */}
                </a>
              </Col>
            )}
          </Row>
        </CarouselItem>
      )
    }
  })

  return (
    <div
      style={{
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        padding: '0px',
        marginBottom: '25px',
        borderRadius: '25px'
      }}
    >
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={promotionBanners} activeIndex={activeIndex} onClickHandler={goToIndex} />

        {AutomationSlides}
        <CarouselControl
          direction="next"
          directionText={t('dashboard.next')}
          onClickHandler={next}
        />
        <CarouselControl
          direction="prev"
          directionText={t('dashboard.previous')}
          onClickHandler={previous}
        />
      </Carousel>
    </div>



  )
}

export default Example
