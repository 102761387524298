import client from '../request'

const get = (data) => {
    const startDate = data.startDate
    const endDate = data.endDate
    return client.get(`/get_weekly_checkin_and_total_reward?start_of_week=${startDate}&end_of_week=${endDate}`)
} 

const checkIn = (data) => {
    return client.post(`/check_in`,data)
}

const history = (props) => {
    let page = props?.page ?? 1
    return client.get(`/get_all_checked_in_record?page=${page}`)

}

const missionList = () =>{
    return client.get(`/get_mission_status`)
}

const redeemPrize = (id) => {
    return client.put(`redeem_reward/${id}`)
}

const getPrize = () => {
    return client.get(`get_all_prize`)
} 

const claimPrize = (data) => {
    return client.post(`exchange_prize`, data )
}

const PrizeHistories = () => {
    return client.get(`get_exchange_histories`)
}


export default {
    get,
    checkIn,
    history,
    missionList,
    getPrize,
    redeemPrize,
    claimPrize,
    PrizeHistories

}