import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
    Row,
    Col,
    Card,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap'

import PammPendingTable from './pending/index'
import PammRejectedTable from './rejected/index'

import PammSubscriptionService from '../../../../network/services/pammSubscription'
import Unsubscribe from './unsubscribe_modal'

import './active.css'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

const PammActiveSubscription = (props) => {
    const { t } = useTranslation()
    const [activeSubscribe, setActiveSubscribe] = useState([])
    const activeList = activeSubscribe

    const [pending, setPending] = useState([])
    const [RejectedResult, setRejectedResult] = useState([])
    const [selectedSubcribe, setSelectedSubcribe] = useState([])
    const [isBusy, setIsBusy] = useState(false)
    const wrapperRef = useRef()

    // modal for unsubscribe
    const [showUnsubscribe, setShowUnsubscribe] = useState(false)
    const toggleModal = () => {
        setShowUnsubscribe(!showUnsubscribe)
    }

    const init = async () => {
        try {
            setIsBusy(true)
            const result = await PammSubscriptionService.getAll()
            const successSubscribe = result?.active
            const subscribePendingReject = result?.pending.filter(record => record?.record_type === 0)
            const subscribeRejectedReject = result?.rejected_subscriptions
            const successSubscribeActive = successSubscribe
            const PendingUnsubscribe = subscribePendingReject?.filter(record => record?.record_type === 0)
            const RejectedResult = subscribeRejectedReject?.filter(record => record?.record_type === 2)
            setActiveSubscribe(successSubscribeActive ?? [])
            setRejectedResult(RejectedResult ?? [])
            setPending(subscribePendingReject ?? [])
            setIsBusy(false)

        } catch (error) {
            setIsBusy(false)
            console.log(error)
        }
        setIsBusy(false)
    }
    const getthestatus = (status) => {
        if (status == 0) {
            return t('pammSubsccription.pending');
        } else if (status == 1) {
            return t('pammSubsccription.active');
        } else if (status == 2) {
            return t('pammSubsccription.rejected');
        } else if (status == 3) {
            return t('pammSubsccription.pendingForUnsubscribe');
        }
    }

    const reload = () => {
        window.location.reload()
    }

    useEffect(() => {
        init()
    }, [])


    const PammActiveList = activeList.map(user => {

        return (
            <>
                <Col lg='4' md='6' key={user.id} style={{ backgroundColor: 'white', borderRadius: '20px' }}>
                    <Card style={{ boxShadow: '0px 7px 20px #00000014' }}>
                        <Row className='mt-3 ml-0'>
                            <Col lg="4" style={{ textAlign: 'center', padding: '0px', marginTop: '15px' }}>
                                <a href={`/veFund/${user?.pammManager?.id}/statistic`} target='_blank' rel="noopener noreferrer">
                                    <img
                                        width='60px' height='60px'
                                        style={{ borderRadius: '15px' }}
                                        src={user.pammManager.avatar !== "" ? user.pammManager.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`}
                                        alt={user.pammManager.name} />
                                </a>
                            </Col>
                            <Col lg='8' style={{ paddingTop: '10px' }}>
                                <p id="pamm-text" style={{color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.ManagerName")} :</p>
                                <p id="pamm-sub-name">{user?.pammManager?.name}</p>
                            </Col>

                        </Row>
                        <div className='mt-2' style={{ borderBottom: '3px solid #f7f7f7' }} />
                        <Row style={{ display: "flex", flexDirection: 'column', padding: '20px', margin: '0px 10px' }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <p id="pamm-text" style={{ color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.date")} :</p>
                                <p>{moment(user?.created_at).format('yyyy-MM-DD')}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <p id="pamm-text" style={{ color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.account")} :</p>
                                <p>{user?.account?.account_login}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <p id="pamm-text" style={{ color: '#9099A3', marginBottom:'10px' }}>{t("pammSubsccription.statistic")} :</p>
                                <Link to={`/veFund/${user.pammManager?.id}/statistic`}>{t('pammSubsccription.view')}</Link>
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <p id="pamm-text" style={{ color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.status")} :</p>
                                <p style={{margin:'0px'}}>{getthestatus(user?.status)}</p>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <p><a href={user?.lpoa_url} target="_blank">LPOA Agreement</a></p>
                                <p><a href={user?.op_url} target="_blank">VE Fund Agreement</a></p>
                            </div>
                        </Row>
                        <Row className="mb-5" style={{ justifyContent: 'center' }}>
                            <Button
                                onClick={() => {
                                    setShowUnsubscribe(true)
                                    setSelectedSubcribe(user)
                                }}
                                style={{ cursor: 'pointer', fontWeight: 'bold', marginBottom: '20px', color: 'black', borderRadius: '20px' }} id="smallerFontSize" color="primary">
                                {t("pammSubsccription.Unsubscribe")}
                            </Button>
                        </Row>
                    </Card>

                </Col>
                {/* <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>

                     <Row style={{ justifyContent: 'center' }}>
                        <div className="mt-3" style={{ textAlign: 'center' }}>
                            <a href={`/veFund/${user?.pammManager?.id}/statistic`} target='_blank' rel="noopener noreferrer">
                                <img id="imageSize"
                                    style={{ borderRadius: '10px' }}
                                    src={user.pammManager.avatar !== "" ? user.pammManager.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`}
                                    alt={user.pammManager.name} />
                            </a>
                            <h5 className="mt-2" style={{ fontWeight: '400' }}>{user?.pammManager?.name}</h5>
                        </div>
                    </Row> 

                    <div className="mt-2" style={{ textAlign: 'center' }}>
                        <p>Account: {user?.account?.account_login}</p>
                        <p>Start Date: {moment(user?.created_at).format('yyyy-MM-DD')}</p>
                        <p><a href={user?.lpoa_url} target="_blank">LPOA Agreement</a></p>
                        <p><a href={user?.op_url} target="_blank">VE Fund Agreement</a></p>
                    </div>

                    <Row style={{ justifyContent: 'center' }}>
                        <Button
                            onClick={() => {
                                setShowUnsubscribe(true)
                                setSelectedSubcribe(user)
                            }}
                            style={{ cursor: 'pointer', fontWeight: 'bold', marginBottom: '20px' }} id="smallerFontSize" color="secondary">
                            {t("pammSubsccription.Unsubscribe")}
                        </Button>
                    </Row>

                </Card> */}
            </>


        )
    })

    return (
        <>

            {activeList.length > 0 &&
                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <h4 className='d-none d-lg-block'>{t("pammSubsccription.success")}</h4>
                        <h6 className='d-block d-lg-none'>{t("pammSubsccription.success")}</h6>
                    </Row>

                    <Container className="p-0">
                        <Row>
                            {activeList.length > 0 && PammActiveList}
                        </Row>
                    </Container>


                </Container>
            }


            {pending.length > 0 &&
                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <h4 className='d-none d-lg-block'>{t("pammSubsccription.pending")}</h4>
                        <h6 className='d-block d-lg-none'>{t("pammSubsccription.pending")}</h6>
                    </Row>
                    <Row className='m-0' style={{ justifyContent: 'space-between', width: '100%' }}>
                        <PammPendingTable />
                    </Row>
                </Container>
            }

            {RejectedResult.length > 0 &&

                <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <Row style={{ width: '100%', marginLeft: '0px' }}>
                        <h4 className='d-none d-lg-block'>{t("pammSubsccription.rejected")}</h4>
                        <h6 className='d-block d-lg-none'>{t("pammSubsccription.rejected")}</h6>
                    </Row>
                    <Row className='m-0' style={{ justifyContent: 'space-between', width: '100%' }}>
                        <PammRejectedTable />
                    </Row>

                </Container>

            }



            <Row className="mb-5">

                {isBusy ? <><h4>{t("pammSubsccription.loading")}...</h4><br /></> : ''}
                {/* Show when no any active manager */}
                {activeList.length <= 0 && !isBusy &&
                    <Button style={{ marginLeft: '30px', borderRadius: '15px', color: 'black' }} color="primary" onClick={reload}>
                        {t("pammSubsccription.SubscribeHere")}
                    </Button>
                }


            </Row>





            {/* <div className="mt-3">

                <PammPendingTable />
            </div> */}


            {showUnsubscribe && (
                <Modal isOpen={showUnsubscribe} backdrop={true} centered={true}>
                    <ModalHeader toggle={toggleModal}></ModalHeader>
                    <ModalBody>
                        <Unsubscribe activeUserData={selectedSubcribe} />
                    </ModalBody>
                    <ModalFooter><Button onClick={toggleModal} color="primary">{t("pammSubsccription.Close")} </Button></ModalFooter>
                </Modal>

            )}
        </>
    )
}

export default PammActiveSubscription

