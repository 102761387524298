import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ResponsiveLine } from '@nivo/line'
import {
    Row,
    Col,
    Card,
    Button,
    Input,
    CardFooter,
    Spinner,
    Container
} from 'reactstrap'

import { Link, NavLink } from 'react-router-dom'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PammService from '../../../network/services/pammManager'
import UserService from '../../../network/services/user'
import AccountService from '../../../network/services/account'


import Pagination from 'react-js-pagination'

import { useTranslation } from 'react-i18next'

import PammMangerList from '../../newDashboard/PammManager/index'

import './pammManager.scss'

import "intro.js/introjs.css";
import { Steps } from 'intro.js-react';

const PammManager = (props) => {

    const { t } = useTranslation()
    const [pammManager, setPammManager] = useState([])
    const dispatch = useDispatch()


    // Filter manager list
    const [searchTerm, setSearchTerm] = useState("")
    const [isBusy, setIsBusy] = useState(false)
    const [synAcc, setSynAcc] = useState(false)

    const [searchResults, setSearchResults] = useState([])
    const [teamId, setTeamId] = useState(null)
    const [partnerTeamIds, setPartnerTeamIds] = useState([])
    const handleChange = event => {
        setSearchTerm(event.target.value)
    };

    // Pagination
    const todosPerPage = 4;
    const [activePage, setCurrentPage] = useState(1);

    const indexOfLastTodo = activePage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = searchResults.slice(indexOfFirstTodo, indexOfLastTodo);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    };


    const init = async () => {
        setIsBusy(true)
        try {
            const result = await PammService.getAll()
            const userInfo = await UserService.getMyself()
            const accountResponse = await AccountService.getAll()

            dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

            setTeamId(userInfo.user.referred_by?.parent_user?.partner?.team_id ?? null)

            if (userInfo.partners.length > 0) {
                const partnerTeamArray = []
                await userInfo.partners.forEach(item => partnerTeamArray.push(item.team_id))
                setPartnerTeamIds(partnerTeamArray)
            }

            setPammManager(result.pamms.data ?? {})
            setSearchResults(result.pamms.data ?? {})
            setIsBusy(false)

        } catch (error) {
            setPammManager([])
            setIsBusy(false)
            setSynAcc(true)

        }


    }

    const vePackageAccounts = useSelector((state) => {
        if (state.account.accounts?.length > 0) {
            return state.account.accounts.filter((e) => (e.package_id === 4 || e.package_id === 5 || e.package_id === 6))
        } else if (state.account.accounts?.length === 0) {
            return []
        } else {
            return null
        }
    })


    useEffect(() => {

        init()

    }, [])

    useEffect(() => {
        const results = pammManager.filter(user =>
            user.name.toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm]);

    let totalGrowth = 0
    let allGrowthData = []

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    // const liveAccounts = useSelector((state) => {
    //     if (state.account.accounts?.length > 0) {
    //         return state.account.accounts.filter((e) => (e.account_type === 0 || e.account_type === 2))
    //     } else if (state.account.accounts?.length === 0) {
    //         return []
    //     } else {
    //         return null
    //     }
    // })

    // console.log('liveAccounts', liveAccounts)

    const followButton = (user) => {
        const pammManagerTeamsIds = user.pammManagerTeams.map(item => item.team_id)
        if (user.pammManagerTeams.length > 0) {
         
            if (vePackageAccounts.length > 0) {
                return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/statistic`}><Button style={{ padding: '5px 10px', width: '141px', height: '58px', borderRadius: '26px' }} color="primary">{t("pamm.explore")}</Button></Link>
            }

            if (pammManagerTeamsIds.includes(teamId) || partnerTeamIds.some(partnerTeamId => pammManagerTeamsIds.includes(partnerTeamId))) {
                return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/statistic`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px', width: '141px', height: '58px', borderRadius: '26px' }} color="primary">{t("pamm.explore")}</Button></Link>
            }
            if (user.is_follow_disabled) {
                return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/statistic`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px', width: '141px', height: '58px', borderRadius: '26px' }} color="primary">{t("pamm.explore")}</Button></Link>
            }



            if (user?.email) {
                return <a href={`mailto:${user?.email}?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '5px 10px', borderRadius: '5px' }} className="btn-primary">{t("pamm.contactPamm")}</a>
            } else {
                return <a href={`mailto:info@ve.education?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '5px 10px', borderRadius: '5px' }} className="btn-primary">{t("pamm.contactAdmin")}</a>
            }
        }
        return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/statistic`}><Button disabled={user.is_follow_disabled} style={{ padding: '5px 10px', width: '100px', height: '50px', borderRadius: '26px', color: 'black' }} color="primary">{t("pamm.explore")}</Button></Link>
    }


    const mobilePammList = pammManager.map(user => {
        allGrowthData = []
        totalGrowth = 0



        for (let [key, value] of Object.entries(user.performances)) {
            allGrowthData.push({
                "x": value.id,
                "y": totalGrowth + value.monthly_growth
            })
            totalGrowth += value.monthly_growth

        }

        if (allGrowthData.length < 1) {
            allGrowthData.push({
                "x": "0",
                "y": 0
            },
                {
                    "x": "1",
                    "y": 0
                })
        } else if (allGrowthData.length === 1) {
            allGrowthData.unshift({
                "x": "0",
                "y": 0
            })
        }

        return (

            <Col lg='6' md='6' xs='10' key={user.id} className="result mb-3 mt-2">
                <Card className="d-block d-lg-none d-md-none" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: 'white', color: 'black' }}>
                    <NavLink to={`veFund/${user.id}/statistic`}>
                        <div style={{ cursor: 'pointer' }}>



                            <Row className="m-0 pt-3 pl-3 pr-3 pb-0" style={{ color: 'black', marginBottom: '10px', alignItems: 'center' }}>

                                <Col id='total-growth' xl="7" lg='12' md='7' sm='6' xs='7' style={{ textAlign: 'start', padding: '0px', marginTop: '10%' }}>
                                    <p style={{ lineHeight: '1.3', letterSpacing: '0' }}>
                                        <span style={{ fontSize: '16px', color: '#232323' }}>{t("pamm.growth")}</span> <br></br>
                                        {totalGrowth > 0 ?
                                            <b style={{ color: 'green', fontSize: '16px' }}>{totalGrowth.toFixed(2)} %</b>
                                            :
                                            <b style={{ color: 'red', fontSize: '16px' }}>{totalGrowth.toFixed(2)} %</b>
                                        }
                                    </p>


                                </Col>
                                <Col xl="5" lg='12' md='5' sm='6' xs='5' style={{ textAlign: 'center', padding: '0px' }}>
                                    <img width="75px" height="75px" style={{ borderRadius: '20px' }} alt='pamm avatar' src={user.avatar !== "" ? user.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />
                                </Col>


                                {/* <Col style={{ color: '#fbc54d' }}>{t("pamm.totalFundSize")}<br></br> <p style={{ color: '#103c5a' }}>{user?.statistics?.fund_size}++</p></Col> */}
                            </Row>
                            <Row className="ml-0 mr-0 mt-2 mb-0 pl-3">
                                <p style={{ margin: '0px', color: 'black', fontSize: '16px', lineHeight: '1.3', letterSpacing: '0', textOverflow: 'ellipsis' }} >{user.name}</p>
                            </Row>
                            <Row className="m-0 pl-3">
                                <p id='total-fund-size' style={{ marginTop: '-5px', marginBottom: "0px", color: '16px', color: '#4F5358', fontSize: '12px' }} >{t("pamm.totalFundSize")}: {user?.statistics?.fund_size}++</p>
                            </Row>
                            {/* <Row className='m-l-10'>
                                <p style={{ margin: '0px', color: 'black', fontSsize: '20px', marginBottom: '0px' }} >{user.name}</p>
                            </Row>
                            <Row className='m-l-10  '>
                                <p id='total-fund-size' style={{ marginTop: '-5px', marginBottom: "10px", color: '16px', color: '#4F5358' }} >{t("pamm.totalFundSize")}: {user?.statistics?.fund_size}++</p>
                            </Row> */}

                            {/* <Row className="mt-3" style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <Col style={{ color: '#fbc54d' }}>{t("pamm.minimum")}<p style={{ color: '#103c5a' }}>{user.min_amount} USD</p></Col>
                                <Col style={{ color: '#fbc54d' }}>{t("pamm.totalFundSize")}<br></br> <p style={{ color: '#103c5a' }}>{user?.statistics?.fund_size}++</p></Col>
                            </Row> */}
                            {/* <Row>
                            <div id='line-graph' style={{ height: '200px', width: '100%' }}> */}
                            {/* Map each graph */}
                            {/* <ResponsiveLine
                                    colors=' #C4A569'
                                    data={[{
                                        "id": "profit",
                                        "color": "hsl(294, 70%, 50%)",
                                        "data": allGrowthData

                                    }]}

                                    curve="natural"
                                    type="linear"
                                    enableSlices='x'
                                    defs={[
                                        {
                                            id: 'gradientC',
                                            type: 'linearGradient',
                                            colors: [
                                                { offset: 0, color: '#f7cc51' },
                                                { offset: 20, color: 'inherit' },
                                                { offset: 80, color: '#fff' },
                                                { offset: 100, color: '#fff' },
                                            ],
                                        }
                                    ]}

                                    fill={[
                                        { match: '*', id: 'gradientC' }
                                    ]}

                                    margin={{ top: 30, bottom: 20, left: 0, right: 0 }}
                                    xScale={{ type: 'point' }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                    yFormat=" >-.2f"
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableGridX={false}
                                    enableGridY={false}
                                    enablePoints={false}
                                    pointSize={10}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'serieColor' }}
                                    pointLabelYOffset={-12}
                                    enableArea={true}
                                    useMesh={true}
                                    legends={[]}
                                    isInteractive={true}
                                /></div> */}
                            {/* <div style={{ display: 'flex', alignItems: 'flex-end' }} className="mt-3">
                                <Col lg="3" >
                                    <img style={{ marginLeft: '20px', marginBottom: '10px', borderRadius: '5px' }} width="60px" height="55px" src={user.avatar !== "" ? user.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />
                                </Col>
                                <Col lg="9" className="m-b-10">

                                    <h6 className="mt-1" style={{ color: '#fbc54d' }}>{user.name} <img className="mb-1 mobile-center" width="11px" height="12px" src={VerificationIcon} /></h6>
                                    {followButton(user)} 
                                </Col>
                            </div> */}
                            {/* </Row> */}
                            <Row className="m-0 mb-3 p-3" style={{ color: 'black', alignItems: 'center' }}>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center', padding: '0px' }}>
                                    {followButton(user)}
                                </Col>
                            </Row>
                        </div>
                    </NavLink>
                    {/* <div className="m-t-20" style={{ display: 'flex', textAlign: "center" }}>
                        <Col id='total-followers' lg="4">
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{user.totalFollowers}</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.Followers")}</span></p>
                        </Col>

                        <Col id='monthly-growth' lg="4" style={{ padding: '0', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                           
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{user?.performances[getLatestPerformanceIndex(user?.performances)]?.monthly_growth.toFixed(2) ?? '0.00'} %</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.monthlyGrowth")}</span></p>
                        </Col>

                        <Col id='total-growth' lg="4">
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{totalGrowth.toFixed(2)} %</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.growth")}</span></p>
                        </Col>

                    </div> */}



                    {/* <CardFooter style={{ padding: '20px', backgroundColor: 'white', color: 'black' }}>
                        <Row id='min-subscribe' style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginBottom: '0px' }}>{t("pamm.minimum")}: {formatter.format(user.min_amount)}</p> */}
                    {/* {followButton(user)} */}
                    {/* </Row>
                    </CardFooter> */}
                </Card>

                {/* {localStorage.getItem('pamm-list-onboarding') === null &&
                    <Steps
                        enabled={stepsEnabled}
                        steps={steps}
                        initialStep={0}
                        onExit={() => {
                            localStorage.setItem('pamm-list-onboarding', true)
                            setStepsEnabled(false)
                        }}
                        options={
                            {
                                showProgress: true,
                                doneLabel: 'Understand',
                                exitOnOverlayClick: false
                            }
                        }
                    />
                } */}
            </Col>



        )
    })
    const PammList = currentTodos.map(user => {

        allGrowthData = []
        totalGrowth = 0



        for (let [key, value] of Object.entries(user.performances)) {
            allGrowthData.push({
                "x": value.id,
                "y": totalGrowth + value.monthly_growth
            })
            totalGrowth += value.monthly_growth

        }

        if (allGrowthData.length < 1) {
            allGrowthData.push({
                "x": "0",
                "y": 0
            },
                {
                    "x": "1",
                    "y": 0
                })
        } else if (allGrowthData.length === 1) {
            allGrowthData.unshift({
                "x": "0",
                "y": 0
            })
        }

        return (

            <Col lg='6' md='6' sm='6' key={user.id} className="result">
                <Card className="d-none d-lg-block d-md-block" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: 'white', color: 'black' }}>
                    <NavLink to={`veFund/${user.id}/statistic`}>
                        <div style={{ cursor: 'pointer' }}>



                            <Row className="m-0 p-3" style={{ color: 'black', marginBottom: '10px', alignItems: 'center' }}>

                                <Col id='total-growth' xl="7" lg='12' md='7' sm='6' xs='7' style={{ textAlign: 'start', padding: '0px', marginTop: '10%' }}>
                                    <p style={{ lineHeight: '1.3', letterSpacing: '0' }}>
                                        <span style={{ fontSize: '16px', color: '#232323' }}>{t("pamm.growth")}</span> <br></br>
                                        {totalGrowth > 0 ?
                                            <b style={{ color: 'green', fontSize: '16px' }}>{totalGrowth.toFixed(2)} %</b>
                                            :
                                            <b style={{ color: 'red', fontSize: '16px' }}>{totalGrowth.toFixed(2)} %</b>
                                        }
                                    </p>

                                </Col>
                                <Col xl="5" lg='12' md='5' sm='6' xs='5' style={{ textAlign: 'center', padding: '0px' }}>
                                    <img width="75px" height="75px" style={{ borderRadius: '20px' }} alt='pamm avatar' src={user.avatar !== "" ? user.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />
                                </Col>

                                {/* <Col style={{ color: '#fbc54d' }}>{t("pamm.totalFundSize")}<br></br> <p style={{ color: '#103c5a' }}>{user?.statistics?.fund_size}++</p></Col> */}
                            </Row>
                            <Row className="m-0 pl-3">
                                <p style={{ margin: '0px', color: 'black', fontSize: '20px', marginBottom: '0px' }} >{user.name}</p>
                            </Row>
                            <Row className="m-0 pl-3">
                                <p id='total-fund-size' style={{ marginTop: '-5px', marginBottom: "10px", color: '16px', color: '#4F5358' }} >{t("pamm.totalFundSize")}: {user?.statistics?.fund_size}++</p>
                            </Row>
                            {/* <Row className='m-l-10'>
                                <p style={{ margin: '0px', color: 'black', fontSsize: '20px', marginBottom: '0px' }} >{user.name}</p>
                            </Row>
                            <Row className='m-l-10  '>
                                <p id='total-fund-size' style={{ marginTop: '-5px', marginBottom: "10px", color: '16px', color: '#4F5358' }} >{t("pamm.totalFundSize")}: {user?.statistics?.fund_size}++</p>
                            </Row> */}

                            {/* <Row className="mt-3" style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <Col style={{ color: '#fbc54d' }}>{t("pamm.minimum")}<p style={{ color: '#103c5a' }}>{user.min_amount} USD</p></Col>
                                <Col style={{ color: '#fbc54d' }}>{t("pamm.totalFundSize")}<br></br> <p style={{ color: '#103c5a' }}>{user?.statistics?.fund_size}++</p></Col>
                            </Row> */}
                            {/* <Row>
                            <div id='line-graph' style={{ height: '200px', width: '100%' }}> */}
                            {/* Map each graph */}
                            {/* <ResponsiveLine
                                    colors=' #C4A569'
                                    data={[{
                                        "id": "profit",
                                        "color": "hsl(294, 70%, 50%)",
                                        "data": allGrowthData

                                    }]}

                                    curve="natural"
                                    type="linear"
                                    enableSlices='x'
                                    defs={[
                                        {
                                            id: 'gradientC',
                                            type: 'linearGradient',
                                            colors: [
                                                { offset: 0, color: '#f7cc51' },
                                                { offset: 20, color: 'inherit' },
                                                { offset: 80, color: '#fff' },
                                                { offset: 100, color: '#fff' },
                                            ],
                                        }
                                    ]}

                                    fill={[
                                        { match: '*', id: 'gradientC' }
                                    ]}

                                    margin={{ top: 30, bottom: 20, left: 0, right: 0 }}
                                    xScale={{ type: 'point' }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                    yFormat=" >-.2f"
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableGridX={false}
                                    enableGridY={false}
                                    enablePoints={false}
                                    pointSize={10}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'serieColor' }}
                                    pointLabelYOffset={-12}
                                    enableArea={true}
                                    useMesh={true}
                                    legends={[]}
                                    isInteractive={true}
                                /></div> */}
                            {/* <div style={{ display: 'flex', alignItems: 'flex-end' }} className="mt-3">
                                <Col lg="3" >
                                    <img style={{ marginLeft: '20px', marginBottom: '10px', borderRadius: '5px' }} width="60px" height="55px" src={user.avatar !== "" ? user.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />
                                </Col>
                                <Col lg="9" className="m-b-10">

                                    <h6 className="mt-1" style={{ color: '#fbc54d' }}>{user.name} <img className="mb-1 mobile-center" width="11px" height="12px" src={VerificationIcon} /></h6>
                                    {followButton(user)} 
                                </Col>
                            </div> */}
                            {/* </Row> */}
                            <Row className="m-0 mb-3 p-3" style={{ color: 'black', alignItems: 'center' }}>
                                <Col></Col>
                                <Col style={{ textAlign: 'center', padding: '0px' }}>
                                    {followButton(user)}
                                </Col>
                            </Row>
                        </div>
                    </NavLink>
                    {/* <div className="m-t-20" style={{ display: 'flex', textAlign: "center" }}>
                        <Col id='total-followers' lg="4">
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{user.totalFollowers}</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.Followers")}</span></p>
                        </Col>

                        <Col id='monthly-growth' lg="4" style={{ padding: '0', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                           
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{user?.performances[getLatestPerformanceIndex(user?.performances)]?.monthly_growth.toFixed(2) ?? '0.00'} %</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.monthlyGrowth")}</span></p>
                        </Col>

                        <Col id='total-growth' lg="4">
                            <p style={{ lineHeight: '1.3', letterSpacing: '0' }}><b>{totalGrowth.toFixed(2)} %</b> <br></br><span style={{ fontSize: '12px' }}>{t("pamm.growth")}</span></p>
                        </Col>

                    </div> */}



                    {/* <CardFooter style={{ padding: '20px', backgroundColor: 'white', color: 'black' }}>
                        <Row id='min-subscribe' style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginBottom: '0px' }}>{t("pamm.minimum")}: {formatter.format(user.min_amount)}</p> */}
                    {/* {followButton(user)} */}
                    {/* </Row>
                    </CardFooter> */}
                </Card>

                {/* {localStorage.getItem('pamm-list-onboarding') === null &&
                    <Steps
                        enabled={stepsEnabled}
                        steps={steps}
                        initialStep={0}
                        onExit={() => {
                            localStorage.setItem('pamm-list-onboarding', true)
                            setStepsEnabled(false)
                        }}
                        options={
                            {
                                showProgress: true,
                                doneLabel: 'Understand',
                                exitOnOverlayClick: false
                            }
                        }
                    />
                } */}
            </Col>


        )

    })



    return (
        <>
            {/* <Row className='mt-2' style={{ margin: '0' }}>
                <Input
                    id='searchInput'
                    type="text"
                    placeholder={t("pamm.Search")}
                    value={searchTerm}
                    onChange={handleChange}
                    style={{ marginLeft: '15px', marginBottom: '20px', width: '100%', border: 'none', borderRadius: '20px', backgroundColor: '#f7f7f7' }}
                />
            </Row> */}
            <Row>
                {/* <Col className='d-none d-lg-block '>
                    <h5 className='ml-3 mt-2 mb-2'>{t('pamm.TopFeature')}</h5>
                </Col> */}

                {/*mobile size */}
                {/* <Col className='d-block d-lg-none'>
                    <h6 className='ml-3 mt-2 mb-2'>{t('pamm.TopFeature')}</h6>
                </Col>

                <Col className='d-none d-lg-block '>
                    <h5 className='ml-0 mt-2 mb-2'>{t('pamm.ForYou')}</h5>
                </Col> */}
            </Row>
            <div className="d-block d-lg-none d-md-none">
                <Row className="courseScrollBar mb-3 ml-0 mr-0" style={{ display: 'flex', flexWrap: "nowrap", overflow: 'overlay' }}>
                    {mobilePammList}
                </Row>
            </div>

            <Row style={{ marginLeft: '0', marginRight: '0', padding: '0px', justifyContent: 'space-between' }}>
                <Col lg='6' md='12' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {isBusy ?
                        <Container
                            style={{
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {/*pamm card skeleton */}
                            <Col lg='6' md='6' sm='6' xs='10' className="result">
                                <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: 'white', color: 'black' }}>
                                    <Row className="m-0 p-3" style={{ color: 'black', marginBottom: '10px', alignItems: 'center' }}>
                                        <Col id='total-growth' xl="7" lg='12' md='7' sm='6' xs='7' style={{ textAlign: 'start', padding: '0px', marginTop: '10%' }}>
                                            <Skeleton width={50} />
                                            <Skeleton width={50} />
                                        </Col>
                                        <Col xl="5" lg='12' md='5' sm='6' xs='5' style={{ textAlign: 'center', padding: '0px' }}>
                                            <Skeleton width={50} height={50} />
                                        </Col>
                                    </Row>
                                    <Row className="m-0 pl-3">
                                        <Skeleton width={50} />
                                    </Row>
                                    <Row className="m-0 pl-3">
                                        <Skeleton width={50} />
                                    </Row>
                                    <Row className="m-0 mb-3 p-3" style={{ color: 'black', alignItems: 'center' }}>
                                        <Col></Col>
                                        <Col style={{ textAlign: 'center', padding: '0px' }}>
                                            <Skeleton width={100} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col lg='6' md='6' sm='6' className="result">
                                <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: 'white', color: 'black' }}>
                                    <Row className="m-0 p-3" style={{ color: 'black', marginBottom: '10px', alignItems: 'center' }}>
                                        <Col id='total-growth' xl="7" lg='12' md='7' sm='6' xs='7' style={{ textAlign: 'start', padding: '0px', marginTop: '10%' }}>
                                            <Skeleton width={50} />
                                            <Skeleton width={50} />
                                        </Col>
                                        <Col xl="5" lg='12' md='5' sm='6' xs='5' style={{ textAlign: 'center', padding: '0px' }}>
                                            <Skeleton width={50} height={50} />
                                        </Col>


                                    </Row>
                                    <Row className="m-0 pl-3">
                                        <Skeleton width={50} />
                                    </Row>
                                    <Row className="m-0 pl-3">
                                        <Skeleton width={50} />
                                    </Row>

                                    <Row className="m-0 mb-3 p-3" style={{ color: 'black', alignItems: 'center' }}>
                                        <Col></Col>
                                        <Col style={{ textAlign: 'center', padding: '0px' }}>
                                            <Skeleton width={100} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Container>
                        : ''}

                    {synAcc === true ?
                        <>
                            <Row style={{
                                padding: '15px',
                                width: '100%',
                                backgroundColor: 'transparent',
                                // backgroundImage: `url(${WalletBG})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                justifyContent: 'space-between',
                                marginBottom: '20px',
                                marginRight: '0px',
                                marginLeft: '0px',
                                backgroundColor: ' #c3a569',
                                boxShadow: '0px 7px 20px #00000014',
                                borderRadius: '20px',
                                color: 'black',
                                border: "3px solid #ad986e"
                            }}>
                                <Col lg='12'>
                                    <h3>No trade account found in your linked account</h3>
                                    <p>Kindly open account first </p>
                                    <Button
                                        color='white'
                                        style={{ borderRadius: '14px', backgroundColor: 'white', fontSize: '16px', height: '40px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                                        onClick={() => {

                                            window.open(
                                                'https://secure.tradehallglobal.co/auth'
                                            );
                                        }}

                                    >
                                        Open Trade Account
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        : ''}

                    {PammList}

                    <div className="pagination d-none d-lg-block d-md-block" style={{ justifyContent: 'center', fontSize: '16px', width: '100%', marginBottom: '20px' }}>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={4}
                            totalItemsCount={pammManager.length}
                            pageRangeDisplayed={9}
                            onChange={handlePageChange}
                            prevPageText={t("pamm.Prev")}
                            nextPageText={t("pamm.Next")}
                            itemClass="paginationLI"
                            activeClass="paginationActiveLi"
                            hideFirstLastPages
                        />
                    </div>


                </Col>
                {/* <Row>
                    <Col className='d-block d-lg-none'>
                        <h6 className='ml-3 mt-2 mb-1'>{t('pamm.ForYou')}</h6>
                    </Col>
                </Row>
                <Col lg='6' md='12' className='p-0'>
                    <PammMangerList />
                </Col> */}
            </Row>



            {/* <div className="pagination " style={{ justifyContent: 'center', fontSize: '16px' }}>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={9}
                    totalItemsCount={pammManager.length}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                    prevPageText={t("pamm.Prev")}
                    nextPageText={t("pamm.Next")}
                    itemClass="paginationLI"
                    activeClass="paginationActiveLi"
                    hideFirstLastPages
                />
            </div> */}

        </>
    )
}

export default PammManager