import React, { useEffect, useState } from 'react'

import Breadcrumb from '../../layout/breadcrumb'
import VeDiscordSubscribeForm from './subscribeForm/index'
import SweetAlert from 'sweetalert2'
import {
    Col,
    Row,
    Container,
    Button, Label, Input,
    Navbar,
    NavbarText,
    CardBody

} from 'reactstrap'
import { useTranslation } from 'react-i18next'

import './pamm.scss'
import DiscordRegister from './discordRegister'
import VeDiscordSubscription from './discordSubscription'
import StripeService from '../../network/services/stripe'

import PaymentHistoryTable from './paymentHistory'



const VeDiscord = () => {
    const [registerPage, setRegisterPage] = useState(0)
    const [isCheckDiscordRegister, setIsCheckDiscordRegister] = useState(false)
    const [nav_Show, setNav_Show] = useState('')


    const [discordSubscription, setDiscordSubscription] = useState([])
    const [discordSubscribedSubscription, setDiscordSubscribedSubscription] = useState([])

    //this is for user who unsubscribe but still able to see the group details, status 3
    const [discordUnSubscribeSubscription, setDiscordUnSubscribeSubscription] = useState([])



    const fetchDiscordSubscription = async () => {

        try {

            const result = await StripeService.checkDiscordSubscription('')
            const subscribedResult = result.result.filter((result) => {
                return result.status == 1
            })
            const unsubscribeResult = result.result.filter((result) => {
                return result.status == 3
            })
            console.log('result', result)
            setDiscordSubscription(result.result)
            setDiscordSubscribedSubscription(subscribedResult)
            setDiscordUnSubscribeSubscription(unsubscribeResult)
        } catch (error) {

            console.log(error)
        }
    }

    useEffect(() => {

        fetchDiscordSubscription()
    }, [])

    const { t } = useTranslation()
    const displayNav = () => {
        if (nav_Show === "Discord Subscription" || nav_Show === "Discord 订阅" || nav_Show === "") {
            return (
                <Col lg="12" style={{ padding: '0' }}>
                    {/* <PammManager /> */}
                    <VeDiscordSubscription subscribedDiscord={discordSubscribedSubscription} unsubscribeDiscordList={discordUnSubscribeSubscription} />
                </Col>
            )
        }
        else if (nav_Show === "Payment History" || nav_Show === "付款历史" || nav_Show === "") {
            return (
                <Col lg="12" style={{ padding: '0' }}>
                    <PaymentHistoryTable paymentResult={discordSubscription} />
                </Col>
            )
        }

    }

    return <>

        {/* <Breadcrumb parent="" title=""></Breadcrumb> */}
        {discordSubscription.length == 0 ?
            <Row style={{ marginTop: '10rem', marginBottom: '10rem' }}>
                <Col>
                    <Container>
                        <div style={{ display: registerPage == 0 ? 'block' : 'none' }}>
                            <DiscordRegister />
                            <div className='mt-5'>
                                <hr />

                            </div>
                            <div className='text-center mb-3'>
                                <div className="custom-control custom-checkbox mb-3 mt-5">
                                    <Input className="custom-control-input" id="agreement" type="checkbox" onClick={() => {
                                        setIsCheckDiscordRegister(!isCheckDiscordRegister)
                                    }} />
                                    <Label className="custom-control-label" htmlFor="agreement">
                                        I have already finished these steps

                                    </Label>
                                    <div className="invalid-feedback">{t("user.PleaseAgreeToTerms")}</div>
                                </div>
                                <div >
                                    <Button onClick={() => {
                                        if (isCheckDiscordRegister == false) {
                                            SweetAlert.fire({
                                                showClass: {
                                                    popup: 'animate__animated animate__backInUp'
                                                },
                                                hideClass: {
                                                    popup: 'animate__animated animate__backOutDown'
                                                },
                                                title: 'Please make sure you already checked the requirement',
                                                icon: 'warning'
                                            })
                                        } else {
                                            setRegisterPage(1)
                                        }

                                    }}>Next</Button>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: registerPage == 1 ? 'block' : 'none' }}>
                            <VeDiscordSubscribeForm />
                            <div className='text-center mb-3'>
                                <Button onClick={() => {
                                    setRegisterPage(0)
                                }}>Previous</Button>
                            </div>
                        </div>


                    </Container>
                </Col>
            </Row>

            :

            <>

                {/* Subscribed */}
                <Col className='d-none d-lg-block d-md-block' style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '30px' }}>
                    <Navbar id='NavBar' className="mobile-font-size m-3" expand="md" style={{ padding: '0px', justifyContent: 'flex-end', textAlign: 'center', backgroundColor: 'transparent', width: '100%', borderRadius: '20px' }}>

                        <div style={{
                            cursor: 'pointer', fontSize: '20px',
                            fontWeight: '500',

                        }} className={`${nav_Show === 'Discord Subscription'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Show === 'Discord Subscription' || nav_Show === "Discord 订阅" || nav_Show === "" ? 'active' : 'inactive'} mr-3 p-t-15`} style={{ width: '237px', height: '58px', padding: '0px 10px' }}>
                                <p className='m-0' style={{ fontSize: '18px' }}>Discord Subscription</p>
                            </NavbarText>
                        </div>


                        <div style={{
                            cursor: 'pointer', fontSize: '20px',
                            fontWeight: '500',
                        }} className={`${nav_Show === 'Payment History'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Show === 'Payment History' || nav_Show === "付款历史" ? 'active' : 'inactive'} mr-3 p-t-15`} style={{ width: '197px', height: '58px' }}>
                                <p className='m-0' style={{ fontSize: '18px' }}>Payment History</p>
                            </NavbarText>
                        </div>


                    </Navbar>
                </Col>
                <Col  className='d-none d-lg-block d-md-block' id='displayGraph' >
                    <CardBody style={{ border: 'none', borderRadius: '0', padding: '10px' }}>
                        {displayNav()}
                    </CardBody>
                </Col>


                {/*mobile navr bar*/}
                <Col
                    className='d-block d-lg-none d-md-none'
                    style={{
                        margin: '100px 0px',
                        padding: '0px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        backgroundColor: 'white',
                        borderRadius: '15px',
                        color: 'black',
                    }}

                >


                    <Col style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        <Navbar id='NavBar' className="mobile-font-size" expand="md"
                            style={{
                                padding: '10px',
                                justifyContent: 'center',
                                textAlign: 'center',
                                backgroundColor: 'white',
                                width: '100%',
                                borderRadius: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap'
                            }}>

                            <div style={{ cursor: 'pointer', padding: '5px' }} className={`${nav_Show === 'Discord Subscription'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                                <NavbarText className={`${nav_Show === 'Discord Subscription' || nav_Show === "Discord 订阅" || nav_Show === "" ? 'active' : 'inactive'} p-t-15`} style={{ width: '180px', height: '50px' }}>
                                    <p className='m-0' style={{ fontSize: '12px' }}>Discord Subscription</p>
                                </NavbarText>
                            </div>


                            <div style={{ cursor: 'pointer', padding: '5px' }} className={`${nav_Show === 'Payment History'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                                <NavbarText className={`${nav_Show === 'Payment History' || nav_Show === "付款历史" ? 'active' : 'inactive'} p-t-15`} style={{ width: '120px', height: '50px' }}>
                                    <p className='m-0' style={{ fontSize: '12px' }}>Payment History</p>
                                </NavbarText>
                            </div>

                        </Navbar>
                    </Col>
                    <Col id='displayGraph' >
                        <CardBody style={{ border: 'none', borderRadius: '0', padding: '0px' }}>
                            {displayNav()}
                        </CardBody>
                    </Col>

                </Col>
            </>

        }




    </>
}

export default VeDiscord