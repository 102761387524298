import client from '../request'

const login = (data) => {
  return client.post('/auth/login', data)
}

const register = (data) => {
  return client.post(`/auth/register`, data)
}

const logout = () => {
  // localStorage.clear()
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  // localStorage.removeItem('tokenTH')
  localStorage.removeItem('animation')
  window.location.reload()
}

const sendVerification = (data) => {
  return client.post(`/auth/code`, data)
}

const verifyCode = (data) => {
  return client.post(`/auth/code/verify`, data)
}

const forgotPassword = (data) => {
  return client.post(`/auth/forgot_password`, data)
}

const checkPhoneNumberDuplicate = (data) =>{
  return client.post(`/users/phone_number/available`, data)
}

export default {
  login,
  register,
  logout,
  sendVerification,
  verifyCode,
  forgotPassword,
  checkPhoneNumberDuplicate
}
