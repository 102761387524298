import React, { useState, useEffect } from 'react'
import { Col, Card, CardHeader, CardBody, Button, Spinner, Badge, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'
import styled from 'styled-components'

import AddressTable from './address_table'
import IdentityTable from './identity_table'

import UserService from '../../../network/services/user'
import VerificationService from '../../../network/services/verification'
import FilesService from '../../../network/services/files'
import { useTranslation } from 'react-i18next'
import { HelpCircle } from 'react-feather'


import './verification.scss'

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: flex-start;

    > button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`

const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`

const ResponsiveButton = styled(Button)`
  background-color: #963737 !important;
  border-color: #b35855 !important;
`
const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`


const Verification = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)
  const [reupload, setReupload] = useState(false)
  const profile = useSelector((state) => state.user.profile)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const verification = useSelector((state) => state.verification)
  const activeTab = useSelector((state) => state.verification.activeTab)
  const [showModal, setShowModal] = useState(false)
  const [activeChecked, setActiveChecked] = useState(1)

  const toggleModal = () => {
    setShowModal(!showModal)
  }


  const handleVerification = async (e) => {
    // console.log(verification)

    if (verification.idFront?.file == null) {
      console.log('missing id front')
      dispatch({ type: 'SET_ID_FRONT', idFront: { error: 'Please select one' } })
      return
    }

    if (verification.idBack?.file == null) {
      console.log('missing id back')
      dispatch({ type: 'SET_ID_BACK', idBack: { error: 'Please select one' } })
      return
    }

    if (verification.addressFront?.file == null) {
      console.log('missing address front')
      dispatch({ type: 'SET_ADDRESS_FRONT', addressFront: { error: 'Please select one' } })
      return
    }

    setIsBusy(true)

    try {
      const idFrontRes = await FilesService.create(verification.idFront?.file)
      const idBackRes = await FilesService.create(verification.idBack?.file)
      const addressFrontRes = await FilesService.create(verification.addressFront?.file)
      // const addressBackRes = await FilesService.create(verification.addressBack)

      const result = await VerificationService.create({
        type: verification.activeTab,
        id_front: idFrontRes.data,
        id_back: idBackRes.data,
        address_front: addressFrontRes.data,
        // debug
        address_back: addressFrontRes.data
      })

      // console.log(result)

      if (result?.verification) {
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: t("user.ThankYouYourAccount"),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      console.log(error)
      // const errorCode = error.code
      // const errorTranslate = error.translate_params

      if (error.message === 'file - File size should be less than 10MB'
        && sessionStorage.getItem('language') === 'en') {
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          // title: t(errorCode, errorTranslate),
          title: 'file - File size should be less than 10MB',
          icon: 'error'
        })
      } else if (error.message === 'file - File size should be less than 10MB'
        && sessionStorage.getItem('language') === 'zh') {
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          // title: t(errorCode, errorTranslate),
          title: '文件 - 文件不能超过10mb',
          icon: 'error'
        })
      } else {
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          // title: t(errorCode, errorTranslate),
          title: error.message,
          icon: 'error'
        })
      }


    }
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const init = async () => {
    const updatedUser = await UserService.getMyself()
    if (updatedUser.verifications) {
      dispatch({ type: 'SET_VERIFICATIONS', verifications: updatedUser.verifications })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // exist
  if (profile != null && !_.isEmpty(profile)) {
    return (
      <>

        {userVerification?.status === 0 && (
          <Card>
            <CardBody
              className="p-4"
              style={{ backgroundColor: ' #C4A569', borderRadius: '15px', padding: '20px' }}
            >
              <ResponsiveCard>
                <ResponsiveParagraph style={{ color: 'black', fontWeight: 'bold' }}>
                  {t("dashboard.yourRecentApplicationWasPending")}
                </ResponsiveParagraph>
              </ResponsiveCard>

            </CardBody>
          </Card>
        )}
        {userVerification?.status !== 1 &&
          userVerification?.status !== 0 && (
            <Card>
              <CardBody style={{ backgroundColor: 'white', borderRadius: '15px', padding: '20px', height: '100%' }}>
                <Row>
                  <Col>
                    <h5>{t("user.Identification")}</h5>
                  </Col>
                  <Col style={{ textAlign: 'end' }}>
                    <Badge style={{ backgroundColor: " #C4A569", borderRadius: '7px', color: 'black', width: '116px', height: '32px', fontSize: "16px" }}>{t("user.unverified")}</Badge>
                  </Col>
                </Row>

                {/* <p style={{ margin: 0, color: '#963737' }}>
                    <strong>
                      {t("user.YourProfileIsntVerified")}
                    </strong>
                  </p> */}
                <Row className="ml-0" style={{ width: "100%" }}>
                  {userVerification?.status === 3 && !reupload && (

                    <ResponsiveButton color="primary" onClick={() => setReupload(true)}>
                      {t("user.Reupload")}
                    </ResponsiveButton>

                  )}
                </Row>
                <Row className="ml-0 mt-1">
                  {userVerification?.status === 3 && userVerification?.reason && (
                    <>
                      <ResponsiveParagraph style={{ color: '#ab0505' }}>
                        {t("user.YourRecentApplication")}
                      </ResponsiveParagraph>
                      <ResponsiveParagraph
                        style={{ color: '#ab0505' }}
                      >{`${t("user.Reason")}: ${userVerification?.reason}`}</ResponsiveParagraph>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          )}

        {/* {userVerification?.status === 3 && userVerification?.reason && (
          <Card>
            <CardBody>
              <h5>Status: Rejected</h5>
              <p style={{ color: 'red', fontSize: 18 }}>{`Reason: ${userVerification?.reason}`}</p>
            </CardBody>
          </Card>
        )} */}

        {userVerification?.status !== 1 ?
          <>
            <Card>
              <CardHeader style={{ padding: '20px' }}>
                <h5>{t("user.Identification")}</h5>
                {userVerification?.status !== 0 &&

                  <>
                    <div className="p-1" />
                    <p style={{ color: '#4F5358', fontSize: '16px' }}>{t('user.identity')}</p>
                    <label className="container" style={{ width: '250px' }}>
                      <input type="radio" id="KYCradio" className={activeTab === 'passport' ? 'active' : ''}
                        style={{ marginRight: '20px' }}
                        checked={activeChecked == 1}
                        onClick={() => {
                          dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'passport' })
                          setActiveChecked(1)
                        }}
                      />
                      {t('user.Passport')}
                      <span className="checkmark"></span>
                    </label>

                    <label className="container" style={{ width: '250px' }}>
                      <input type="radio" id="KYCradio" className={activeTab === 'id' ? 'active' : ''}
                        style={{ marginRight: '20px' }}
                        checked={activeChecked == 2}
                        onClick={() => {
                          dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'id' })
                          setActiveChecked(2)
                        }}
                      />
                      {t('user.NationalID')}
                      <span className="checkmark"></span>
                    </label>
                  </>
                }
                <p style={{ fontSize: '16px', color: '#4F5358' }}>{t('user.requirements')}</p>
                <div className="p-0" />

              </CardHeader>
              <CardBody style={{ padding: '20px' }}>
                <IdentityTable reupload={reupload} />
              </CardBody>

              <CardHeader style={{ padding: '20px', paddingTop: '40px' }}>
                <h5>{t("user.ProofOfAddress")}
                  <HelpCircle onClick={() => { toggleModal() }} style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />
                  <Modal isOpen={showModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>{t("pamm.PleaseTakeNote")}</ModalHeader>
                    <ModalBody>
                      <p>{t("user.OurComplianceRequiresThatWeVerify")}</p>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={() => { toggleModal() }} style={{ margin: '0 auto' }}>Understand</Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </h5>
                <div className="p-1" />
                <p style={{ margin: 0, fontSize: '16px' }}>
                  {t("user.ProofOfAddressThatIsNotOlderThan")}<strong>180 {t("user.days")}</strong> {t("user.utilityBillBankStatementDrivingLicense")}
                </p>
              </CardHeader>
              <CardBody style={{ padding: '20px' }}>
                <AddressTable reupload={reupload} />
              </CardBody>
            </Card>
          </>
          :
          //Verify process is success
          <>

            <Card>
              <CardHeader style={{ padding: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 style={{ marginRight: '10px' }}>{t("user.Identification")}</h5>
                  <Badge color="primary">Verified</Badge>
                </div>
                <div className="p-1" />
                <p style={{ margin: 0 }}>{t("user.PassportNationalID")}</p>
                <div className="p-0" />

                <p style={{ color: 'green' }}>
                  <strong>{t('user.verified')}</strong>
                </p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader style={{ padding: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 style={{ marginRight: '10px' }}>{t("user.ProofOfAddress")}</h5>
                  <Badge color="primary">Verified</Badge>
                </div>
                <div className="p-1" />
                <p style={{ margin: 0 }}>
                  {t("user.ProofOfAddressThatIsNotOlderThan")}<strong> 180 {t("user.days")}</strong> {t("user.utilityBillBankStatementDrivingLicense")}
                </p>

                <p style={{ color: 'green' }}>
                  <strong>{t('user.verified')}</strong>
                </p>
              </CardHeader>
            </Card>

          </>
        }


        {(userVerification == null || reupload) && (
          <Card>
            <CardBody style={{ textAlign: 'center' }}>
              <h6>
                {t("user.ByClickingConfirmProceedYouConfirmThat")}
              </h6>
              <Button color="primary" onClick={handleVerification} disabled={isBusy}>
                {isBusy ? t("user.Loading") : t("user.Confirm")}
              </Button>
            </CardBody>
          </Card>
        )}
      </>
    )
  }

  // new
  if (profile != null && _.isEmpty(profile)) {
    return (
      <Card>
        <CardBody>
          {t("user.PleaseCompleteThe")} <a href="#details">{t("user.MyDetailsVerificationProcess")}</a> {t("user.toContinueTheProcess")}
        </CardBody>
      </Card>
    )
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default Verification
