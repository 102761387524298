import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, ListGroup, ListGroupItem, Input, FormText, Container } from 'reactstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`

const AddressUpload = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const addressFront = useSelector((state) => state.verification.addressFront)

  return (
    <>
      <Row>
        <Col md="12">
          <Container className="d-none d-lg-block d-xl-block d-md-block" style={{textAlign:'center', padding:'0px'}}>
            <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCutAddress.png" width='22%'/>
            <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotblurAddress.png" width='22%'/>
            <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustBeValid.png" width='22%'/>
            <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleAddress.png" width='22%'/>
          </Container>

          {/*mobile size */}

          <Container className="d-block d-xl-none d-lg-none d-md-none" style={{textAlign:'center', padding:'0px'}}>
            <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCutAddress.png" width='80%'/>
            <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotblurAddress.png" width='80%'/>
            <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustBeValid.png" width='80%'/>
            <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleAddress.png" width='80%'/>
          </Container>
          {/* <ListGroup className="list-group-flush">
            <ListGroupItem>
              {t("user.MustBeDatedNotOlderThan")} <strong>{t("user.sixMonths")}</strong> {t("user.atTimeOfVerification")}
            </ListGroupItem>
            <ListGroupItem>
              {t("user.DocumentMustShowResidenceAddress")}
            </ListGroupItem>
            <ListGroupItem>
              {t("user.AllInformationAndDetailsMust")}
            </ListGroupItem>
            <ListGroupItem>{t("user.MustBePresentedInFull")}</ListGroupItem>
            <ListGroupItem>
              {t("user.MustBeStatementThatIncludingAddress")}
            </ListGroupItem>
            <ListGroupItem>{t("user.TheEdgesOfDocumentsShouldNotCutOff")}</ListGroupItem>
          </ListGroup> */}
        </Col>
        {/* <Col md="6">
          <img src="/address.jpg" alt="address" style={{ maxWidth: '100%' }} />
        </Col> */}
      </Row>

      <hr />
      <p>{t("user.UploadYourAddressProof")}</p>

      <Row>
        <Col>
          <FormText color="muted">{t("user.Address")}</FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({
                type: 'SET_ADDRESS_FRONT',
                addressFront: { file: e?.currentTarget?.files[0] }
              })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{addressFront?.error != null && addressFront.error}</span>
          <MobileGap />
        </Col>
      </Row>
    </>
  )
}

export default AddressUpload
