import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
  Badge,
  FormGroup,
  Label,
  Input,
  CardTitle,
  CardText
} from 'reactstrap'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import AccountService from '../../../network/services/account'
import PackageService from '../../../network/services/package'
import { useTranslation } from 'react-i18next'

import SweetAlert from 'sweetalert2'

import { useForm } from 'react-hook-form'

import './index.scss'

import ScrollDownIcon from './down-arrow.png'

import 'react-accessible-accordion/dist/fancy-example.css'

import GreenChecked from './checked.png'

function VePackageModal({
  account,
  accountDetails,
  vePremiumUpgradeModal,
  setVePremiumUpgradeModal
}) {
  const { t } = useTranslation()
  useEffect(() => {
    getAvailablePackages()
  }, [])

  // console.log('accountaccountaccount', account)
  const getAvailablePackages = async () => {
    const result = await PackageService.getAvailablePackages({
      language: 'chinese',
      package_type: 'premium',
      account_login: account.account_login
    })
    // console.log('resultresultresult', result)
    // const levels = [...new Set(result.packages.map((item) => item.level))]
    // console.log('levelslevels $', levels)
    // const filteredPackages = []
    // for (const level of levels) {
    //   const firstPackage = result.packages.find((item) => item.level == level)
    //   filteredPackages.push(firstPackage)
    // }

    setAllPackages(result.packages)
    setAvailablePackages(result.packages)
  }
  const [personalDetailsModal, setPersonalDetailsModal] = useState(false)
  const [packageSelect, setPackageSelect] = useState('')
  const [teacherSelect, setTeacherSelect] = useState('')
  const [applicateName, setApplicateName] = useState('')
  const [identity, setIdentity] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [availablePackages, setAvailablePackages] = useState([])
  const [allPackages, setAllPackages] = useState([])
  const userProfile = useSelector((state) => state.user.profile)

  const { register, handleSubmit, errors, formState } = useForm()

  const personalDetailsModalToggle = () => {
    setPersonalDetailsModal(!personalDetailsModal)
  }

  const [selectedPackage, setSelectedPackage] = useState(null)

  const handlePackageApplication = (item) => {
    setIsBusy(true)
    SweetAlert.fire({
      showClass: {
        popup: 'animate__animated animate__backInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__backOutDown'
      },
      title: 'Are you sure you want to upgrade package?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('financial.confirm'),
      cancelButtonText: t('financial.cancel'),
      reverseButtons: true
    }).then(async (result) => {
      setIsBusy(false)
      if (result.value) {
        try {
          let result = await AccountService.upgradePackage(accountDetails.id, {
            package_id: item.id,
            applicant_name: applicateName,
            identification_number: identity
          })

          if (result?.package_application?.id) {
            SweetAlert.fire({
              showClass: {
                popup: 'animate__animated animate__backInUp'
              },
              hideClass: {
                popup: 'animate__animated animate__backOutDown'
              },
              title: 'Applied Package Successfully',
              icon: 'success'
            }).then(() => {
              setIsBusy(false)
              setVePremiumUpgradeModal(!vePremiumUpgradeModal)
              window.location.reload()
            })
          }
        } catch (error) {
          setIsBusy(false)
          const errorCode = error.code
          const errorTranslate = error.translate_params
          console.log('errorCodeerrorCode', errorCode)
          console.log('errorTranslateerrorTranslateerrorTranslate', errorTranslate)

          SweetAlert.fire({
            showClass: {
              popup: 'animate__animated animate__backInUp'
            },
            hideClass: {
              popup: 'animate__animated animate__backOutDown'
            },
            title: t(errorCode, errorTranslate),
            icon: 'error'
          })
        }
      }
    })
  }

  const faqItems = [
    {
      heading: t('live.WhatBenefitsCanIget'),
      content: ''
    },
    {
      heading: t('live.CanIUpgradeMyStarterPackage'),
      content: t('live.YesIfYouDepositUSD')
    },
    {
      heading: t('live.IfMyExistingAccount'),
      content: t('live.NoInTheFuture')
    },
    {
      heading: t('live.DoesGoldenDiamondClub'),
      content: t('live.YesDifferentPackages')
    },
    {
      heading: t('live.IsItPossibleToReview'),
      content: t('live.AbsolutelyAllOfOur')
    },
    {
      heading: t('live.AreGoldenDiamondClubClasses'),
      content: t('live.WeHaveRecordedAndUploaded')
    }
  ]

  const checkIsPackageUnavailable = (vePackage) => {
    const accountLevel = accountDetails?.package?.level
    const accountPackageName = accountDetails?.package?.name
    if (accountLevel != null) {
      if (accountLevel == 3) {
        if (
          vePackage.level == 4 ||
          vePackage.name == accountPackageName ||
          accountLevel > vePackage.level
        ) {
          return true
        }
      } else if (accountLevel >= vePackage.level) {
        return true
      }
    }
    return false
  }

  return (
    <>
      {' '}
      {/* Control CSS display property  */}
      <Row style={{ justifyContent: 'center', paddingBottom: '20px' }}>
        <h2>{t('live.PremiumPackage')}</h2>
      </Row>
      <div
        className="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex"
        style={{ justifyContent: 'space-between', marginTop: '20px' }}
      >
        {availablePackages.map((item) => {
          return (
            <Col lg="3">
              <img
                style={{
                  width: '100%',
                  margin: '0 auto'
                }}
                src={item.image_url}
                alt={item.name}
              />
              <Card
                className="responsiveCardVePack premiumPackageCard"
                style={{
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                  borderRadius: '5px',
                  textAlign: 'center',
                  paddingBottom: '65px'
                }}
                // onClick={() => {
                //   setSelectedPackage(item)
                // }}
              
              >
                <div
                  style={{
                    padding: '1rem 0',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div style={{ margin: '0 auto' }}>
                      {item?.additional_display_content && (
                        <h5 style={{ fontWeight: 'bold' }}>{item?.additional_display_content}</h5>
                      )}
                      {item?.package_content?.split(',').map((benefit, index) => {
                        return (
                          <div style={{ display: 'flex' }} key={index}>
                            <div style={{ marginRight: '10px' }}>
                              <img
                                src={GreenChecked}
                                style={{ width: '16px' }}
                                alt="greenChecked"
                              />
                            </div>
                            <p style={{ marginBottom: '10px', fontSize: '14px', textAlign: 'left' }}>{t(benefit)}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <Button
                    style={{
                      width: '75%',
                      margin: '10px auto 15px auto'
                    }}
                    className="packageButton"
                    onClick={() => {
                      // handlePackageApplication(4)
                      setPackageSelect(item?.id)
                      setSelectedPackage(item)

                      if (item.is_audit_required == true) {
                        personalDetailsModalToggle(account)
                      } else {
                        handlePackageApplication(item)
                      }
                    }}
                    disabled={checkIsPackageUnavailable(item)}
                  >
                    {t('live.UpgradeTo')}
                    {item?.level == 2 ? item?.name.substring(0, item.name.length - 1) : item?.name}
                  </Button>
                </div>
              </Card>
            </Col>
          )
        })}
      </div>
      <Row
        style={{
          justifyContent: 'center',
          marginTop: '25px',
          marginBottom: '25px',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <p style={{ marginBottom: '10px', color: 'white' }}>{t('live.ScrollFor')}</p>
        <img src={ScrollDownIcon} alt="scroll down icon" style={{ width: '30px' }} />
      </Row>
      {/* <Row style={{ justifyContent: 'center', backgroundColor: '#F5F7FA', padding: '2rem 0rem' }}>
      <Col lg="8">
        <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>
          {t('live.FrequentlyAskedQuestions')}
        </h5>

        <Accordion allowZeroExpanded allowMultipleExpanded>
          {faqItems.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton style={{ backgroundColor: '#FFF' }}>
                  {item.heading}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {item.content}
                {item.heading === t('live.WhatBenefitsCanIget') && (
                  <a
                    href={
                      sessionStorage.getItem('language') === 'en'
                        ? 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+package-01.jpg'
                        : 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/CRM+package-01.jpg'
                    }
                    target="_blank"
                  >
                    {t('live.ClickForMoreDetails')}
                  </a>
                )}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Col>
    </Row> */}
      {/* Modal for personal details  */}
      <Modal
        // id="upgrade-live-modal"
        isOpen={account.id === accountDetails?.id && personalDetailsModal}
        toggle={personalDetailsModalToggle}
        centered
      >
        <ModalHeader toggle={personalDetailsModalToggle} centered>
          {t('live.FillInYourDetails')}
        </ModalHeader>
        <ModalBody>
          {selectedPackage?.level == 2 && (
            <>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t('live.SelectOneTeacher')}
                  <span style={{ color: 'red' }}>*</span>
                  <br />
                </Label>
                <Input
                  type="select"
                  name="select"
                  onChange={(e) => {
                    setPackageSelect(e.target.value)
                    setTeacherSelect(e.target.value)
                  }}
                  // defaultValue={packageSelect}
                  placeholder="Select Teacher"
                >
                  <option value={''}>Select at least one</option>
                  <option value={allPackages.find((item) => item.name == 'TTTS')?.id}>Simmi</option>
                  <option value={allPackages.find((item) => item.name == 'TTTV')?.id}>
                    Vernon
                  </option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  Account Balance Require
                  <span style={{ color: 'red' }}>*</span>
                  <br />
                </Label>
                <Input readOnly type="text" name="select" defaultValue={'5000 USD'}>
                  5000 USD
                </Input>
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Label className="col-form-label pt-0">
              {t('live.ApplicantName')}
              <span style={{ color: 'red' }}>*</span>
              <br />
              <span style={{ fontSize: '12px' }}>{t('live.EnterYourNameAccording')}</span>
            </Label>
            <Input
              className="form-control"
              type="text"
              name="identification_number"
              innerRef={register({ required: true })}
              defaultValue=""
              onChange={(e) => {
                setApplicateName(e.target.value)
              }}
              placeholder="e.g. JohnSon"
            ></Input>
            <span style={{ color: 'red' }}>{errors.identity && t('user.IdentityIsRequired')}</span>
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label pt-0">
              {t('user.IdentityCardPassportNumber')}
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <Input
              className="form-control"
              type="text"
              name="identification_number"
              innerRef={register({ required: true })}
              defaultValue=""
              onChange={(e) => {
                setIdentity(e.target.value)
              }}
              placeholder="e.g. 901111012345"
            ></Input>
            <span style={{ color: 'red' }}>{errors.identity && t('user.IdentityIsRequired')}</span>
          </FormGroup>

          <p style={{ color: 'red' }}>
            {selectedPackage?.level == 1 && t('live.Reminder400')}
            {selectedPackage?.level == 3 && t('live.Reminder2000')}
            {selectedPackage?.level == 4 && t('live.Reminder4000')}
          </p>

          <a
            href="https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/NDA+-+en.pdf"
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {t('live.ReadTermsConditions')}
          </a>
          <p>{t('live.ThePurposeOfThisAction')}</p>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handlePackageApplication(selectedPackage)}
              disabled={applicateName === '' || identity === '' || isBusy}
              color="primary"
              style={{ float: 'right' }}
            >
              {t('demo.submit')}
            </Button>
            {isBusy && <Spinner />}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default VePackageModal
