import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Button,
    Navbar,
    NavbarText,
    CardBody,
    Tooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Container
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'


import Breadcrumb from '../../../layout/breadcrumb'
import PammManagerStatistic from '../../../network/services/pammManagerStatistic'

import VerificationIcon from '../../../assets/images/Verification_icon.png'

import './pammStats.scss'

import GrowthLineGraph from './growthLineGraph'
import GrowthGraph from './growthGraph'
import TradeStatistic from './tradeRecordTable'
import GrowthStatistic from './growthTable'
import moment from 'moment'
import _ from 'lodash'
import './pammManagerStatistic.css'
import UserService from '../../../network/services/user'
import AccountService from '../../../network/services/account'

import { useTranslation } from 'react-i18next'

import { HelpCircle } from 'react-feather'

import "intro.js/introjs.css";
import { Steps, Hints } from 'intro.js-react';
import arrow from "./tick.svg"

const Statistic = () => {
    const { t } = useTranslation()
    const { id } = useParams()

    const dispatch = useDispatch()

    const [pammManagerDetails, setPammManagerDetails] = useState([])
    const [pammManagerTrades, setPammManagerTrades] = useState([])
    const [teamId, setTeamId] = useState(null)
    const [partnerTeamIds, setPartnerTeamIds] = useState([])
    const [pammManagerTeamIds, setPammManagerTeamIds] = useState([])


    // const [settlementTooltipOpen, setSettleTooltipOpen] = useState(false);
    // const settlementToggle = () => setSettleTooltipOpen(!settlementTooltipOpen);

    const [penalthyTooltipOpen, setPenalthyTooltipOpen] = useState(false);
    const penalthyToggle = () => setPenalthyTooltipOpen(!penalthyTooltipOpen);

    const [nextSettlementTooltipOpen, setNextSettlementTooltipOpen] = useState(false);
    const nextSettlementToggle = () => setNextSettlementTooltipOpen(!nextSettlementTooltipOpen);

    const [managementFeeTooltipOpen, setManagementFeeTooltipOpen] = useState(false);
    const managementFeeToggle = () => setManagementFeeTooltipOpen(!managementFeeTooltipOpen);

    let [dataTotalGrowth, setDataTotalGrowth] = useState(0)

    //Nav bar toggle
    const [nav_Graph, setNav_Graph] = useState('')

    const [onClickMonth, setOnClickMonth] = useState(2021)
    const [isLoading, setIsLoading] = useState(false)

    const [showModal, setShowModal] = useState(false)

    let totalGrowth = 0

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const init = async () => {
        try {

            const accountResponse = await AccountService.getAll()

            dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

        } catch (error) {
            return ''

        }

    }

    const vePackageAccounts = useSelector((state) => {
        if (state.account.accounts?.length > 0) {
            return state.account.accounts.filter((e) => (e.package_id === 4 || e.package_id === 5 || e.package_id === 6))
        } else if (state.account.accounts?.length === 0) {
            return []
        } else {
            return null
        }
    })


    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        const search = async () => {
            let data = []
            totalGrowth = 0
            setIsLoading(true)
            const result = await PammManagerStatistic.get(id)
            const userInfo = await UserService.getMyself()
            setTeamId(userInfo.user.referred_by?.parent_user?.partner?.team_id ?? null)

            if (userInfo.partners.length > 0) {
                const partnerTeamArray = []
                await userInfo.partners.forEach(item => partnerTeamArray.push(item.team_id))
                setPartnerTeamIds(partnerTeamArray)
            }

            if (result.details.pammManagerTeams.length > 0) {

                const pammManagerTeamArray = []
                await result.details.pammManagerTeams.forEach(item => pammManagerTeamArray.push(item.team_id))
                setPammManagerTeamIds(pammManagerTeamArray)
            }

            let CalculateResult = result?.details?.performances?.map(performance => {
                // console.log('result?.details?.performances',result?.details?.performances)
                data.push({
                    "x": performance?.id,
                    "y": totalGrowth + performance?.monthly_growth
                })
                totalGrowth += performance?.monthly_growth
            })

            setDataTotalGrowth(totalGrowth)
            setPammManagerDetails(result?.details)
            setPammManagerTrades(result?.details.statistics)
        }

        search()
    }, [])

    const [stepsEnabled, setStepsEnabled] = useState(true)
    const steps = [
        {
            element: '#first-step',
            intro: t('intro.pammManagerStatistic.step1'),
        },
        {
            element: '#pamm-stats',
            intro: t('intro.pammManagerStatistic.step2'),
        },
        {
            element: '#nextSettlement',
            intro: t('intro.pammManagerStatistic.step3'),
        },
        {
            element: '#LineGraph',
            intro: t('intro.pammManagerStatistic.step4'),
        },
        {
            element: '#NavBar',
            intro: t('intro.pammManagerStatistic.step5'),
        }
        ,
        {
            element: '#follow-button',
            intro: t('intro.pammManagerStatistic.step6'),
        }

    ]

    // get the Date without day
    const getDate = moment(pammManagerDetails.created_at).format("YYYY-MM-DD")

    let settlementDate
    let settlementDateTomorrow1
    let settlementDateTomorrow2

    if (pammManagerDetails.settlement_period == "Monthly") {
        if (moment().format('YYYY-MM-DD') != moment().startOf('month').format('YYYY-MM-DD')) {
            settlementDate = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
            settlementDateTomorrow1 = moment().add(1, 'M').startOf('month').add(1, 'days').format('YYYY-MM-DD')
            settlementDateTomorrow2 = moment().add(1, 'M').startOf('month').add(2, 'days').format('YYYY-MM-DD')
        } else {
            settlementDate = moment().startOf('month').format('YYYY-MM-DD')
            settlementDateTomorrow1 = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD')
            settlementDateTomorrow2 = moment().startOf('month').add(2, 'days').format('YYYY-MM-DD')
        }
    } else {
        settlementDate = moment(pammManagerDetails.settlement_date).format("YYYY-MM-DD")
        settlementDateTomorrow1 = moment(pammManagerDetails.settlement_date).add(1, 'days').format('YYYY-MM-DD')
        settlementDateTomorrow2 = moment(pammManagerDetails.settlement_date).add(2, 'days').format('YYYY-MM-DD')
    }


    const followButton = (user) => {
        if (pammManagerTeamIds.length > 0) {

            if (vePackageAccounts?.length > 0) {
                return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/subscribe`}><Button id='follow-button' style={{ padding: '5px 10px', marginTop: '25px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }

            if (pammManagerTeamIds.includes(teamId) || partnerTeamIds.some(partnerTeamId => pammManagerTeamIds.includes(partnerTeamId))) {
                return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/subscribe`}><Button id='follow-button' disabled={user.is_follow_disabled} style={{ padding: '0.375rem 1.75rem', marginTop: '25px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }
            if (user.is_follow_disabled) {
                return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/subscribe`}><Button id='follow-button' disabled={user.is_follow_disabled} style={{ padding: '0.375rem 1.75rem', marginTop: '25px' }} color="primary">{t("pamm.follow")}</Button></Link>
            }
            if (user?.email) {
                return <div id='follow-button'><a href={`mailto:${user?.email}?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '0.5rem 1.75rem', marginTop: '25px' }} className="btn-primary">{t("pamm.contactPamm")}</a></div>
            } else {
                return <div id='follow-button'><a href={`mailto:info@ve.education?subject=Request to subscribe PAMM ${user.name}`} style={{ padding: '0.5rem 1.75rem', marginTop: '25px' }} className="btn-primary">{t("pamm.contactAdmin")}</a></div>
            }
        }
        return <Link to={`${process.env.PUBLIC_URL}/veFund/${user.id}/subscribe`}><Button id='follow-button' disabled={user.is_follow_disabled} style={{ padding: '0.375rem 1.75rem', marginTop: '25px' }} color="primary">{t("pamm.follow")}</Button></Link>
    }

    const displayGraph = () => {
        if (nav_Graph === "Performance" || nav_Graph === "表现" || nav_Graph === "") {
            return (
                <Col lg="12" style={{ padding: '0' }}>
                    <GrowthGraph />

                    <GrowthStatistic onClickChangeState={e => { setOnClickMonth(parseInt(e.target.textContent)) }} />

                </Col>
            )
        }
        else if (nav_Graph === "Statistics" || nav_Graph === "统计数据") {
            return (<Row style={{ margin: '0', flexDirection: 'row' }}>
                <Col lg="6" >
                    <p className="mt-3 mb-4">{t("pamm.startDate")} <span style={{ color: '#828282' }}>{getDate}</span></p>
                    <p className="mt-3 mb-4">{t("pamm.profitTradesCount")} <span style={{ color: '#828282' }}>{pammManagerTrades.profit_trades_count}</span></p>
                    <p className="mt-3 mb-4">{t("pamm.profitTradesPercentage")} <span style={{ color: '#828282' }}>{pammManagerTrades.profit_trades_percentage}%</span></p>
                    <p className="mt-3 mb-4">{t("pamm.bestProfit")} <span style={{ color: '#828282' }}>{formatter.format(pammManagerTrades.best_profit)} </span></p>
                    <p className="mt-3 mb-4">{t("pamm.totalTrades")}  <span style={{ color: '#828282' }}>{pammManagerTrades.total_trades}</span></p>
                    <p className="mt-3 mb-2">{t("pamm.longTrades")} <span style={{ color: '#828282' }}>{pammManagerTrades.long_trades}</span></p>
                </Col>
                <Col lg="6">
                    <p className="mt-3 mb-4">{t("pamm.lossTradesCount")} <span style={{ color: '#828282' }}>{pammManagerTrades.loss_trades_count}</span></p>
                    <p className="mt-3 mb-4">{t("pamm.lossTradesPercentage")} <span style={{ color: '#828282' }}>{pammManagerTrades.loss_trades_percentage}%</span></p>
                    <p className="mt-3 mb-4">{t("pamm.worstLoss")}  <span style={{ color: '#828282' }}>{formatter.format(pammManagerTrades.worst_loss)} </span></p>
                    <p className="mt-3 mb-4">{t("pamm.drawdown")} <span style={{ color: '#828282' }}>{pammManagerTrades.max_drawdown}%</span></p>
                    <p className="mt-3">{t("pamm.shortTrades")} <span style={{ color: '#828282' }}>{pammManagerTrades.short_trades}</span></p>
                </Col>

            </Row>)
        }
        else if (nav_Graph === "Trade Record" || nav_Graph === "交易记录") {
            return (
                <TradeStatistic />
            )
        }
        else if (nav_Graph === "Description" || nav_Graph === "说明") {
            return (
                <Col lg="12">
                    <p className="mt-3 mb-2">{t("pamm.description")} </p>
                    <CardBody style={{ height: '50%', alignItems: 'stretch', borderRadius: '5px', padding: '0px' }}>
                        <p style={{ color: '#828282', whiteSpace: 'pre-wrap' }}>{pammManagerDetails.description}</p>
                    </CardBody>
                </Col>
            )
        }

        else {
            return ""
        }
    }

    return (
        <Container className='mb-5'>
            <Breadcrumb parent="" title={t("pamm.pammManagerStatistics")} ><Link to="/veFund">VE Fund</Link> &nbsp; / {pammManagerDetails.name}</Breadcrumb>
            <Container>
                <Row style={{ width: '100%', maxWidth: '1280px', margin: '0px' }}>
                    <Col lg='4' style={{ borderRadius: '20px', marginBottom: '15px', color: 'black', boxShadow: '0px 4px 20px #0000000D' }}>

                        <div id='pamm-stats' className="flex-direction-mobile" style={{ display: 'flex', backgroundColor: 'white', padding: '20px 10px 10px 10px', borderRadius: '15px' }}>
                            <Col style={{ textAlign: 'center' }}>

                                <img style={{ borderRadius: '20px', marginBottom: "0.7rem" }} width="80px" height="80px" src={pammManagerDetails.avatar !== "" ? pammManagerDetails.avatar : `https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/assets/User+photo-05.jpg`} />


                                <p id="pamm-sub-name" style={{ fontWeight: '500', marginBottom: '0.5rem' }}>{pammManagerDetails.name}</p>


                                {
                                    pammManagerTrades.fund_size ?
                                        <p id='pamm-text' style={{ fontWeight: '500', color: '#9099A3' }}>{t("pamm.totalFundSize")}: <span style={{ fontWeight: '400' }}>{pammManagerTrades.fund_size}++</span></p> :

                                        ''
                                }


                                {/* {followButton(pammManagerDetails)} */}

                                <Col lg='12' id='col-line-graph' className='d-none d-lg-block d-xl-block d-md-block'>
                                    <Row id="LineGraph" style={{ height: '360px', backgroundColor: 'white', borderRadius: '15px' }}>

                                        <div style={{ height: '238px', width: '100%', margin: '0px' }}>
                                            <GrowthLineGraph id={id} />
                                        </div>
                                    </Row>
                                </Col>

                                {/*mobile sized */}
                                <Col lg='12' id='col-line-graph' className='d-block d-xl-none d-md-none d-lg-none'>
                                    <Row id="LineGraph" style={{ height: '360px', marginLeft: '-25px', marginRight: '-25px', backgroundColor: 'white', borderRadius: '15px' }}>

                                        <div style={{ height: '238px', width: '100%', margin: '0px' }}>
                                            <GrowthLineGraph id={id} />
                                        </div>
                                    </Row>
                                </Col>

                                <Card style={{ boxShadow: '0px 7px 20px #23232314', padding: '10px', margin: '-25px' }}>
                                    {pammManagerDetails.performance_fee !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.performanceFee")}
                                            </p>
                                            <p id="pamm-text" >{pammManagerDetails.performance_fee}%</p>
                                        </div>
                                        : ''}

                                    {pammManagerDetails.management_fee !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.managementFee")}
                                            </p>
                                            <div style={{ display: 'flex' }}>
                                                <p id="pamm-text">{pammManagerDetails.management_fee}% {pammManagerDetails.name === 'VE Capital Grand' && ' - Yearly'}</p>
                                                <HelpCircle id="managementFee" style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                        : ''}

                                    {pammManagerDetails.flat_fee !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.flatFee")}
                                            </p>
                                            <p id="pamm-text">{formatter.format(pammManagerDetails.flat_fee)}</p>
                                        </div>
                                        : ''}

                                    {pammManagerDetails.settlement_period !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.settlementPeriod")}
                                            </p>
                                            <p id="pamm-text">{pammManagerDetails.settlement_period}</p>
                                        </div>
                                        : ''}


                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                            {t("pamm.settlementDate")}
                                        </p>
                                        <div style={{ display: 'flex' }}>
                                            <p id="pamm-text">{settlementDate} (GMT +0)</p>
                                            {/* <HelpCircle id="nextSettlement" style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} /> */}
                                            <HelpCircle onMouseEnter={() => { toggleModal() }} onClick={() => { toggleModal() }} style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />

                                            <Modal isOpen={showModal} centered={true}>
                                                <ModalHeader toggle={toggleModal}>{t("pamm.PleaseTakeNote")}</ModalHeader>
                                                <ModalBody>
                                                    <p>1. {t("pamm.IfYouDecide")} <strong>{t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.BesideSettlement")} <span style={{ color: 'red', fontWeight: 'bold' }}>{t("pamm.deduct")} {pammManagerDetails.penalty_percent}%</span> {t("pamm.fromYourFund")}</p>
                                                    <p>2. {t("pamm.ThisSettlementWillHappenOn")} {settlementDate} (GMT +0) 00:00:00</p>
                                                    3. {t("pamm.TheInvestorIsNotAllowed")}
                                                    <br /><br />
                                                    {t("pamm.TheInvestorWillBeAssigned")} <span style={{ color: 'green', fontWeight: 'bold' }}>{t("pamm.afterTheSettlementDate")}</span> {t("pamm.AsBufferPeriod")}
                                                    <br /><br />
                                                    {t("pamm.ForExample")}
                                                    {t("pamm.IfTheSettlementDateIsOn")} <span style={{ fontWeight: 'bold' }}>{settlementDate} (GMT +0) 00:00:00</span>{t("pamm.theInvestorIsNotAllowed")} <span style={{ fontWeight: 'bold' }}>{settlementDateTomorrow1} (GMT +0) 00:00:00</span> {t("pamm.to")} <span style={{ fontWeight: 'bold' }}>{settlementDateTomorrow2} (GMT +0) 23:59:59</span> {t("pamm.WithoutAnyPenalty")}
                                                    <br />
                                                    <br />
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button onClick={() => { toggleModal() }} style={{ margin: '0 auto' }}>Understand</Button>
                                                    </div>
                                                </ModalBody>
                                            </Modal>
                                        </div>
                                    </div>

                                    {pammManagerDetails.totalFollowers !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.subscribers")}
                                            </p>
                                            <p id="pamm-text">{pammManagerDetails.totalFollowers} {t("pamm.followers")}</p>
                                        </div>
                                        : ''}

                                    {pammManagerDetails.penalty_percent !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.penalty")}
                                            </p>
                                            <div style={{ display: 'flex' }}>
                                                <p id="pamm-text">{pammManagerDetails.penalty_percent}% </p>
                                                <HelpCircle id="penalthy" style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                        : ''}

                                    {pammManagerDetails.min_amount !== null ?
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <p id="pamm-text" style={{ fontWeight: '500', color: '#9099A3' }} className=" mb-2">
                                                {t("pamm.minSubscription")}
                                            </p>

                                            <p id="pamm-text">{formatter.format(pammManagerDetails.min_amount)}</p>

                                        </div>
                                        : ''}

                                </Card>

                                {/* {pammManagerDetails.management_fee !== null ? <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-3 mb-2">{t("pamm.managementFee")} {pammManagerDetails.management_fee}%</p> : ''} */}
                                {/* {pammManagerDetails.flat_fee !== null ? <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-3 mb-2">{t("pamm.flatFee")} {pammManagerDetails.flat_fee}USD</p> : ''} */}
                                {/* <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-3 mb-2">{t("pamm.settlementPeriod")} {pammManagerDetails.settlement_period}</p> */}
                                {/* <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-2 mb-2">{t("pamm.settlementDate")} {settlementDate} (GMT +0)
                                                    <HelpCircle id="nextSettlement" style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />

                                </p> */}

                                {/* <Tooltip id='settlementTooltip' style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }} isOpen={nextSettlementTooltipOpen} target="nextSettlement" toggle={nextSettlementToggle}>

                                    <h5>{t("pamm.PleaseTakeNote")}</h5>
                                    <p>1. {t("pamm.IfYouDecide")} <strong>{t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.BesideSettlement")} <span style={{ color: 'red', fontWeight: 'bold' }}>{t("pamm.deduct")} {pammManagerDetails.penalty_percent}%</span> {t("pamm.fromYourFund")}</p>
                                    <p>2. {t("pamm.ThisSettlementWillHappenOn")} {settlementDate} (GMT +0) 00:00:00</p>
                                                                    3. {t("pamm.TheInvestorIsNotAllowed")}
                                    <br /><br />
                                    {t("pamm.TheInvestorWillBeAssigned")} <span style={{ color: 'green', fontWeight: 'bold' }}>{t("pamm.afterTheSettlementDate")}</span> {t("pamm.AsBufferPeriod")}
                                    <br /><br />
                                    {t("pamm.ForExample")}
                                    {t("pamm.IfTheSettlementDateIsOn")} <span style={{ fontWeight: 'bold' }}>{settlementDate} (GMT +0) 00:00:00</span>{t("pamm.theInvestorIsNotAllowed")} <span style={{ fontWeight: 'bold' }}>{settlementDateTomorrow1} (GMT +0) 00:00:00</span> {t("pamm.to")} <span style={{ fontWeight: 'bold' }}>{settlementDateTomorrow2} (GMT +0) 23:59:59</span> {t("pamm.WithoutAnyPenalty")}
                                </Tooltip> */}

                                <Tooltip id='managementFeeTooltip' style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }} isOpen={managementFeeTooltipOpen} target="managementFee" toggle={managementFeeToggle}>

                                    <h5>{t("pamm.PleaseTakeNote")}</h5>
                                    <p>{t('pamm.managementFeeDescription')}</p>
                                </Tooltip>


                                {/* <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-3 mb-2">{t("pamm.startDate")}  {getDate}</p> */}
                                {/* <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-3 mb-2">{t("pamm.subscribers")} {pammManagerDetails.totalFollowers} {t("pamm.followers")}</p>
                                <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-2 mb-2">{t("pamm.penalty")} {pammManagerDetails.penalty_percent}%
                                                    <HelpCircle id="penalthy" style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />
                                </p> */}
                                <Tooltip placement='bottom-start' style={{ textAlign: 'left', backgroundColor: '#f7f7f7', color: 'black' }} isOpen={penalthyTooltipOpen} target="penalthy" toggle={penalthyToggle}>
                                    <h5>{t("pamm.PleaseTakeNote")}</h5>
                                    <p>1. {t("pamm.ThePenaltyWillHappen")} <strong> {t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.Beside")}</p><br />
                                    <p>2. {t("pamm.IfYouDecide")} <strong>{t("pamm.withdraw")}</strong> {t("pamm.or")} <strong>{t("pamm.unsubscribe")}</strong> {t("pamm.BesideSettlement")} <span style={{ color: 'red' }}>{t("pamm.deduct")} {pammManagerDetails.penalty_percent}%</span> {t("pamm.fromYourFund")}</p>
                                </Tooltip>
                                {/* <p style={{ fontSize: '16px', marginBottom: "1rem" }} className="mt-3 mb-2">{t("pamm.minSubscription")} {pammManagerDetails.min_amount}USD</p> */}

                            </Col>
                        </div>

                        {followButton(pammManagerDetails)}







                    </Col>




                    <Col
                        lg='8'
                        style={{
                            margin: '0px',
                            padding: '20px 0px',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            backgroundColor: 'white',
                            borderRadius: '20px',
                            color: 'black',
                            boxShadow: '0px 4px 20px #0000000D'
                        }}

                    >
                        {/*deskstop size */}
                        <Col className='d-none d-lg-block ' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                            <Navbar id='NavBar' className="mobile-font-size" expand="md" style={{ padding: '0 10px', justifyContent: 'flex-start' }}>

                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === 'Performance'}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText className={`${nav_Graph === 'Performance' || nav_Graph === "" ? 'isActive' : ''} mr-3 ml-3`}>
                                        {t("pamm.pammPerformance")}
                                    </NavbarText>
                                </div>


                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.statistics")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText className={`${nav_Graph === t("pamm.statistics") ? 'isActive' : ''} mr-3 ml-3`}>
                                        {t("pamm.statistics")}
                                    </NavbarText>
                                </div>

                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.tradeRecord")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText
                                        className={`${nav_Graph === t("pamm.tradeRecord") ? 'isActive' : ''} mr-3 ml-3`}
                                    >
                                        {t("pamm.tradeRecord")}
                                    </NavbarText>
                                </div>

                                <div style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.description")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    <NavbarText
                                        className={`${nav_Graph === t("pamm.description") ? 'isActive' : ''} mr-3 ml-3`}
                                    >
                                        {t("pamm.description")}
                                    </NavbarText>
                                </div>

                            </Navbar>
                        </Col>
                        {/*mobile size */}
                        <Col className='d-block d-lg-none' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                            <ul style={{ padding: '0 10px', justifyContent: 'flex-start' }}>

                                <li style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.pammPerformance")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    {nav_Graph === t("pamm.pammPerformance") || nav_Graph === "" ? <img src={arrow} /> : ""}
                                    <NavbarText className='mr-3 ml-3'>
                                        {t("pamm.pammPerformance")}
                                    </NavbarText>
                                </li>


                                <li style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.statistics")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    {nav_Graph === t("pamm.statistics") ? <img src={arrow} /> : ""}
                                    <NavbarText className='mr-3 ml-3'>
                                        {t("pamm.statistics")}
                                    </NavbarText>
                                </li>

                                <li style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.tradeRecord")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                    {nav_Graph === t("pamm.tradeRecord") ? <img src={arrow} /> : ""}
                                    <NavbarText
                                       className='mr-3 ml-3'
                                    >
                                        {t("pamm.tradeRecord")}
                                    </NavbarText>
                                </li>

                                <li style={{ cursor: 'pointer' }} className={`${nav_Graph === t("pamm.description")}`} onClick={(e) => setNav_Graph(e.currentTarget.textContent)}>
                                {nav_Graph === t("pamm.description") ? <img src={arrow} /> : ""}
                                    <NavbarText
                                        className='mr-3 ml-3'
                                    >
                                        {t("pamm.description")}
                                    </NavbarText>
                                </li>

                            </ul>
                        </Col>
                        <div className='mt-2' style={{ borderBottom: '3px solid #f7f7f7' }} />
                        <Col id='displayGraph' >
                            <CardBody style={{ border: 'none', borderRadius: '0', padding: '10px' }}>
                                {displayGraph()}
                            </CardBody>
                        </Col>

                    </Col>

                </Row>

            </Container>


            <div style={{ padding: '20px' }} />
            {/* { localStorage.getItem('pamm-stats-onboarding') === null &&
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={0}
                    onExit={() => {
                        localStorage.setItem('pamm-stats-onboarding', true)
                        setStepsEnabled(false)
                    }}
                    options={
                        {
                            showProgress: true,
                            doneLabel: 'Understand',
                            exitOnOverlayClick: false
                        }
                    }
                />
            } */}
        </Container>
    )
}

export default Statistic
