import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import AccountService from '../../../network/services/account'
import { useTranslation } from 'react-i18next'

const MonitoringOpenTable = () => {
  const { t } = useTranslation()
  const columns = [
    {
      name: t("monitoring.symbol"),
      selector: 'Symbol',
      sortable: true
    },
    {
      name: t("monitoring.ticket"),
      selector: 'Position',
      sortable: true
    },
    {
      name: t("monitoring.time"),
      selector: 'TimeUpdate',
      sortable: true,
      width: '200px',
      format: (row) => {
        return moment.unix(row.TimeUpdate).format('yyyy-MM-DD hh:mmA')
      }
    },
    {
      name: t("monitoring.volume"),
      selector: 'Volume',
      sortable: true,
      format: (row) => {
        return Number.parseInt(row.Volume) / 10000
      }
    },
    {
      name: t("monitoring.priceClose"),
      selector: 'PriceOpen',
      sortable: true
    },
    {
      name: 'S / L',
      selector: 'PriceSL',
      sortable: true
    },
    {
      name: 'T / P',
      selector: 'PriceTP',
      sortable: true
    },
    {
      name: t("monitoring.priceCurrent"),
      selector: 'PriceCurrent',
      sortable: true
    },
    {
      name: t("monitoring.swap"),
      selector: 'Storage',
      sortable: true
    },
    {
      name: t("monitoring.profit"),
      selector: 'Profit',
      sortable: true
    }
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)
  const selectedAccount = useSelector((state) => state.monitoring.account)

  const fetchOpenOrders = async () => {
    try {
      if (selectedAccount != null) {
        setIsBusy(true)
        const result = await AccountService.getOpenOrders(selectedAccount.id, { limit: 10 })

        setTotal(result.count)
        setData(result.orders)
        setIsBusy(false)
      }
    } catch (error) {
      console.log(error)
      setIsBusy(false)
    }
  }

  const handlePageChange = async (page) => {
    setIsBusy(true)
    // console.log(page)
    const result = await AccountService.getOpenOrders(selectedAccount.id, {
      page: page,
      limit: 10
    })
    // console.log(result)
    setData(result?.orders ?? [])
    setIsBusy(false)
  }

  useEffect(() => {
    fetchOpenOrders()
    // eslint-disable-next-line
  }, [selectedAccount])

  return (
    <>
      <h3>{t("monitoring.openOrders")}</h3>
      {data != null ? (
        data.length > 0 ? (
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            progressPending={isBusy}
          />
        ) : (
          <h6>{t("monitoring.noRecordsFound")}</h6>
        )
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      )}
    </>
  )
}

export default MonitoringOpenTable
