import React from 'react'

import {
    Container
} from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import Courses from './allCourses/index'

import { useTranslation } from 'react-i18next'

const Course = () => {

    const { t } = useTranslation()
    // const [showGraph, setShowGraph] = useState(true)

    return <>
        <Breadcrumb parent={t("courses.Courses")} title="Courses"></Breadcrumb>
        <Container style={{ paddingBottom: 40 }}>
            <Courses />
        </Container>

    </>
}

export default Course