import React from 'react'

import {
    Container
} from 'reactstrap'

import Breadcrumb from '../../../../layout/breadcrumb'


import { useTranslation } from 'react-i18next'

const PurchasePPTModule = () => {

    const { t } = useTranslation()
    // const [showGraph, setShowGraph] = useState(true)

    return <>
        <Breadcrumb parent={'Purchase PPT Module'} title="Purchase PPT Module"></Breadcrumb>
       

    </>
}

export default PurchasePPTModule