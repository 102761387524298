import React, { useState } from 'react'
import {
  Container, Button, Input, Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row, Col
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import ActiveLiveAccounts from './accounts'
import VeSyncThService from '../../../network/services/syncth'
import UserService from '../../../network/services/user'


import SweetAlert from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import './index.css'

const LiveAccounts = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  // const verification = useSelector((state) => _.last(state.user.verifications))
  const user = useSelector((state) => state.user.user)
  const [errors, setErrors] = useState({ verification: null, twoFA: null })
  const [syncEmail, setSyncEmail] = useState('')

  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const [isSendCode, setIsSendCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [authCode, setAuthCode] = useState('')




  const sendAuthCode = async () => {
    if (syncEmail === '') {
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: "Please fill in your email correctly",
        icon: 'error'
      })
    } else {
      try {
        setIsSendCode(true)
        let result = await VeSyncThService.sendCode({ email: syncEmail })

      } catch (error) {
        console.log(error)
        setIsLoading(false)
        setIsSendCode(false)
        const errorCode = error.code
        const errorTranslate = error.translate_params

        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: t(errorCode, errorTranslate),
          icon: 'error'
        })
      }
    }
  }
  const syncThAccount = async () => {
    try {

      let result = await VeSyncThService.verifyAccount({ email: syncEmail, code: authCode })


      if (result.result.success) {
        const updatedUser = await UserService.getMyself()
        dispatch({ type: 'SET_USER', user: updatedUser.user })
        setIsLoading(true)
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: 'Account synced successfully!',

          icon: 'success'
        }).then(async () => {
          // let accountResult = await AccountService.getLiveAndIbAccount()
          // setAccounts(accountResult.accounts)
          // setIsLoading(false)
          // toggleModal()
          window.location.reload();
        })
      }

    } catch (error) {
      console.log(error)
      setIsLoading(false)
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }


  return (
    <>

      {user.is_th_sync == true ? <Container>

        <ActiveLiveAccounts />

      </Container> :
        <>
          <Row className="syncCard">
            <Col lg='12'>
              <h3>{t('dashboard.SyncAccount')}</h3>
              <p>{t('dashboard.unclockTrade')}</p>
              <Button
                color='white'
                style={{ borderRadius: '14px', backgroundColor: 'white', fontSize: '16px', height: '40px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                onClick={() => {
                  setShowModal(true)
                }}

              >
                {t('dashboard.Sync')}
              </Button>
            </Col>


          </Row>

          {showModal && (
            <Modal isOpen={showModal} backdrop={true} centered={true}>
              <ModalHeader toggle={toggleModal}>{t('dashboard.SyncTradehall')}</ModalHeader>
              <ModalBody>
                <div>
                  <Label className='mb-0'>{t('dashboard.TradehallEmail')}</Label>
                  <Input
                    className="form-control"
                    placeholder="e.g. abcd123@gmail.com"
                    required
                    type='email'
                    onChange={(e) => {
                      setSyncEmail(e.target.value)
                    }}
                  />
                  {isSendCode == false && <small>{t('dashboard.HaveNoTradehallAcc')} <a href="https://secure.tradehallglobal.co/sign_up/" target='_blank' >{t('dashboard.Register')}</a> {t('dashboard.TradehallAcc')}</small>}

                </div>
                <Label className='mt-3'></Label>
                <div className='d-flex'>
                  <Input
                    className="form-control w-50  mr-2"
                    placeholder={t('dashboard.VerificationCode')}
                    required
                    onChange={(e) => {
                      setAuthCode(e.target.value)
                    }}
                  />
                  <Button
                    disabled={isSendCode == true}
                    style={{ borderRadius: '14px', fontSize: '16px', height: '40px' }}
                    onClick={() => {
                      sendAuthCode()
                    }}
                  >
                    {t('dashboard.SendCode')}
                  </Button>
                </div>

                {isSendCode == true && <p className="mb-2 text-success">Kindly check your email / junk mail for verification code</p>}

                <span style={{ color: 'red' }}>{errors?.twoFA}</span>
                <Button
                  disabled={authCode.length < 6}
                  className="mt-3 pull-right"
                  onClick={() => {
                    syncThAccount()
                  }}
                >
                  {t("user.Submit")}
                </Button>
              </ModalBody>
            </Modal>
          )}
        </>
      }


    </>
  )
}

export default LiveAccounts
