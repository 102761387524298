import React, { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Collapse,
    Button,
    Progress,
    Container,
    Badge,
    Input
} from 'reactstrap'
import parse from 'html-react-parser'
import {
    BrowserRouter as Router,
    useParams,
    useHistory
} from "react-router-dom";

import { useDispatch } from 'react-redux'

import CourseService from '../../../network/services/courses'
import QuizService from '../../../network/services/quiz'


import { useTranslation } from 'react-i18next'
import SweetAlert from 'sweetalert2'

import "./index.scss";
import "./course.css";


import {
    Video,
    ChevronDown,
    ChevronUp,
    Book,
    FileText, CheckCircle,
    AlertCircle

} from 'react-feather'

import ReactGA from 'react-ga'


const PammManager = (props) => {

    const history = useHistory();
    const dispatch = useDispatch()
    const { id } = useParams()

    const { t } = useTranslation()


    const [allChapter, setAllChapter] = useState([])

    const [courseResult, setCourseResult] = useState({})

    const [description, setDescription] = useState([])

    const [isCollapse, setIsCollapse] = useState(false)
    const [isChapterOpenId, setIsChapterOpenId] = useState('')
    const toggleCollapse = (chapter) => {
        setIsChapterOpenId(chapter?.id)
        setIsCollapse(true)

    };

    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
    }

    const [selectedVideoIndex, setSelectedVideoIndex] = useState('')
    const [video, setVideo] = useState('')
    const [videoLink, setVideoLink] = useState('')
    const [videoID, setVideoID] = useState('')
    const [selectedLesson, setSelectedLesson] = useState(null)

    const [isSubmitted, setIsSubmitted] = useState(false)

    const [selectCorrectAnswer, setSelectCorrectAnswer] = useState([])

    const [finalResult, setFinalResult] = useState(null)

    const init = async () => {

        const chaptersResult = await CourseService.getCourseChapter(id)
        setAllChapter(chaptersResult?.chapters)
        setCourseResult(chaptersResult?.course)

    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [video])

    const completeLesson = async () => {
        const completeResult = await CourseService.updateChapterProgress(videoID, {
            playback_status: 2
        })

        const chaptersResult = await CourseService.getCourseChapter(id)
        setAllChapter(chaptersResult?.chapters)
        setCourseResult(chaptersResult?.course)
    }

    const updateLesson = async (lesson) => {
        // if (lesson?.userCourseProgresses?.playback_status === 0) {
        //     const completeResult = await CourseService.updateChapterProgress(lesson?.id, {
        //         playback_status: 1
        //     })

        //     const chaptersResult = await CourseService.getCourseChapter(id)
        //     setAllChapter(chaptersResult?.chapters)
        // }
        return


    }


    const checkCorrectAnswer = async () => {
        console.log(selectCorrectAnswer)
        let totalCorrectAnswer = 0

        if (selectedLesson.quizzes.length != selectCorrectAnswer.length) {
            SweetAlert.fire({
                showClass: {
                    popup: 'animate__animated animate__backInUp'
                },
                hideClass: {
                    popup: 'animate__animated animate__backOutDown'
                },
                title: `Make sure all the question has been answer`,

                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: t('financial.confirm'),
                reverseButtons: true
            })
        } else {

            setIsSubmitted(true)

            for (let answer of selectCorrectAnswer) {

                if (answer.quiz.answer == answer.user_answer) {
                    totalCorrectAnswer += 1
                }
            }

            const totalMark = totalCorrectAnswer / selectedLesson.quizzes.length * 100

            const finalResult = await QuizService.answerQuiz({
                lesson_id: selectedLesson.id,
                total_correct_answer: totalCorrectAnswer,
                total_questions: selectedLesson.quizzes.length,
                total_marks: totalMark,
            })

            console.log('finalResultfinalResult', finalResult)
            setFinalResult(finalResult.participant_quiz)


            if (totalMark > 80) {
                SweetAlert.fire({
                    showClass: {
                        popup: 'animate__animated animate__backInUp'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__backOutDown'
                    },
                    title: `Congratulations, you pass the quiz`,

                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: t('financial.confirm'),
                    reverseButtons: true
                })
            } else {
                SweetAlert.fire({
                    showClass: {
                        popup: 'animate__animated animate__backInUp'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__backOutDown'
                    },
                    title: `Hmm, watch video and try again`,

                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: t('financial.confirm'),
                    reverseButtons: true
                })
            }

        }



        // post api to backend


    }


    return (
        <Container className='mt-5' style={{ marginBottom: '70px' }}>
            <Row style={{ marginTop: '110px' }}>
                <Col className='d-none d-lg-block'>
                    <Button
                        style={{
                            width: '136px',
                            height: '57px',
                            borderRadius: '40px',
                            backgroundColor: '#F0F0F0',
                            color: 'black',
                            fontSize: '18px'
                        }}
                        onClick={id == 24 ? () => { window.location.reload() } : () => navigate(`${process.env.PUBLIC_URL}/courses`)}
                        color='#F0F0F0'
                    >{t('courses.Back')}

                    </Button>
                </Col>
                <Col className='d-none d-lg-block'>
                    <p style={{ fontSize: '20px', fontWeight: '500' }}>
                        {t('courses.Chapter')}
                    </p>
                </Col>
            </Row>
            <Row style={{ padding: '0' }}>
                <Col className='mt-5'>
                    {/* <h3 style={{ position: 'absolute', left: '50%', top: "60px", transform: 'translate(-50%, -50%)' }}>殿豪超值优惠 （配套A)</h3> */}

                    {/* Video Part type == 1 */}
                    {selectedLesson?.type == 1 && videoLink &&

                        <div>
                            <div style={{ padding: '6% 0 50% 0', position: 'relative', backgroundColor: videoLink && 'black' }}>
                                <iframe src={videoLink}
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen
                                    style={{ position: 'absolute', top: '0', left: '0' }}
                                    id='frame-vimeo'
                                    title="Screen Recording 2021-11-29 at 11.09.29 AM.mov"
                                    onLoad={async () => {
                                        if (video?.userCourseProgresses?.playback_status === 0) {
                                            const completeResult = await CourseService.updateChapterProgress(videoID, {
                                                playback_status: 1
                                            })

                                            const chaptersResult = await CourseService.getCourseChapter(id)
                                            setAllChapter(chaptersResult?.chapters)
                                            setCourseResult(chaptersResult?.course)
                                        }
                                    }}
                                >
                                </iframe>
                            </div>

                            <div className='mt-4' style={{ whiteSpace: 'pre-wrap' }}>

                                {selectedLesson?.description != null ?
                                    <h5>{parse(String(selectedLesson?.description))}</h5>
                                    : ''}

                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                    }

                    {/* Reading Part */}
                    {
                        selectedLesson?.type == 2 &&
                        <div className='mt-2' style={{ whiteSpace: 'pre-wrap' }}>

                            {selectedLesson?.description != null ?
                                <h5>{parse(String(selectedLesson?.description))}</h5>
                                : ''}

                        </div>
                    }

                    {
                        selectedLesson?.type == 3 &&
                        <>
                            <div className='mt-2'>
                                <h4 className='mb-4'>Quiz: {selectedLesson.name}</h4>
                                {/* <h6 className='mb-5'>Highest Mark: 100%</h6> */}
                                {
                                    selectedLesson.quizzes?.map((quiz, quizIndex) => {
                                        return <div className='mb-5'>

                                            <h6 >{quizIndex + 1}. {quiz?.question}</h6>
                                            {quiz.question_image != "" &&
                                                <div className='mt-3 mb-3'>
                                                    <img width='350px' height='200px' src={quiz.question_image} alt={quiz.question_image} />
                                                </div>}
                                            {quiz?.options.split(',').map((option, optionIndex) => {

                                                return <div className='ml-4'>
                                                    <Input disabled={isSubmitted} type="radio" id={option + quizIndex + optionIndex} name={quizIndex} value={option} onClick={(e) => {

                                                        let alreadyAnswerQuestionIndex = selectCorrectAnswer.findIndex(answer => {
                                                            return answer.quiz.question == quiz?.question && answer.quiz.id == quiz?.id
                                                        })

                                                        if (alreadyAnswerQuestionIndex == -1) {

                                                            const updatedArray = selectCorrectAnswer
                                                            updatedArray.push({
                                                                quiz: quiz,
                                                                user_answer: e.target.value
                                                            })

                                                            setSelectCorrectAnswer(
                                                                updatedArray
                                                            )

                                                        } else {

                                                            const updatedArray = selectCorrectAnswer
                                                            updatedArray[alreadyAnswerQuestionIndex].user_answer = e.target.value

                                                            setSelectCorrectAnswer(
                                                                updatedArray
                                                            )

                                                        }

                                                    }} />
                                                    <label for={option + quizIndex + optionIndex}>{option}</label><br></br>


                                                </div>
                                            })}
                                            {isSubmitted && selectCorrectAnswer.filter(answeredQuestion => {

                                                // console.log(quiz?.question)
                                                // console.log(answeredQuestion.question == quiz?.question)
                                                return answeredQuestion.quiz.question == quiz?.question && answeredQuestion.quiz.id == quiz?.id
                                            }).map(filterAnsweredQuiz => {
                                                if (filterAnsweredQuiz.quiz.answer === filterAnsweredQuiz.user_answer) {
                                                    return <>
                                                        <div className='correct-container' >
                                                            <CheckCircle width='20px' style={{ marginRight: '10px' }} />
                                                            <p className='m-0'>Bravo, This answer is correct</p>
                                                        </div>
                                                    </>
                                                } else {
                                                    return <>
                                                        <div className='incorrect-container' >
                                                            <AlertCircle width='20px' style={{ marginRight: '10px' }} />
                                                            <p className='m-0'>Opps, this answer is incorrect, you may refer back to previous video tutorial to find the correct answer</p>
                                                        </div>
                                                    </>
                                                }

                                            })}
                                        </div>
                                    })
                                }

                                {
                                    !isSubmitted ?
                                        <>
                                            <Button className='mb-5' disabled={isSubmitted} onClick={() => {
                                                checkCorrectAnswer()
                                            }}>Submit</Button>
                                        </> :
                                        <>
                                            <p>Mark: {finalResult?.total_marks.toFixed()}% / 100%</p>
                                            <p>Total Correct Answer: {finalResult?.total_correct_answers} out of {selectCorrectAnswer.length}</p>
                                        </>

                                }

                            </div>
                        </>
                    }


                </Col>
            </Row>


            <Row className='mt-3'>


                <Col id='colCoursesSide' lg='6' style={{ backgroundColor: 'transparent', padding: '0', overflow: 'hidden' }} className='d-none d-md-block d-lg-block d-xl-block'>
                    <div>
                        <img src={allChapter[0]?.course?.cover_image_url} alt='courses background' style={{ width: '100%', marginBottom: '20px' }} />
                    </div>

                    <Badge style={{ backgroundColor: ' #C4A569', color: 'black', width: '133px', height: '44px', borderRadius: '7px', fontSize: "16px", padding: '15px' }}>
                        {courseResult?.difficulty}
                    </Badge>
                    <Badge style={{ textTransform: 'capitalize', borderRadius: '7px', marginLeft: '10px', backgroundColor: '#CDCFD7', color: 'black', width: '133px', height: '44px', fontSize: '16px', padding: '15px' }}>{courseResult?.course_language}</Badge>

                    <div
                        onClick={() => {
                            setVideoLink('')
                        }}
                        style={{ textAlign: 'left', cursor: 'pointer' }}>
                        <h3>{allChapter[0]?.course?.name}</h3>
                    </div>
                    <h5 style={{ color: '#4F5358' }}>{courseResult?.author}</h5>
                </Col>

                <Col lg='6' style={{ padding: '0', marginBottom: '20px' }}>
                    <div style={{ boxShadow: '0px 7px 20px #00000014', borderRadius: '14px', margin: '0px 15px', padding: '20px', marginBottom: "10px" }}>
                        <div className="text-left" style={{ color: ' #C4A569', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                            <p style={{ fontSize: '20px' }}>{courseResult.course_progress}%</p>
                            <p style={{ fontSize: '20px' }}>Completed</p>
                        </div>
                        <Progress id="courseProgress" width="100%" value={courseResult.course_progress} style={{ backgroundColor: ' #C4A569', width: '100%' }} />
                    </div>
                    {/* <h3 style={{ position: 'absolute', left: '50%', top: "60px", transform: 'translate(-50%, -50%)' }}>殿豪超值优惠 （配套A)</h3> */}

                    <div style={{ background: '#F0F0F0', margin: '0 15px', borderRadius: '14px' }}>
                        {allChapter?.map((chapter, index) => {
                            return (
                                <>
                                    {/* {console.log('chapter', chapter)} */}
                                    <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', background: '#F0F0F0', borderRadius: '14px' }}>
                                        <div className='mt-2 mb-2' onClick={() => { toggleCollapse(chapter) }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '15px', marginRight: '15px' }}>
                                            <h6 style={{ marginBottom: '0px' }} >{chapter?.name}</h6>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h6 style={{ marginBottom: '0', marginRight: '15px' }}>
                                                    {chapter?.lessons?.filter(lesson => {
                                                        return lesson?.userCourseProgresses?.playback_status === 2
                                                    }).length}

                                                    /{chapter?.lessons?.length}</h6>
                                                {isChapterOpenId === index + 1 && isCollapse ? <ChevronUp /> : <ChevronDown />}


                                            </div>
                                        </div>
                                        <Collapse isOpen={isChapterOpenId === allChapter[index].id && isCollapse} style={{ background: 'white' }}>
                                            <div style={{ marginLeft: '15px', marginTop: '10px' }}>
                                                {chapter?.lessons?.map((lesson, index) => {
                                                    return (
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px', marginTop: '20px' }}>
                                                                {
                                                                    // 1 == video, 2 == book, 3 == quiz
                                                                    lesson.type == 1 ?
                                                                        <Video width='20px' style={{ marginRight: '10px' }} /> :
                                                                        lesson.type == 2 ? <Book width='20px' style={{ marginRight: '10px' }} />
                                                                            : <FileText width='20px' style={{ marginRight: '10px' }} />
                                                                }

                                                                <div style={{ marginTop: '2px' }}>
                                                                    <p
                                                                        onClick={() => {

                                                                            setVideoLink(lesson?.video_url)
                                                                            setVideoID(lesson?.id)
                                                                            setVideo(lesson)
                                                                            updateLesson(lesson)
                                                                            setSelectedVideoIndex(index + 1)
                                                                            setSelectedLesson(lesson)
                                                                            ReactGA.event({
                                                                                category: 'Chapters',
                                                                                action: 'Click video',
                                                                                label: 'Click video'
                                                                            })
                                                                        }}
                                                                        style={{ marginBottom: '0px', cursor: 'pointer', color: videoID === lesson.id && ' #C4A569' }}>{index + 1}. {lesson?.name}
                                                                    </p>
                                                                </div>
                                                                <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                                                                    {lesson?.userCourseProgresses?.playback_status === 1 && <i className="fa fa-clock-o" style={{ color: ' #C4A569', fontSize: 20 }}></i>}
                                                                    {lesson?.userCourseProgresses?.playback_status === 2 && <i className="fa fa-check-circle" style={{ color: 'green', fontSize: 20 }}></i>}

                                                                </div>

                                                            </div>

                                                        </>
                                                    )
                                                })}
                                                {/* <p style={{ marginBottom: '10px' }}>1. What is Forex? (10.45)</p>
                                        <p style={{ marginBottom: '0px' }}>2. How to Trade? (5.10)</p> */}
                                            </div>
                                        </Collapse>
                                    </div>
                                </>
                            )
                        })}
                    </div>

                    {videoLink && video?.userCourseProgresses?.playback_status !== 2 &&
                        <div style={{ textAlign: 'center', marginTop: '5px', marginRight: '10px', marginBottom: '20px', marginTop: '20px' }}>
                            <Button style={{ width: '233px', height: '57px', color: 'black', borderRadius: '20px' }} color='primary' onClick={() => { completeLesson() }}>
                                <p style={{ fontSize: '18px', marginTop: '5px' }}>Complete</p>
                            </Button>
                        </div>
                    }

                    {description != '' && description != null &&
                        <div className='ml-4 mr-4' style={{ marginTop: '20px' }}>
                            <p>{parse(description)}</p>
                        </div>
                    }
                </Col>
            </Row>
        </Container >
    )
}

export default PammManager