import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector } from "react-redux"

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'

import styled from 'styled-components'
import _ from "lodash"

import { useTranslation } from 'react-i18next'

import './index.scss'

import LanguageIcon from '../../language.png'

import VEBackground from './veBackground.jpg'

const LoginButton = styled.button` 
  margin-right: 37px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
`

const SignInForm = ({ logo }) => {
  const { register, handleSubmit, errors } = useForm()
  const [loginError, setLoginError] = useState(null)
  const { t, i18n } = useTranslation()
  const userVerificationStatus = useSelector((state) => _.last(state.user.verifications))

  const submitAuth = async (data) => {
    try {
      const result = await AuthService.login({
        email: data.email,
        password: data.password
      })

      if (result.success) {
        localStorage.setItem('token', result.data.jwt.token)
        localStorage.setItem('user', JSON.stringify(result.data.user))

        // const updatedUser = await UserService.getMyself()

        // if (userVerificationStatus?.status === 1 || userVerificationStatus?.status === 0) {
        //   window.location.href = '/dashboard'
        // }
        // else if (updatedUser?.user?.is_funnel_completed === false &&
        //   updatedUser?.user?.is_profile_completed !== true &&
        //   updatedUser?.user?.is_account_created === false) {
        //   //removed verify for new registration flow
        //   window.location.href = '/demo_get'
        //   // window.location.href = '/dashboard'
        // }
        // else if (updatedUser?.user?.is_funnel_completed === false &&
        //   updatedUser?.user?.is_profile_completed !== true &&
        //   updatedUser?.user?.is_account_created === true) {
        //   //removed verify for new registration flow
        //   // window.location.href = '/demo_get'
        //   window.location.href = '/dashboard'
        // }
        // // else if (updatedUser?.user?.verification_status === null) {
        // //   window.location.href = '/verify-KYC'
        // // }
        // else if (updatedUser?.verification?.status === 1) {
        //   window.location.reload()
        // } else {
        //   window.location.href = '/dashboard'
        // }
        window.location.href = '/dashboard'
      }
    } catch (error) {
      if (error.message === 'E_INVALID_AUTH_PASSWORD' || error.message === 'E_INVALID_AUTH_UID') {
        setLoginError(t("auth.InvalidCredential"))
      } else {
        setLoginError(error.message ?? t("auth.pleseTryAgain"))
      }
    }
  }

  return (
    <>
      <Row className='justify-content-center'>
        <Col>
          <div className="mb-3" style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '3' }}>
            {logo?.value && (
              <img
                src={require('../../assets/images/logo/ve-logo.png')}
                // src={newLogo}
                alt="logo"
                style={{ maxHeight: 175, maxWidth: 175 }}
              />
            )}
          </div>





          <Row style={{ justifyContent: 'center', zIndex: '6', height: '100vh' }}>
            <div
              className='d-none d-lg-block'
              width='40vw'
              height='100vh'

              style={{
                backgroundColor: 'white',
                backgroundSize: 'cover',
                backgroundImage: `url('${VEBackground}')`,
                position: 'relative'
              }}>

              {/* <p style={{ zIndex: '3', color: 'white', fontSize: '28px', margin: '50px' }}>
              </p> */}

              <div className="d-flex justify-content-start align-items-start " style={{ color: 'white', textAlign: 'left', width: '40vw', height: '100vh' }}>
                <div style={{ margin: '75px' }}>
                  <h1 className='m-0'>Learn with</h1>
                  <h1 className='m-0'>Value Envision</h1>
                  <h2 className='m-0'>用心教育，正規交易</h2>
                </div>
              </div>

            </div>
            <Form
              id="login-form"
              className="theme-form cont"
              onSubmit={handleSubmit(submitAuth)}
              style={{ margin: 'auto', zIndex: '5', minHeight: '310px', borderRadius: '0px 0px 50px 0px', border: 'none' }}
            >
              {/* <img
                style={{ width: '50px' }}
                src={require('../../assets/images/logo/ve-logo-only.png')}
                alt=""
              /> */}
              <img
                style={{ float: 'center', width: '250px' }}
                className="img-fluid for-light mb-5"
                src={require('../../assets/images/logo/ve-logo.png')}
                alt=""
              />
              <h4 className='mb-4 ' style={{ color: '#0f1430' }}>Login to account</h4>

              <Label className="col-form-label pt-0">{t("auth.emailAddress")}</Label>

              <FormGroup style={{ display: "flex", backgroundColor: 'transparent', marginBottom: '10px' }}>
                <Input
                  id="input-form"
                  className="form-control"
                  placeholder={t("auth.emailAddress")}
                  type="text"
                  required=""
                  name="email"
                  innerRef={register({ required: true })}
                  style={{ backgroundColor: "#f8f8f8" }}
                />
              </FormGroup>
              <span style={{ color: 'red' }}>{errors.email && t("auth.emailIsRequire")}</span>

              <Label className="col-form-label">{t("auth.password")}</Label>

              <FormGroup style={{ display: "flex", marginBottom: '15px' }}>
                <Input
                  id="input-form"
                  className="form-control"
                  placeholder={t("auth.password")}
                  type="password"
                  required=""
                  name="password"
                  innerRef={register({ required: true })}
                  style={{ backgroundColor: "#f8f8f8" }}
                />
              </FormGroup>
              <span style={{ color: 'red' }}>{errors.password && t("auth.passIsRequire")}</span>
              {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>}

              <div className='d-flex justify-content-end'>
                <Link to={`${process.env.PUBLIC_URL}/forgot_password`} href="#javascript">
                  <p style={{ color: '#C4A569', }}>Recover your password</p>
                </Link>
              </div>
              <LoginButton id='login_button' className="w-100" color="primary" style={{ backgroundColor: '#C4A569', color: 'black' }} >{t("auth.login")}</LoginButton>


              <div style={{ padding: 5 }} />
              <div className='d-flex justify-content-end'>
                <p style={{ color: '#898989', marginBottom: '0.8rem' }}>{t("auth.dontHaveAccount")}
                  <Link to={`${process.env.PUBLIC_URL}/sign_up`} href="#javascript">
                    <span className="ml-1" style={{ color: '#C4A569' }}>{t("auth.createAccount")}</span>
                  </Link>
                </p>
              </div>

              <div className='d-flex justify-content-end'>
                <p style={{ color: '#898989' }}>Need any assistance?

                </p>

                <a href="https://wa.me/601120682128?text=Hi%2C%20I%20need%20assist%20on%20VE%20Connect" target="_blank">
                  <img
                    onClick={() => {

                    }}
                    src={require('../../assets/images/logo/whatsapp.png')}
                    // src={newLogo}
                    alt="logo"
                    style={{ maxHeight: 20, maxWidth: 20, margin: '0px 5px 0px 10px', cursor: 'pointer' }}
                  />
                </a>

                <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@ve.education" target="_blank">
                  <img
                    onClick={() => {

                    }}
                    src={require('../../assets/images/logo/mail.png')}
                    // src={newLogo}
                    alt="logo"
                    style={{ maxHeight: 20, maxWidth: 20, margin: '0px 5px', cursor: 'pointer' }}
                  />
                </a>
              </div>

            </Form>


          </Row>
        </Col>
      </Row>
    </>
  )
}

export default SignInForm
