import React, { useState, useEffect } from 'react'
import { LogOut, User, HelpCircle } from "react-feather"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import Auth from "../../../network/services/auth"
import { Trans, useTranslation } from 'react-i18next'

import "./profile.scss"
import LanguageIcon from '../../../language.png'
import logout from './logout.png'

const Profile = () => {
  const currentUser = useSelector((state) => state.user.user)
  const [dropDownTrigger, setDropDownTrigger] = useState('')
  const logOut = () => {
    Auth.logout()
  }
  const dropdowntrigger = () => {
    setDropDownTrigger(!dropDownTrigger)
  }

  const { t, i18n } = useTranslation()
  const [nameDropdownOpen, setNameDropdownOpen] = useState(false);
  const [DropdownClicked, setDropdownClicked] = useState(false)
  const nameToggle = () => setNameDropdownOpen(prevState => !prevState);

  return (
    <>

      <div className='' style={{ height: '35px', marginRight: '150px', zIndex: '2', borderRadius: '25px' }}>
        <ul class='menu--main' onClick={() => dropdowntrigger()}>
          <li style={{ width: '180px', borderRadius: '25px', color: 'white', backgroundColor: '#84754e', textAlign: 'center' }}>{currentUser?.name.length > 12 ? currentUser?.name.substr(0, 12) + "..." : currentUser?.name ?? "Name"}
            {dropDownTrigger ?
              <ul class='sub-menu' style={{ width: '180px', boxShadow: "0px 4px 20px #0000000D" }}>
                <Link to={`${process.env.PUBLIC_URL}/account`} href="#javascript">
                  <li>
                    <div>
                      <User />
                      <span style={{ color: 'black' }}>   {t("header.account")}</span>
                    </div>
                  </li>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/language`} href="#javascript">
                  <li>
                    <div>
                      <HelpCircle alt="language" />
                      <span style={{ color: 'black' }}>    {t("language.barLanguage")}</span>
                  </div>
                  </li>
                </Link>
                <li onClick={logOut}>
                  <div>
                    <img src={logout} height="15px" width="15px"></img>
                    <span style={{ color: 'black' }}>   {t("header.logout")}</span>
                  </div>
                </li>
              </ul>
              : ''
            }
          </li>

        </ul>
      </div>

      {/* mobile size account */}
      {/* <div className='d-block d-lg-none d-xl-none' style={{ height: '35px', marginRight: '140px', zIndex: '1000', borderRadius: '25px' }}>
        <ul class='menu--main' onClick={() => dropdowntrigger()}>
          <li style={{ width: '180px', borderRadius: '25px', backgroundColor: '#84754e', color:'white' }}>{currentUser?.name.length > 12 ? currentUser?.name.substr(0, 8) + "..." : currentUser?.name ?? "Name"}
            {dropDownTrigger ?
              <ul class='sub-menu' style={{ width: '130px', paddingBottom: '210px' }}>
                <li>
                  <div style={{ marginLeft: '0px', width: '100px' }}>
                    <Link to={`${process.env.PUBLIC_URL}/account`} href="#javascript">
                      <User />
                      <span style={{ fontSize: '12px', color: 'black' }}>   {t("header.account")}</span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div style={{ marginLeft: '0px', width: '100px' }}>
                    <Link to={`${process.env.PUBLIC_URL}/language`} href="#javascript">
                      <img src={LanguageIcon} alt="languageIcon" width='15px' height="15px" className="m-r-5" />
                      <span style={{ fontSize: '12px', color: 'black' }}>{t("language.barLanguage")}</span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div style={{ marginLeft: '0px', width: '100px' }}>
                    <Link to={`${process.env.PUBLIC_URL}/faqs`} href="#javascript">
                      <HelpCircle alt="faqs" width='15px' height="15px" className="m-r-5" />
                      <span style={{ fontSize: '12px', color: 'black' }}>{t("FAQs")}</span>
                    </Link>
                  </div>
                </li>
                <li onClick={logOut}>
                  <div style={{ marginLeft: '0px', width: '100px', marginBottom: "20px" }}>
                    <LogOut />
                    <span style={{ fontSize: '12px' }}>   {t("header.logout")}</span>
                  </div>
                </li>
              </ul>
              : ''
            }
          </li>

        </ul>
      </div> */}
    </>

  )
}

export default Profile
