import React from 'react'

function lpoa() {
    return (
        <>
            <p>
                The undersigned customer (“Customer”) hereby authorizes VE Fund
                Manager as Client’s fund agent, and revokes all prior powers of attorney
                relating to Client’s trading account at TradeHall, with full power and
                authority to purchase, sell or trade in contract(s) of Crypto, Indices, Forex,
                Commodities, Shares and ETFs, including non-deliverable transactions, on
                margin or otherwise, in one or more accounts opened by and maintained
                with TradeHall under the Client’s name. VE Fund Manager is authorized to
                act on behalf of the Client in the same manner and with some force and
                effect as Client might or could act with respect to such transactions, as
                well as and with respect to all other things necessary or incidental to the
                furtherance and/or conduct of the Account.
                                    </p>
            <p>
                TradeHall is hereby authorized to follow the instructions of VE Fund
                Manager in all such transactions communicated through entry on
                TradeHall’s trading system, or such written or oral instructions that
                TradeHall may accept and management decisions relating to the Account.
                TradeHall is not responsible for following the Instructions of the VE Fund
                Manager, including but not limited to any instruction to aggregate (or
                bunch) orders of Client with orders for or on behalf of VE Fund Manager’s
                other clients, and Client will not attempt to hold TradeHall liable for VE Fund
                Manager’s actions or omissions.
                                    </p>
            <p>
                Client understand that there have never designed a “safe” trading system
                and TradeHall does not imply or otherwise endorse the operation
                methods of such VE Fund Manager. Client hereby exempts TradeHall from
                any and all liability to Client or anyone who claims through Client with
                respect to any damages, losses or lost of profit caused or allegedly
                sustained TradeHall due to the Client’s instructions from the VE Fund
                Manager. The relationship between the VE Fund Manager and the Client, in
                addition to any other compensation provided by the customer to
                TradeHall, will also compensate TradeHall for any losses, damages,
                liabilities and expenses of any kind or nature whatsoever, arising
                therefrom.
                                    </p>
            <p>
                Client also agrees to hold TradeHall from harmless and to indemnify it as
                to any expense, damage or liability (including attorneys’ fees) sustained
                by it with respect to any and all acts and practices of VE Fund Manager
                regarding this Account, including all losses arising therefrom and debit
                balance(s) due thereof.
                                    </p>
            <p>
                Client acknowledges and agrees that Client and VE Fund Manager, by
                separate agreement, have agreed on the method of compensation for
                services rendered to Client that was promised by VE Fund Manager. Client
                hereby agrees to indemnify and hold harmless TradeHall and its affiliates
                and employees from any losses, damages or disputes arising out of or
                relating to the calculation and payment of such fees to VE Fund Account. All
                statements, notices, correspondence and the like generated in this
                Account will be sent or given to VE Fund Manager at the address shown for
                this Account and to Client at the address indicated in Client’s Account
                documents, and to such other person or address as Customer may
                hereafter designate in writing. In this regard, Client acknowledges and
                agrees that, although Client has granted trading authority to VE Fund
                Manager, Client has an obligation to review promptly all statements
                reflecting transactions executed in the Account and to contact TradeHall
                or VE Fund Manager if Client has any questions concerning such
                statements.
                                    </p>
            <p>
                Client understands that there are many strategies that can be used in
                trading, some of which have unlimited risk of loss and may cause the
                Client to bear the total loss of all funds in the account, and the Client is
                liable for any deficit in the Account resulting therefrom. Client
                acknowledges that Client has the opportunity to discuss with VE Fund
                Manager, and understands the nature and risks of the strategy to be used
                in connection with transactions related to the Client’s account. The Client
                also acknowledges that it is Client’s sole responsibility to monitor their
                Account and that while TradeHall provides about the Account and
                transactions online. TradeHall is under no obligation to affirmatively
                advise Client of any changes in Client’s Account value.
                                    </p>
            <p>
                This authorization is a continuing one and will remain in full force and
                effect until revoked by Client, by written notice given to TradeHall. Such
                revocation will only take effect after TradeHall has actually received it, but
                it will not affect in any way any liability arising from its receipt of
                previously initiated transactions. After receiving the cancellation notice,
                TradeHall has the right to close the position of the account at the current
                market price determined by TradeHall itself and return the funds to the
                Client. This authorization will insure to the benefit of TradeHall, its
                successors and assigns. The provisions hereof will be in addition to, and
                will in no way limit or restrict, any right that TradeHall may have under any
                agreement with Client.
                                    </p>
        </>
    )
}

export default lpoa
