import React, { useState, useEffect } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, FormText, Input, Container} from 'reactstrap'
import styled from 'styled-components'

import Passport from './identity/passport'
import NationalId from './identity/national_id'
// import DrivingLicense from './identity/driving_license'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`
    

const IdentityUpload = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeTab = useSelector((state) => state.verification.activeTab)
  const idFront = useSelector((state) => state.verification.idFront)
  const idBack = useSelector((state) => state.verification.idBack)

  return (
    <>
      <Container className="d-none d-lg-block d-xl-block d-md-block" style={{textAlign:'center', padding:'0px'}}>
        <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotBlur.png" width='22%'/>
        <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCut.png" width='22%'/>
        <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustColor.png" width='22%'/>
        <img className='ml-1 mr-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleId.png" width='22%'/>
      </Container>

      {/*mobile size */}

      <Container className="d-block d-xl-none d-lg-none d-md-none" style={{textAlign:'center', padding:'0px'}}>
        <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotBlur.png" width='80%'/>
        <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustNotCut.png" width='80%'/>
        <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/mustColor.png" width='80%'/>
        <img className='mb-1' src="https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/social/verify/goodExampleId.png" width='80%'/>
      </Container>
      {/* <Nav className="nav-pills nav-primary">
        <NavItem>
          <NavLink
            className={activeTab === 'passport' ? 'active' : ''}
            onClick={() => dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'passport' })}
            style={{ cursor: 'pointer' }}
          >
            {t("user.Passport")}
          </NavLink>
        </NavItem> */}
        {/* <NavItem>
          <NavLink
            className={activeTab === 'license' ? 'active' : ''}
            onClick={() => dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'license' })}
            style={{ cursor: 'pointer' }}
          >
            Driving License
          </NavLink>
        </NavItem> */}
        {/* <NavItem>
          <NavLink
            className={activeTab === 'id' ? 'active' : ''}
            onClick={() => dispatch({ type: 'SET_ACTIVE_VERIFICATION', activeTab: 'id' })}
            style={{ cursor: 'pointer' }}
          >
            {t("user.NationalID")}
          </NavLink>
        </NavItem>
      </Nav> */}

      {/* <TabContent activeTab={activeTab}>
        <TabPane tabId="passport">
          <Passport />
        </TabPane>
        <TabPane tabId="license">
          <DrivingLicense />
        </TabPane>
        <TabPane tabId="id">
          <NationalId />
        </TabPane>
      </TabContent> */}

      <hr />
      <p>{t("user.FrontAndBackOfYour")} {activeTab} {t("user.proof")}</p>

      <Row>
        <Col sm={12} md={6}>
          <FormText color="muted" style={{ textTransform: 'capitalize' }}>
            {activeTab} {t("user.Front")}
          </FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({ type: 'SET_ID_FRONT', idFront: { file: e?.currentTarget?.files[0] } })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{idFront?.error != null && idFront.error}</span>
          <MobileGap />
        </Col>
        <Col sm={12} md={6}>
          <FormText color="muted" style={{ textTransform: 'capitalize' }}>
            {activeTab} {t("user.Back")}
          </FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({ type: 'SET_ID_BACK', idBack: { file: e?.currentTarget?.files[0] } })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{idBack?.error != null && idBack.error}</span>
        </Col>
      </Row>
    </>
  )
}

export default IdentityUpload
