import React, { useState } from 'react'
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col
} from 'reactstrap'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'

import UserService from '../../../network/services/user'
import AuthService from '../../../network/services/auth'

import { useTranslation } from 'react-i18next'

const TwoFactorAuthentication = () => {
  const { t } = useTranslation()
  // modal
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const [authCode, setAuthCode] = useState(null)
  const [errors, setErrors] = useState({ verification: null, twoFA: null })
  const email = useSelector((state) => state.user.user.email)
  const twoFA = useSelector((state) => state.user.twoFA)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const handle2fa = async () => {
    try {
      let result
      result = await UserService.update2fa({ auth_code: authCode })

      // if (twoFA != null) {
      //   // disable
      // } else {
      //   // enable
      // }

      // console.log(result)

      if (result.user) {
        setShowModal(false)

         SweetAlert.fire({
            showClass: {
              popup: 'animate__animated animate__backInUp'
            },
            hideClass: {
              popup: 'animate__animated animate__backOutDown'
            },
          title: t("user.2FAStatusUpdatedSuccessfully"),
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
      }
    } catch (error) {
      console.log(error)
      setErrors({ twoFa: error.message })
    }
  }

  if (userVerification?.status === 1) {
    return (
      <>
        <Card>
          <CardBody>
            <div className="mb-2" style={{ display: 'flex' }}>
              <Badge style={{width:'40px'}} color={twoFA != null ? 'success' : 'danger'} pill className="mr-2">
                <p style={{marginTop:'5px'}}>{twoFA != null ? t("user.On") : t("user.Off")}</p>
              </Badge>
              <h6 style={{fontSize:'18px', marginTop:'10px'}}>{t("user.TwoFactorAuthentication")}</h6>
            </div>
            <div className="p-1" />
            <p style={{ margin: 0 }}>
              {t("user.ProtectYourAccountAndYour")}
            </p>
          </CardBody>
          <CardBody style={{paddingTop:'20px'}}>
            <div>
              <h6>{t("user.HowToUseTwoFactorAuthentication")}</h6>
              <p style={{ margin: 0 }}>
                {t("user.WhenYouSigninAfterEntering")}
              </p>
              <div className="p-2" />
              <Container style = {{padding: '0px', boxShadow:'0px 7px 20px #00000014'}}>
                <Card>
                  <CardBody style={{padding:'20px', width:'100%'}}>
                    <Row style={{justifyContent:'space-between'}}>
                        <div style={{marginLeft:'20px', marginTop:'10px'}}>
                          <p style={{ margin: 0 }}>
                          {t("user.YourWalletIs")} <b>{twoFA != null ? '' : t("user.not")}</b> {t("user.protectedWithTwoFactorAuthentication")}
                          </p>
                          <div className="p-0" />
                          {twoFA == null && (
                            <p style={{ margin: 0 }}>
                              {t("user.SecureLoginAndOrPayout")}
                            </p>
                          )}
                        </div>
                      
                      
                        <Button
                          color={twoFA != null ? 'danger' : 'primary'}
                          style={{
                            padding:'0px',
                            marginRight:'20px',
                            marginLeft:'20px',
                            marginTop:'10px',
                            width:'149px',
                            height:'50px',
                            borderRadius:'12px',
                            color:'black'

                          }}
                          onClick={async () => {
                            if (email != null) {
                              const codeResult = await AuthService.sendVerification({
                                email: email
                              })

                              if (codeResult.success) {
                                setShowModal(true)
                              } else {
                                setErrors({ verification: codeResult.message })
                              }
                            } else {
                              setErrors({ verification: 'Missing email' })
                            }
                          }}
                        >
                          {twoFA != null ? t('user.disable2FA') : t('user.enable2FA')}
                        </Button>
                      </Row>
                      <div>
                        <span style={{ color: 'red' }}>{errors?.verification}</span>
                      </div>
                    
                  </CardBody>
                </Card>
              </Container>
            </div>

          </CardBody>
        </Card>

        {showModal && (
          <Modal isOpen={showModal} backdrop={true} centered={true}>
            <ModalHeader toggle={toggleModal}>{t("user.PleaseVerifyYourIdentity")}</ModalHeader>
            <ModalBody>
              <p className="mb-1">{t("user.CheckYourEmail")}</p>
              <Input
                className="form-control"
                placeholder="Verification Code"
                required
                onChange={(e) => {
                  setAuthCode(e.target.value)
                }}
              />
              <span style={{ color: 'red' }}>{errors?.twoFA}</span>
              <Button
                className="mt-3 pull-right"
                onClick={() => {
                  handle2fa()
                }}
              >
                {t("user.Submit")}
              </Button>
            </ModalBody>
          </Modal>
        )}
      </>
    )
  }

  return (
    <Card>
      <CardBody>
      {t("user.PleaseCompleteThe")} <a href="#verification">{t("user.KYCVerificationProcess")}</a> {t("user.toContinueTheProcess")}
      </CardBody>
    </Card>
  )
}

export default TwoFactorAuthentication
