import client from '../request'

const login = (data) => {
  return client.post('/eye_game/login', data)
}

const getMyGame = (id) => {
  return client.get(`/eye_game/find_my_game?eye_game_user_id=${id}`)
}

const answerQuestion = (data) => {
  return client.post(`/eye_game/answer_game`, data)
}

const getLeaderboard = (data) => {
  return client.get(`/eye_game/get_leaderboard_result`, data)
}

export default {
  login,
  getMyGame,
  answerQuestion,
  getLeaderboard
}
