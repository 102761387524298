import React, { useEffect, useState } from 'react'

import Breadcrumb from '../../layout/breadcrumb'
import PammManager from './PammManager/index'
import PammSubscription from './pammSubscription/index'

import {
    Row,
    Col,
    Card,
    Button,
    Navbar,
    NavbarText,
    CardBody,
    Tooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Container
} from 'reactstrap'
import { useTranslation } from 'react-i18next'

import './pamm.scss'

const Pamm = () => {

    // const [showGraph, setShowGraph] = useState(true)
    const [nav_Show, setNav_Show] = useState('')
    const { t } = useTranslation()
    const displayNav = () => {
        if (nav_Show === "Manager List" || nav_Show === "经理人" || nav_Show === "") {
            return (
                <Col lg="12" style={{ padding: '0' }}>
                    <PammManager />
                </Col>
            )
        }
        else if (nav_Show === "Subscription" || nav_Show === "订阅") {
            return (
                <Col lg="12" style={{ padding: '0' }}>
                    <PammSubscription />
                </Col>
            )
        }
        // else if (nav_Show === "Other" || nav_Show === "其他") {
        //     return (
        //         <Col lg="12" style={{ padding: '0' }}>
        //             <MyPamm />
        //         </Col>
        //     )

        // }
    }

    return <>
        {/* <Breadcrumb parent="VE Fund" title="VE FUnd"></Breadcrumb> */}
        <Breadcrumb parent="" title=""></Breadcrumb>

        <Container fluid="sm">
            {/* <Row style={{
                padding: '15px',
                width: '50%',
                backgroundColor: 'transparent',
                // backgroundImage: `url(${WalletBG})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                justifyContent: 'space-between',
                marginBottom: '20px',
                marginRight: '0px',
                marginLeft: '0px',
                backgroundColor: ' #c3a569',
                boxShadow: '0px 7px 20px #00000014',
                borderRadius: '20px',
                color: 'black'
            }}>
                <Col lg='9'>
                    <h3>{t('pamm.StayTune')}</h3>
                    <p>{t('pamm.ComingSoon')}</p>
                    
                </Col>
            </Row> */}
            {/* {showGraph ? <PammManager/> : <PammManagerTable/>}  */}
            {/* <PammManager /> */}

            <Col
                className='d-none d-lg-block d-md-block'
                style={{
                    margin: '0px',
                    padding: '20px 0px',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    color: 'black',
                }}

            >
                <Col style={{ fontSize: '13px', fontWeight: 'bold', marginLeft: '30px' }}>
                    <Navbar id='NavBar' className="mobile-font-size m-3" expand="md" style={{ padding: '0px', justifyContent: 'flex-end', textAlign: 'center', backgroundColor: 'transparent', width: '100%', borderRadius: '20px' }}>

                        <div style={{
                            cursor: 'pointer', fontSize: '20px',
                            fontWeight: '500',
                        }} className={`${nav_Show === 'Manager List'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Show === 'Manager List' || nav_Show === "经理人" || nav_Show === "" ? 'active' : 'inactive'} mr-3 p-t-15`} style={{ width: '197px', height: '58px' }}>
                                <p className='m-0' style={{ fontSize: '18px' }}>{t("pamm.PammManagerList")}</p>
                            </NavbarText>
                        </div>


                        <div style={{
                            cursor: 'pointer', fontSize: '20px',
                            fontWeight: '500',
                        }} className={`${nav_Show === 'Subscription'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Show === 'Subscription' || nav_Show === "订阅" ? 'active' : 'inactive'} mr-3 p-t-15`} style={{ width: '197px', height: '58px' }}>
                                <p className='m-0' style={{ fontSize: '18px' }}>{t("pamm.Subscription")}</p>
                            </NavbarText>
                        </div>


                    </Navbar>
                </Col>
                <Col id='displayGraph' >
                    <CardBody style={{ border: 'none', borderRadius: '0', padding: '10px' }}>
                        {displayNav()}
                    </CardBody>
                </Col>
            </Col>

            {/*mobile navr bar*/}
            <Col
                className='d-block d-lg-none d-md-none'
                style={{
                    margin: '0px',
                    padding: '0px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    color: 'black',
                }}

            >

                <Col style={{ fontSize: '13px', fontWeight: 'bold' }}>
                    <Navbar id='NavBar' className="mobile-font-size" expand="md"
                        style={{
                            padding: '10px',
                            justifyContent: 'center',
                            textAlign: 'center',
                            backgroundColor: 'white',
                            width: '100%',
                            borderRadius: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap'
                        }}>

                        <div style={{ cursor: 'pointer', padding: '5px' }} className={`${nav_Show === 'Manager List'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Show === 'Manager List' || nav_Show === "经理人" || nav_Show === "" ? 'active' : 'inactive'} p-t-15`} style={{ width: '120px', height: '50px' }}>
                                <p className='m-0' style={{ fontSize: '12px' }}>{t("pamm.PammManagerList")}</p>
                            </NavbarText>
                        </div>


                        <div style={{ cursor: 'pointer', padding: '5px' }} className={`${nav_Show === 'Subscription'}`} onClick={(e) => setNav_Show(e.currentTarget.textContent)}>
                            <NavbarText className={`${nav_Show === 'Subscription' || nav_Show === "订阅" ? 'active' : 'inactive'} p-t-15`} style={{ width: '120px', height: '50px' }}>
                                <p className='m-0' style={{ fontSize: '12px' }}>{t("pamm.Subscription")}</p>
                            </NavbarText>
                        </div>

                    </Navbar>
                </Col>
                <Col id='displayGraph' >
                    <CardBody style={{ border: 'none', borderRadius: '0', padding: '0px' }}>
                        {displayNav()}
                    </CardBody>
                </Col>

            </Col>
        </Container>
    </>
}

export default Pamm