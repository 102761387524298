import axios from 'axios'
import { BASE_URL } from './constants'

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true
}

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const token = localStorage.getItem('token')
    const THtoken = localStorage.getItem('tokenTH')

    if (token != null) {
      request.headers.Authorization = `Bearer ${token}`
    }

    if (THtoken != null) {
      request.headers['external-authorization'] = `Bearer ${THtoken}`
    }
  }

  // console.log(request)
  return request
}

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    // unauthorized
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('tokenTH')
    localStorage.removeItem('animation')
    window.location.reload()
  }

  if (error?.response?.status === 422) {
    // unprocessable entity
    // console.log(error.response)

    let referralError = error.response.data.errors.find(
      (e) => e.field === 'referral_code' && e.message === 'exists validation failure'
    )

    if (referralError) {
      return Promise.reject({
        message: "Referral code doesn't exist"
      })
    }

    return Promise.reject({
      message:
        error?.response?.data?.errors?.map((e) => `${e.field} - ${e.message}`).join('\n') ??
        'Please contact customer support'
    })
  }

  if (isHandlerEnabled(error.config)) {
    // Handle errors
    console.log(error.response)
    return Promise.reject({
      code: error.response?.data?.code ?? 'request.PleaseTryAgainLater',
      translate_params: error.response?.data?.translate_params,
      message:
        error.response?.data?.message ??
        error.response?.data?.error ??
        error.response?.data?.code ??
        'Please try again later'
    })
  }

  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }

  return response.data
}

const client = axios.create({
  baseURL: BASE_URL
})
client.interceptors.request.use((request) => requestHandler(request))
client.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default client
