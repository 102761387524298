import React, { Fragment, useState, useEffect } from 'react'
import {
  Row, Col, Container, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal,
  ModalHeader,
  ModalBody,
  Label,
  Spinner,
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'sweetalert2'


import { useTranslation } from 'react-i18next'


import AccountService from "../../../network/services/account"
import UserService from '../../../network/services/user'
import VeSyncThService from '../../../network/services/syncth'

import './MT5List.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const DashboardWallet = () => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [syncEmail, setSyncEmail] = useState('')
  const [authCode, setAuthCode] = useState('')
  const [errors, setErrors] = useState({ verification: null, twoFA: null })
  const user = useSelector((state) => state.user)

  const [isSendCode, setIsSendCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [needSync, setNeedSync] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }
  let history = useHistory()
  const dispatch = useDispatch()


  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }
  const selectedAccount = useSelector((state) => state.monitoring.account)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [noNeedSync, setNoNeedSync] = useState(true)
  const toggle = () => setDropdownOpen((prevState) => !prevState)


  const [error, setError] = useState(null);
  const sendAuthCode = async () => {
    if (syncEmail === '') {
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: "Please fill in your Email and Verification Code correctly",
        icon: 'error'
      })
    } else {
      try {
        setIsSendCode(true)
        let result = await VeSyncThService.sendCode({ email: syncEmail })

      } catch (error) {
        console.log(error)
        setIsSendCode(false)
        setIsLoading(false)
        const errorCode = error.code
        const errorTranslate = error.translate_params
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: t(errorCode, errorTranslate),

          icon: 'error'
        })
      }
    }
  }
  const syncThAccount = async () => {
    setIsBusy(true)
    try {

      let result = await VeSyncThService.verifyAccount({ email: syncEmail, code: authCode })


      if (result.result.success) {
        const updatedUser = await UserService.getMyself()
        dispatch({ type: 'SET_USER', user: updatedUser.user })
        setIsLoading(false)
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: 'Account synced successfully!',

          icon: 'success'
        }).then(async () => {

          window.location.reload();
        })
      }

    } catch (error) {
      console.log(error)
      setIsLoading(false)
      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        html: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }



  const init = async () => {
    // live accounts
    setIsLoading(true)
    try {
      const result = await AccountService.getLiveAndIbAccount()
      setAccounts(result.accounts)
      setNoNeedSync(result.success)
      setIsLoading(false)
    }
    catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    init()

    // eslint-disable-next-line
  }, [])

  const checkAccountClass = () => {

    if (selectedAccount != null) {
      if (selectedAccount?.account_type === 2) {
        return 'IB Account'
      } else if (selectedAccount?.package?.name) {
        return selectedAccount?.package?.name
      } else {
        return 'Normal Account'
      }
      //end of selected Accout
    } else {
      if (accounts[0]?.account_type === 2) {
        return 'IB Account'
      } else if (accounts[0]?.package?.name) {
        return accounts[0]?.package?.name
      } else {
        return 'Normal Account'
      }
    }
  }


  var USDformatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })



  return (
    <>
      {isLoading ?
        <Row style={{
          padding: '15px',
          width: '100%',
          backgroundColor: '#f7f7f7',
          // backgroundImage: `url(${WalletBG})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          justifyContent: 'space-between',
          marginBottom: '20px',
          marginRight: '0px',
          marginLeft: '0px',
          borderRadius: '20px',
          color: 'black'
        }}>
          <div className='ml-2'>
            <Skeleton width={100} height={25} borderRadius={20} />
          </div>
          <div className='mr-2'>
            <Skeleton width={200} height={25} borderRadius={20} />
          </div>

          <Col lg='12' style={{ marginTop: '25px' }}>
            <Row className='justify-content-between'>
              <Col>
                <Skeleton width={150} height={10} borderRadius={20} />
                <Skeleton width={150} height={10} borderRadius={20} />
              </Col>

              <Col>
                <Skeleton width={150} height={10} borderRadius={20} />
                <Skeleton width={150} height={10} borderRadius={20} />
              </Col>

              <Col>
                <Skeleton width={150} height={10} borderRadius={20} />
              </Col>
            </Row>
          </Col>

        </Row>
        :

        user.user.is_th_sync == true ?

          <Row style={{
            padding: '15px',
            width: '100%',
            backgroundColor: 'transparent',
            // backgroundImage: `url(${WalletBG})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            justifyContent: 'space-between',
            marginBottom: '20px',
            marginRight: '0px',
            marginLeft: '0px',
            backgroundColor: ' #c3a569',
            boxShadow: '0px 7px 20px #00000014',
            borderRadius: '20px',
            color: 'black',
            border: "3px solid #ad986e"
          }}>
            {accounts.length > 0 ?

              <>

                <Col lg='12'>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <div className="dropdown-basic m-1">
                      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret color='tertiary' style={{ minWidth: 150, backgroundColor: '#ad986e', color: 'black', fontSize: '18px', fontWeight: '500', borderRadius: '25px' }}>
                          #{selectedAccount != null ? selectedAccount.account_login :
                            accounts[0]?.account_login}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-content-wallet">
                          {accounts?.length > 0 &&
                            accounts.map((account, index) => {
                              return (
                                <Fragment key={account.id}>
                                  <DropdownItem
                                    style={{ borderRadius: '20px' }}
                                    onClick={() => {
                                      dispatch({ type: "SET_MONITORING_ACCOUNT", account: account })
                                    }}
                                  >
                                    #{account.account_login}
                                  </DropdownItem>
                                  {index !== accounts.length - 1 && <DropdownItem divider />}
                                </Fragment>
                              )
                            })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    {/*desktop */}
                    <div className='d-none d-lg-block'>
                      <div className="upgradeButton m-1"
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/tradeAccount`)
                        }}
                      >
                        {t('live.checkDetails')}
                      </div>
                    </div>
                    {/*mobile used */}
                    <div className='d-block d-lg-none'>
                      <div className="upgradeButton-mobile m-1"

                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/tradeAccount`)
                        }}
                      >
                        {t('live.Packages')}
                      </div>
                    </div>

                  </Row>
                </Col>

                <Col lg='12' style={{ marginTop: '25px' }}>
                  <Row className='justify-content-between'>
                    <Col>
                      <p style={{ fontSize: '16px' }}><span style={{ color: '#3d392f' }}>{t('live.balance')}: </span> {selectedAccount != null ? USDformatter.format(selectedAccount?.balance) : USDformatter.format(accounts[0]?.balance)}</p>
                      <p style={{ fontSize: '16px' }}><span style={{ color: '#3d392f' }}>{t('live.class')}: </span> {checkAccountClass()}</p>
                    </Col>

                    <Col>
                      <p style={{ fontSize: '16px' }}><span style={{ color: '#3d392f' }}>{t('live.credit')}: </span>{selectedAccount != null ? USDformatter.format(selectedAccount?.credit) : USDformatter.format(accounts[0]?.credit)}</p>
                      <p style={{ fontSize: '16px' }}><span style={{ color: '#3d392f' }}>{t('live.accountType')}: </span>{selectedAccount != null ? selectedAccount?.plan?.name : accounts[0]?.plan?.name}</p>
                    </Col>
                    <Col>
                      <p style={{ fontSize: '16px' }}><span style={{ color: '#3d392f' }}>{t('live.leverage')}: </span> 1 : {selectedAccount != null ? selectedAccount?.leverage : accounts[0]?.leverage}</p>
                    </Col>
                  </Row>
                </Col>
              </>
              :
              <Col lg='12'>
                <h3>{t('dashboard.NoTradeAccount')}</h3>
                <p>{t('dashboard.OpenTradeAccount')} </p>
                <Button
                  color='white'
                  style={{ borderRadius: '14px', backgroundColor: 'white', fontSize: '16px', height: '40px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                  onClick={() => {

                    window.open(
                      'https://secure.tradehallglobal.co/auth'
                    );
                  }}

                >
                  {t('dashboard.GoOpenAccount')}
                </Button>
              </Col>
            }
          </Row>
          :
          <>
            <Row style={{
              padding: '15px',
              width: '100%',
              backgroundColor: 'transparent',
              // backgroundImage: `url(${WalletBG})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              justifyContent: 'space-between',
              marginBottom: '20px',
              marginRight: '0px',
              marginLeft: '0px',
              backgroundColor: ' #c3a569',
              boxShadow: '0px 7px 20px #00000014',
              borderRadius: '20px',
              color: 'black'
            }}>
              <Col lg='12'>
                <h3>{t('dashboard.SyncAccount')}</h3>
                <p>{t('dashboard.unclockTrade')}</p>
                <Button
                  color='white'
                  style={{ borderRadius: '14px', backgroundColor: 'white', fontSize: '16px', height: '40px', boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
                  onClick={() => {
                    setShowModal(true)
                  }}

                >
                  {t('dashboard.Sync')}
                </Button>
              </Col>
            </Row>
          </>

      }


      {showModal && (
        <Modal isOpen={showModal} backdrop={true} centered={true}>
          <ModalHeader toggle={toggleModal}>{t('dashboard.SyncTradehall')}</ModalHeader>
          <ModalBody>
            <div>
              <Label className='mb-0'>{t('dashboard.TradehallEmail')}</Label>
              <Input
                className="form-control"
                placeholder="e.g. abcd123@gmail.com"
                required
                type='email'
                onChange={(e) => {
                  setSyncEmail(e.target.value)
                }}
              />
              {isSendCode == false && <small>{t('dashboard.HaveNoTradehallAcc')} <a href="https://secure.tradehallglobal.co/sign_up/" target='_blank' >{t('dashboard.Register')}</a> {t('dashboard.TradehallAcc')}</small>}

            </div>
            <Label className='mt-3'></Label>
            <div className='d-flex'>
              <Input
                className="form-control w-50  mr-2"
                placeholder={t('dashboard.VerificationCode')}
                required
                onChange={(e) => {
                  setAuthCode(e.target.value)
                }}
              />
              <Button
                disabled={isSendCode == true}
                style={{ borderRadius: '14px', fontSize: '16px', height: '40px' }}
                onClick={() => {
                  sendAuthCode()
                }}
              >
                {t('dashboard.SendCode')}
              </Button>
            </div>

            {isSendCode == true && <p className="mb-2 text-success">{t('dashboard.CheckMail')}</p>}

            <span style={{ color: 'red' }}>{errors?.twoFA}</span>
            <Button
              disabled={authCode.length < 6 || isBusy === true}
              className="mt-3 pull-right"
              onClick={() => {
                syncThAccount()
              }}
            >
              {t("user.Submit")}
            </Button>
          </ModalBody>
        </Modal>
      )}



    </>
  )




}

export default DashboardWallet
