import client from '../request'
import _ from 'lodash'

const getAvailablePackages = (props = null) => {
    console.log(props)
  let filters = props
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      if (filterString.length == 0) {
        filterString += `${key}=${value}`
      } else {
        filterString += `&${key}=${value}`
      }
    })
  }
  return client.get(`/packages?${filterString}`)
}

export default {
  getAvailablePackages
}
