import React, { useState, useRef, useEffect } from "react"
import DataTable from "react-data-table-component"
import moment from "moment"
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap'

import PammSubscriptionService from '../../../../../network/services/pammSubscription'
import { useTranslation } from 'react-i18next'

const PendingTable = () => {
  const { t } = useTranslation()
  // const columns = [

  //   {
  //     name: t("pammSubsccription.managerName"),
  //     selector: "name",
  //     sortable: true,
  //     width: '200px',
  //     format: (row) => {
  //       return row.pammManager.name ?? "-"
  //     }
  //   },
  //   {
  //     name: t("pammSubsccription.date"),
  //     selector: "created_at",
  //     sortable: true,
  //     width: "200px",
  //     format: (row) => {
  //       return moment(row.created_at).format("yyyy-MM-DD")
  //     },
  //   },
  //   {
  //     name: t("pammSubsccription.account"),
  //     selector: "Account",
  //     sortable: true,
  //     width: '200px',
  //     format: (row) => {
  //       return row.account.account_login ?? "-"
  //     }
  //   },
  //   {
  //     name: t("pammSubsccription.statistic"),
  //     selector: "Statistic",
  //     sortable: true,
  //     format: (row) => {
  //       return <Link to={`pamm/${row.pamm_manager_id}/statistic`} style={{ color: '#204661' }}>VIEW</Link>
  //     }
  //   },
  //   {
  //     name: t("pammSubsccription.status"),
  //     selector: "status",
  //     sortable: true,
  //     width: '200px',
  //     format: (row) => {
  //       switch (row.status) {
  //         case 0:
  //           return 'Pending for subscribe'
  //         case 1:
  //           return t("pammSubsccription.active")
  //         case 2:
  //           return <span style={{ color: 'red' }}>Rejected</span>
  //         case 3:
  //           return t("pammSubsccription.pendingForUnsubscribe")
  //         default:
  //           return "-"
  //       }
  //     },
  //   },
  //   {
  //     name: "Reason",
  //     selector: "Reason",
  //     sortable: true,
  //     width: '800px',
  //     format: (row) => {
  //       return row?.reason ?? "-"
  //     }
  //   },
  // ]

  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [avatar, setAvatar] = useState([])
  const [pending, setPending] = useState([])

  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState("")
  const wrapperRef = useRef()


  const init = async () => {
    setIsBusy(true)

    const result = await PammSubscriptionService.getAll()
    const filterPending = result?.pending?.filter(record => record?.record_type === 0)
    setPending(filterPending ?? [])


    // const ActualPendingAndReject = subscribePendingReject?.filter(record => record?.status === 0 || (record?.status === 2 && record?.record_type === 2))

    // setData(ActualPendingAndReject ?? [])
    setIsBusy(false)
  }

  const getthestatus = (status) => {
    if (status == 0) {
      return t('pammSubsccription.pending');
    } else if (status == 1) {
      return <p style={{ color: 'green' }}>{t('pammSubsccription.active')}</p>
    } else if (status == 2) {
      return t('pammSubsccription.rejected');
    } else if (status == 3) {
      return t('pammSubsccription.pendingForUnsubscribe');
    }
  }


  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
  }, [filters])
  const pendingList = pending.map(user => {
    return (pending.length > 0 ?
      <>

        <Col lg='4' md='6' style={{ backgroundColor: 'white', borderRadius: '20px' }}>
          <Card style={{ boxShadow: '0px 7px 20px #00000014' }}>
            <Row className='mt-3 ml-0'>
              <Col lg="4" style={{ textAlign: 'center', padding: '0px', marginTop: '15px' }}>
                <img src={user?.pammManager?.avatar} width='60px' height='60px' style={{ borderRadius: '15px' }} />
              </Col>
              <Col lg='8' style={{ paddingTop: '10px' }}>
                <p id="pamm-text" style={{color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.ManagerName")} :</p>
                <p id="pamm-sub-name">{user?.pammManager?.name}</p>
              </Col>

            </Row>
            <div className='mt-2' style={{ borderBottom: '3px solid #f7f7f7' }} />
            <Row style={{ display: "flex", flexDirection: 'column', padding: '20px', margin: '0px 10px' }}>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p id="pamm-text" style={{ color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.date")} :</p>
                <p>{moment(user?.updated_at).format('yyyy-MM-DD')}</p>
              </div>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p id="pamm-text" style={{ color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.account")} :</p>
                <p>{user?.account_login}</p>
              </div>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p id="pamm-text" style={{ color: '#9099A3', marginBottom:'10px' }}>{t("pammSubsccription.statistic")} :</p>
                <Link to={`/veFund/${user.pammManager?.id}/statistic`}>{t('pammSubsccription.view')}</Link>
              </div>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p id="pamm-text" style={{ color: '#9099A3', margin:'0px' }}>{t("pammSubsccription.status")} :</p>
                <p style={{margin:'0px'}}>{getthestatus(user?.status)}</p>
              </div>
            </Row>

          </Card>
        </Col>
      </>
      : " "
    )
  })
  return (
    <Container className="p-0">
      <Row>
        {pending.length > 0 && pendingList}
      </Row>
    </Container>

  )
}

export default PendingTable
