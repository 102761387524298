import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import AccountService from '../../../network/services/account'
import { useTranslation } from 'react-i18next'

const PaymentHistoryTable = ({ paymentResult }) => {
    const { t } = useTranslation()
    console.log('paymentResultpaymentResult', paymentResult)
    const columns = [
        {
            name: "Name",
            selector: 'discord_group_id',
            sortable: true,
            format: (row) => {
                switch (row.discord_group_id) {
                    case 1:
                        return 'Vernon Trade Idea'
                    case 2:
                        return 'Simmi Trade Idea'
                    case 3:
                        return 'Vernon Trade Idea'
                    case 4:
                        return 'Simmi Trade Idea'
                    default:
                        return 'Vtrade Idea'
                }
            }
        },
        {
            name: 'Status',
            selector: 'payment_status',
            sortable: true,
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            format: (row) => {
                return moment(row.created_at).format('yyyy-MM-DD')
            }
        },
        {
            name: 'Next Billing Date',
            selector: 'date',
            sortable: true,
            format: (row) => {

                if (row.status == '1' ) {
                    return moment(row?.next_billing_date).format('yyyy-MM-DD')
                }
            }
        },
    ]

    const [isBusy, setIsBusy] = useState(false)
    const [total, setTotal] = useState(0)




    return (
        <>
            <h3>Payment History</h3>
            {paymentResult != null ? (
                paymentResult.length > 0 ? (
                    <DataTable
                        noHeader
                        data={paymentResult}
                        columns={columns}
                        striped={true}
                        center={true}
                        pagination
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        paginationServer
                        paginationTotalRows={total}
                        // onChangePage={handlePageChange}
                        progressPending={isBusy}
                    />
                ) : (
                    <h6>{t("monitoring.noRecordsFound")}</h6>
                )
            ) : (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            )}
        </>
    )
}

export default PaymentHistoryTable
