import React, { useState, useEffect } from 'react'
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    Container,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap'
import EyeGameService from '../../../network/services/eyeGame'

import styled from 'styled-components'
import _ from "lodash"

import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'


import {
    useParams,
} from "react-router-dom";
import Leaderboard from './table'


const EyeGame = ({ logo }) => {
    const [loginError, setLoginError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t, i18n } = useTranslation()


    // const init = async () => {
    //     try {
    //         setIsLoading(true)
    //         const result = await EyeGameService.getMyGame(id)

    //         if (result.eye_games) {
    //             //set user details
    //             setUserDetails(result?.eye_games[0]?.gameUser)

    //             const unplayGame = result?.eye_games?.filter((item) => {
    //                 return item?.status == 0
    //             })

    //             const playedGames = result?.eye_games?.filter((item) => {
    //                 return item?.status == 1 || item?.status == 2
    //             })

    //             const correctPlayedGame = result?.eye_games?.filter((item) => {
    //                 return item?.status == 1
    //             })

    //             console.log('playedGamesplayedGames', playedGames.length)

    //             setUnplayGame(unplayGame)
    //             setPlayedGames(playedGames)
    //             setCorrectPlayedGames(correctPlayedGame)
    //             setSelectedGame(unplayGame[0])
    //             setSelectedGameOptions(unplayGame[0]?.gameQuestion?.options?.split(','))

    //             console.log('unplayGame[0]?.gameQuestion?.', unplayGame[0]?.gameQuestion?.options?.split(','))
    //         }

    //         setIsLoading(false)
    //     } catch (error) {
    //         setIsLoading(false)
    //     }
    // }

    // useEffect(() => {
    //     init()
    // }, [])



    return (
        <>
            {!isLoading ?
                <div>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand >Value Envision</NavbarBrand>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink >Leaderboard</NavLink>
                            </NavItem>
                        </Nav>

                    </Navbar>

                    <Container className='mt-5'>
                        <Row className='justify-content-center'>

                            <Col lg='6' >
                                <Card style={{
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                }}>
                                    <CardBody>
                                        <h3 className='text-center'>汇眼识图 Leaderboard</h3>
                                        <Leaderboard />
                                    </CardBody>
                                </Card>


                            </Col>


                        </Row>

                    </Container>


                </div>
                :
                <Container
                    style={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Spinner />
                </Container>
            }

        </>
    )
}

export default EyeGame
