import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment'

import UserDetail from './details'
import Verification from './verifications'
import ChangePassword from './password'
import TwoFactorAuthentication from './authentication'
import Breadcrumb from '../../layout/breadcrumb'

import UserService from '../../network/services/user'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import avatar from './user.png'
import personalInfo from './PersonalInformation.svg'
import changePassword from './changePassword.svg'

import '../newDashboard/dashboard.css'

const User = () => {
  const { t } = useTranslation()
  const currentUser = useSelector((state) => state.user.user)
  const verification = useSelector((state) => _.last(state.user.verifications))
  
  const tabs = {
    details: t("user.PersonalInformation"),
    // bank: t("user.BankDetails"),
    // creditCard: t("user.CreditCardDetails"),
    verification: t("user.Verification"),
    password: t("user.ChangePassword"),
    authentication: t("user.TwoFactorAuthentication")
  }

  let location = useLocation()
  let history = useHistory()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('details')

  // const user = useSelector((state) => state.user.user)
  // const [showTransfer, setShowTransfer] = useState(true)
  // const asicTransfer = useSelector((state) => state.user.asic_transfer)


  const init = async () => {
    const result = await UserService.getMyself()
    // console.log(result)

    dispatch({ type: 'SET_USER', user: result.user })
    dispatch({ type: 'SET_PROFILE', profile: result.profile ?? {} })

    if (result.partners) {
      dispatch({ type: 'SET_AGENT', partners: result.partners })
    }

    // if (result.bank) {
    //   dispatch({ type: 'SET_BANK', bank: result.bank })
    // }

    // if (result.banks) {
    //   dispatch({ type: 'SET_BANKS', banks: result.banks })
    // }

    if (result.verification) {
      dispatch({ type: 'SET_VERIFICATIONS', verifications: result.verifications })
    }

    if (result.user?.auth_required) {
      dispatch({ type: 'SET_TWOFA', twoFA: result.user.auth_required })
    }
  }

  const setTab = (val) => {
    history.push('#' + val)
  }


  useEffect(() => {
    if (!isEmpty(location.hash)) {
      setActiveTab(location.hash.replace('#', ''))
    } else {
      setActiveTab('details')
    }
  }, [location])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb parent={t("user.UserProfile")} title={tabs[activeTab]} />
      
      <Container className='mobile-function-bar'>

        <Row>
          
          <Col sm="12" lg="5" xl="4" className="project-list">
            <Card
              style={{
                  background:'#FFFFFF 0% 0% no-repeat',
                  boxShadow:'0px 7px 20px #0000000D',
                  borderRadius:'14px',
                  height:'134px',
                  textAlign:'center'
                
                }}
            >
              <Row style={{justifyContent:'center',width:'100%', margin:'15px'}}>
                <Col lg='2' xs='2' style={{marginTop:'15px'}}>
                  <img src={avatar} width="80px" height="80px" alt="avatar"></img>
                </Col>
                <Col lg='10' xs='10' style={{paddingLeft:'50px',marginTop:'10px'}}>
                  <Row className='m-0' style={{fontSize:'18px', alignItems:"flex-start"}}>
                    <Col lg='12' className='p-0'>
                    {currentUser?.email ?? t("header.Email")}
                    <p style={{color:'#4F5358', fontSize:'14px'}}>
                      {(t("dashboard.join"))} {moment(currentUser?.created_at).format('DD/MM/YYYY')}
                    </p>
                    </Col>
                  </Row>
                  {/* <Row style={{margin:'0px'}}>
                    // {currentUser?.created_at} 
                    <p style={{color:'#4F5358', fontSize:'14px'}}>
                      {(t("dashboard.join"))} {moment(currentUser?.created_at).format('DD/MM/YYYY')}
                    </p>
                  </Row> */}
                </Col>
              </Row>
            
            </Card>
            
              
                <Col style={{padding:'0px', backgroundColor:'white', borderRadius:'20px', boxShadow:'0px 7px 20px #00000014', marginBottom:'20px'}}>
                  <Nav tabs className="border-tab">
                    <NavItem style={{width:'100%'}}>
                      <NavLink
                        className={activeTab === 'details' ? 'active' : ''}
                        onClick={() => setTab('details')}
                        style={{ marginLeft:'0px', color:'black'}}
                      >
                        <img src={personalInfo} alt="personalInfo" style={{marginRight:'15px', marginLeft:'15px'}}></img>
                        {t("user.PersonalInformation")}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem style={{width:'100%'}}>
                      <NavLink
                        className={activeTab === 'bank' ? 'active' : ''}
                        onClick={() => setTab('bank')}
                        style={{ marginLeft:'0px', color:'black'}}
                      >
                        <img src={bank} alt="bank" style={{marginRight:'15px', marginLeft:'15px'}}></img>
                        {t("user.BankDetails")}
                      </NavLink>
                    </NavItem>
                    <NavItem style={{width:'100%'}}>
                      <NavLink
                        className={activeTab === 'creditCard' ? 'active' : ''}
                        onClick={() => setTab('creditCard')}
                        style={{ marginLeft:'0px', color:'black'}}
                      >
                        <img src={bank} alt="creditcard" style={{marginRight:'15px', marginLeft:'15px'}}></img>
                        {t("user.CreditCardDetails")}
                      </NavLink>
                    </NavItem> */}
                    {/* <NavItem style={{width:'100%'}}>
                      <NavLink
                        className={activeTab === 'verification' ? 'active' : ''}
                        onClick={() => setTab('verification')}
                        style={{ marginLeft:'0px', color:'black'}}
                      >
                        <img src={verifyIcon} alt="verify" style={{marginRight:'15px', marginLeft:'15px'}}></img>
                        {t("user.Verification")}
                      </NavLink>
                    </NavItem> */}
                    <NavItem style={{width:'100%'}}>
                      <NavLink
                        className={activeTab === 'password' ? 'active' : ''}
                        onClick={() => setTab('password')}
                        style={{ marginLeft:'0px', color:'black'}}
                      >
                        <img src={changePassword} alt="changePassword" style={{marginRight:'15px', marginLeft:'15px'}}></img>
                        {t("user.ChangePassword")}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem style={{width:'100%'}}>
                      <NavLink
                        className={activeTab === 'authentication' ? 'active' : ''}
                        onClick={() => setTab('authentication')}
                        style={{ marginLeft:'0px', color:'black'}}
                      >
                        <img src={TwoFactor} alt="TwoFactor" style={{marginRight:'15px', marginLeft:'15px'}}></img>
                        {t("user.TwoFactorAuthentication")}
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </Col>
              
            
          </Col>

          {/* {console.log('user', user)} */}
          {/* {asicTransfer === false ?
            <Modal isOpen={showTransfer} backdrop={true} centered={true} id='modal_asic' style={{ maxWidth: '90%' }}>
              <ModalHeader
                style={{
                  backgroundColor: 'rgb(15, 60, 90)',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                Important: Update to Your Trading Account
          </ModalHeader>
              <ModalBody>
                <TransferModal isModalShow={showTransfer} />

              </ModalBody>
            </Modal>
            :
            ''
          } */}

          <Col sm="12" lg="7" xl="8" className='mb-5 '>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="details">
                <UserDetail />
              </TabPane>
              {/* <TabPane tabId="bank">
                <BankDetail />
              </TabPane>
              <TabPane tabId="creditCard">
                <CreditCard />
              </TabPane> */}
              <TabPane tabId="verification">
                <Verification />
              </TabPane>
              <TabPane tabId="password">
                <ChangePassword />
              </TabPane>
              <TabPane tabId="authentication">
                <TwoFactorAuthentication />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default User
