import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import EyeGameService from '../../network/services/eyeGame'

import styled from 'styled-components'
import _ from "lodash"

import { useTranslation } from 'react-i18next'

import './index.scss'


const LoginButton = styled.button` 
  margin-right: 37px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
`

const SignInForm = ({ logo }) => {
  const { register, handleSubmit, errors } = useForm()
  const [loginError, setLoginError] = useState(null)
  const { t, i18n } = useTranslation()

  const submitAuth = async (data) => {


    try {
      const result = await EyeGameService.login({
        name: data.name,
      })

      if (result.user_id) {

        window.location.href = `start/eye_games_auth/${result.user_id}`
      }
    } catch (error) {
      console.log('hello error')

      toast.warning('Invalid Credential. Find support', {
        position: toast.POSITION.TOP_RIGHT,
      })
      setLoginError("User Not Found. Please wait for assistance")

    }
  }

  return (
    <>
      <Row className='justify-content-center'>
        <Col>
          <div className="mb-3" style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '3' }}>
            {logo?.value && (
              <img
                src={require('../../assets/images/logo/ve-logo.png')}
                // src={newLogo}
                alt="logo"
                style={{ maxHeight: 175, maxWidth: 175 }}
              />
            )}
          </div>

          <Row style={{ justifyContent: 'center', zIndex: '6', height: '100vh' }}>

            <Form
              id="login-form"
              className="theme-form cont"
              onSubmit={handleSubmit(submitAuth)}
              style={{ margin: 'auto', zIndex: '5', minHeight: '310px', borderRadius: '0px 0px 50px 0px', border: 'none' }}
            >

              <img
                style={{ float: 'center', width: '250px' }}
                className="img-fluid for-light mb-5"
                src={require('../../assets/images/logo/ve-logo.png')}
                alt=""
              />
              <h4 className='mb-4 ' style={{ color: '#0f1430' }}>汇眼识图</h4>

              <Label className="col-form-label pt-0">Name</Label>

              <FormGroup style={{ display: "flex", backgroundColor: 'transparent', marginBottom: '10px' }}>
                <Input
                  id="input-form"
                  className="form-control"
                  placeholder='Name'
                  type="text"
                  required=""
                  name="name"
                  innerRef={register({ required: true })}
                  style={{ backgroundColor: "#f8f8f8" }}
                />
              </FormGroup>
              <span style={{ color: 'red' }}>{errors.email && t("auth.emailIsRequire")}</span>
              <div style={{ padding: 5 }} />
              {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>}
              <div style={{ padding: 5 }} />

              <LoginButton id='login_button' className="w-100" color="primary" style={{ backgroundColor: '#C4A569', color: 'black' }} >{t("auth.login")}</LoginButton>

            </Form>


          </Row>
        </Col>
      </Row>
    </>
  )
}

export default SignInForm
