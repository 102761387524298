import React, { useState, useEffect } from 'react'
import {
    useParams, useHistory
} from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import {
    Row,
    Col,
    Card,
    Badge,
    Button,
    Container,
} from 'reactstrap'
import Breadcrumb from '../../../../layout/breadcrumb'
import SweetAlert from 'sweetalert2'

import ModuleService from '../../../../network/services/modules'
import './index.css'

const ModuleChapters = () => {
    let history = useHistory()
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }

    const [allModuleChapters, setAllModuleChapters] = useState([])
    const [thisModuleChapters, setThisModuleChapters] = useState([])
    const [module, setModule] = useState([])
    const [moduleIds, setModuleIds] = useState([])
    const [rows, setRows] = useState([])

    const init = async () => {
        const allModuleChaptersData = await ModuleService.getModuleChapterUnderCourse(id)
        setAllModuleChapters(allModuleChaptersData?.modules)

        const thisModuleChaptersData = await ModuleService.getModuleChapter(id)
        setThisModuleChapters(thisModuleChaptersData?.chapters)
        setModule(thisModuleChaptersData.module)

        console.log('allModuleChaptersDataallModuleChaptersData', allModuleChaptersData)
        console.log('thisModuleChaptersDatathisModuleChaptersData', thisModuleChaptersData)

    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (allModuleChapters.length > 0) {
            const allChapters = allModuleChapters.reduce((acc, module) => acc.concat(module.chapters || []), []);
            const totalRows = Math.ceil(allChapters.length / 9);

            const moduleIds = allChapters.map(chapter => chapter.module_id);
            const uniqueModuleIds = Array.from(new Set(moduleIds));

            let rows = Array.from({ length: totalRows }, (_, rowIndex) => {
                const rowChapters = allChapters.slice(rowIndex * 9, (rowIndex + 1) * 9);
                return rowChapters;
            });

            setModuleIds(uniqueModuleIds);
            setRows(rows);
        }
    }, [allModuleChapters]);

    const getImage = (id) => {
        console.log('ididid', id)
        if (id == 1) {
            return 'https://ve-portal.s3.ap-southeast-1.amazonaws.com/01+module.jpg'
        } else if (id == 2) {
            return 'https://ve-portal.s3.ap-southeast-1.amazonaws.com/02+module.jpg'
        } else if (id == 3) {
            return 'https://ve-portal.s3.ap-southeast-1.amazonaws.com/03+module.jpg'
        } else if (id == 4) {
            return 'https://ve-portal.s3.ap-southeast-1.amazonaws.com/04+module.jpg'
        } else if (id == 5) {
            return 'https://ve-portal.s3.ap-southeast-1.amazonaws.com/05+module.jpg'
        } else if (id == 7) {
            return 'https://ve-portal.s3.ap-southeast-1.amazonaws.com/06+module.jpg'
        }

    }


    return <>
        <Breadcrumb parent={module.name} title={module.name}></Breadcrumb>


        <Row style={{ marginTop: "25px", overflow: 'auto', display: 'flex' }}>
            {thisModuleChapters?.map(chapter => {
                return (
                    <Col
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${process.env.PUBLIC_URL}/courses/ppt/journey/module/${module.id}/chapters/lesson`)
                        }}
                        key={chapter.id} xl='3' lg='4' xs='8' sm='12' className="result mb-5">
                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                backgroundColor: 'white',
                                color: 'black',
                            }}
                        >
                            <div style={{
                                filter: chapter?.is_course_available === false ? 'contrast(0.5)' : ''
                            }}>
                                <div >
                                    {
                                        chapter?.is_course_available === false &&
                                        <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>
                                    }
                                    <img alt='course background' src={getImage(module.id)}
                                        style={{
                                            width: '100%',
                                            maxHeight: '225px',
                                            borderTopLeftRadius: '15px',
                                            borderTopRightRadius: '15px'
                                        }}
                                    />
                                </div>

                                <Row style={{ marginTop: '-17px', color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                    <Badge
                                        className='p-t-10'
                                        style={{
                                            borderRadius: '7px',
                                            color: 'black',
                                            width: 'auto',
                                            height: '32px',
                                            fontSize: '14px'
                                        }}
                                        color="primary"
                                    >
                                        {chapter?.module?.course?.difficulty}
                                    </Badge>
                                    <Badge
                                        className='p-t-10'
                                        style={{
                                            textTransform: 'capitalize',
                                            borderRadius: '7px',
                                            color: 'black',
                                            width: '98px',
                                            height: '32px',
                                            backgroundColor: '#CDCFD7',
                                            fontSize: '14px'
                                        }}
                                        color=""
                                    >
                                        {chapter?.module?.course?.course_language}
                                    </Badge>
                                </Row>

                                <Row style={{ color: 'black', marginBottom: '0px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                    <h4>{chapter?.name}</h4>
                                </Row>

                                <Row className="mt-0" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                    <div>
                                        <p style={{ marginBottom: '0', marginTop: '6px' }}>{chapter?.author}</p>
                                    </div>
                                </Row>

                                <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'flex-end', marginRight: '5%' }}>
                                    {/* <div style={{ display: 'flex' }}>
                                        {(() => {
                                            let content;
                                            switch (chapter.status) {
                                                case 1:
                                                    content = (
                                                        <>
                                                            <p><span className="dot"></span></p>
                                                        </>
                                                    );
                                                    break;
                                                case 2:
                                                    content = (
                                                        <>
                                                            <p className='ml-2'>{t('modules.NotAvailable')}</p>
                                                        </>
                                                    );
                                                    break;
                                                case 3:
                                                    content = (
                                                        <>
                                                            <p className='ml-2'>{t('modules.ComingSoon')}</p>
                                                        </>
                                                    );
                                                    break;
                                                default:
                                                    content = (
                                                        <>
                                                            <p className='ml-2'>{t('modules.NotAvailable')}</p>
                                                        </>
                                                    );
                                            }
                                            return <Col span={8}>{content}</Col>;
                                        })()}
                                    </div> */}
                                </Row>
                            </div>
                        </Card>
                    </Col>
                )
            })}
        </Row>

    </>
}

export default ModuleChapters