import React from 'react'
import { Media } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import AddressUpload from './address_upload'
import { useTranslation } from 'react-i18next'


const AddressTable = ({ reupload }) => {
  const { t } = useTranslation()

  const translateStatus = (status) => {
  
    switch (status) {
      case 0:
        return t("user.Pending")
      case 1:
        return t("user.Verified")
      case 2:
        return t("user.Failed")
      case 3:
        return t("user.Rejected")
      default:
        return '-'
    }
  }
  const verification = useSelector((state) => _.last(state.user.verifications))

  if (verification == null || reupload) {
    return (
      <>
        <AddressUpload />
      </>
    )
  }

  return (
    <div className="blog-box blog-grid text-center">
      <a href={verification.address_front} target="_blank" rel="noopener noreferrer">
        <Media className="img-fluid top-radius-blog" src={verification.address_front} alt="" />
      </a>
      <div className="blog-details-main">
        <ul className="blog-social">
          <li className="digits">{moment(verification.created_at).format('yyyy-MM-DD hh:mmA')}</li>
          <li className="digits" style={{ fontWeight: 'bold' }}>
            {translateStatus(verification.status)}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AddressTable
