import React, { useState, useEffect } from 'react'
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    Container,
    Row,
    Col,
    Button
} from 'reactstrap'
import EyeGameService from '../../../network/services/eyeGame'

import styled from 'styled-components'
import _ from "lodash"

import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import './index.scss'

import {
    useParams,
} from "react-router-dom";

import CorrectIcon from './correct.png'
import WrongIcon from './wrong.png'
import CongrateIcon from './congrate.png'



const EyeGame = ({ logo }) => {
    const [loginError, setLoginError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [userDetails, setUserDetails] = useState(null)

    const [unplayGame, setUnplayGame] = useState([])
    const [playedGames, setPlayedGames] = useState([])
    const [correctPlayedGames, setCorrectPlayedGames] = useState([])


    const [selectedGame, setSelectedGame] = useState({})
    const [selectedGameOptions, setSelectedGameOptions] = useState([])




    const { t, i18n } = useTranslation()
    const { id } = useParams()


    const init = async () => {
        try {
            setIsLoading(true)
            const result = await EyeGameService.getMyGame(id)

            if (result.eye_games) {
                //set user details
                setUserDetails(result?.eye_games[0]?.gameUser)

                const unplayGame = result?.eye_games?.filter((item) => {
                    return item?.status == 0
                })

                const playedGames = result?.eye_games?.filter((item) => {
                    return item?.status == 1 || item?.status == 2
                })

                const correctPlayedGame = result?.eye_games?.filter((item) => {
                    return item?.status == 1
                })

                console.log('playedGamesplayedGames', playedGames.length)

                setUnplayGame(unplayGame)
                setPlayedGames(playedGames)
                setCorrectPlayedGames(correctPlayedGame)
                setSelectedGame(unplayGame[0])
                setSelectedGameOptions(unplayGame[0]?.gameQuestion?.options?.split(','))

                console.log('unplayGame[0]?.gameQuestion?.', unplayGame[0]?.gameQuestion?.options?.split(','))
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        init()
    }, [])

    const [answeredQuestion, setAnsweredQuestion] = useState(false)
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(null)

    const answerQuestion = async (answer) => {
        try {
            setAnsweredQuestion(true)

            const result = await EyeGameService.answerQuestion({
                eye_game_user_id: id,
                eye_game_id: selectedGame?.gameQuestion?.id,
                selected_answer: answer
            })

            if (answer == selectedGame?.gameQuestion?.answer) {
                setIsCorrectAnswer(true)
            } else {
                setIsCorrectAnswer(false)
            }

            // window.location.reload()

        } catch (error) {

        }
    }

    return (
        <>
            {!isLoading ?
                <div>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand >Value Envision</NavbarBrand>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink >{userDetails?.name}</NavLink>
                            </NavItem>
                        </Nav>

                    </Navbar>

                    {/* Answer the game */}
                    {selectedGame && answeredQuestion == false &&
                        <>
                            <div className='d-flex justify-content-center mt-5'>
                                <h3>{selectedGame?.gameQuestion?.question}</h3>
                            </div>

                            <div className='d-flex justify-content-around mt-5'>
                                {selectedGameOptions?.map((item) => {
                                    return <div onClick={() => {
                                        answerQuestion(item)
                                    }} className='p-3 options-cards' >
                                        <img width={500} height={300} src={item} alt='game options' />
                                    </div>
                                })}
                            </div>
                        </>

                    }

                    {/* Answered the game */}
                    {selectedGame && answeredQuestion == true &&
                        <>
                            <div className='d-flex justify-content-center mt-5'>
                                <h3>{selectedGame?.gameQuestion?.question}</h3>
                            </div>

                            <div className='d-flex justify-content-around mt-5'>
                                <div className='p-3 answered-cards' >
                                    <img width={500} height={300} src={selectedGame?.gameQuestion?.answer_description_img} alt='game options' />
                                </div>

                                <div className='answered-description' style={{ width: '700px' }}>
                                    {parse(selectedGame?.gameQuestion?.answer_description)}
                                </div>

                            </div>

                            <Row style={{ marginRight: '0px', marginTop: '150px', backgroundColor: 'white', justifyContent: 'center' }}>
                                <Col className="game-footer-copyright " style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: ' #C4A569', padding: '20px' }}>
                                    {isCorrectAnswer ?
                                        <img width={75} height={75} src={CorrectIcon} alt='correct icon' />
                                        :
                                        <img width={75} height={75} src={WrongIcon} alt='wrong icon' />

                                    }
                                </Col>
                                <Col
                                    lg="6"
                                    className="d-none d-lg-flex d-xl-flex"
                                    style={{ margin: '0px', backgroundColor: ' #C4A569', justifyContent: 'center' }}
                                >
                                    <div style={{ margin: '15px' }}>
                                        <Button style={{ width: '150px', height: "75px" }} onClick={() => {
                                            window.location.reload()
                                        }} >Continue</Button>
                                    </div>
                                </Col>

                            </Row>
                        </>

                    }

                    {playedGames?.length == 10 &&
                        <div style={{ justifyContent: 'space-evenly' }} className='d-flex mt-5'>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={CongrateIcon} alt='congrate icon' />
                                <h1 className='mt-3 mb-2'>{userDetails?.name}</h1>
                                <h1 style={{ color: 'green' }}>{correctPlayedGames?.length} / 10</h1>
                            </div>

                            <div>
                                {playedGames?.map((item) => {
                                    return <div>
                                        <h6 className='mb-4'>{item?.gameQuestion?.question}  {item?.status == 1 ?
                                            <img width={25} height={25} src={CorrectIcon} alt='correct icon' />
                                            :
                                            <img width={25} height={25} src={WrongIcon} alt='wrong icon' />

                                        }
                                        </h6>

                                    </div>
                                })}
                            </div>
                        </div>

                    }


                </div>
                :
                <Container
                    style={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Spinner />
                </Container>
            }

        </>
    )
}

export default EyeGame
